import pathnames from "routes/pathnames";

import PageSplashScreen from "pages/splash-screen";
import PageLogin from "pages/authentication/login";
import PageForgotPassword from "pages/authentication/forgot-password";
import PageInventoryProductList from "pages/inventory/product-list";
import PageInventorVariants from "pages/inventory/variants";
import PageDashboard from "pages/dashboard";
import Page404 from "pages/404";
import PageSignUp from "pages/signUp";
import PageRelationshipSuppliers from "pages/relationships/suppliers";
import PageEditSuppliers from "pages/relationships/editSuppliers";
import PageAddSupplier from "pages/relationships/newSupplier";
import PageWarehouse from "pages/warehouse/warehouse";
import PageAddWarehouse from "pages/warehouse/addWarehouse";
import PageProductCategory from "pages/inventory/productCategory";
import PageAddProduct from "pages/inventory/addProduct";
import PageEditWarehouse from "pages/warehouse/editWarehouse";
import PageEditProduct from "pages/inventory/editProduct";
import PageEditVariant from "pages/inventory/editVariant";
import PageAddNewVariant from "pages/inventory/addNewVariant";
import PageCreateStockCount from "pages/inventory/createStockCount";
import PageManageStock from "pages/inventory/manageStock";
import PageViewStockPosition from "pages/warehouse/viewStockPosition";
import PageUserList from "pages/user/userList";
import PageAddUser from "pages/user/addUser";
import PageUserProfile from "pages/user/userProfile";
import PageChangePassword from "pages/user/changePassword";
import PageCreatePassword from "pages/authentication/create-password";
import PageVerifyEmail from "pages/authentication/verify-email";
import PageSignUpSuccess from "pages/authentication/signup-success";
import PageViewOtherProfile from "pages/user/viewOtherProfile";
import PageStockMovementList from "pages/inventory/stockMovement";

const routes = [
    {
        path: pathnames.pageSplashScreen,
        component: PageSplashScreen,
        navBarTitle: "",
        title: "Splash Screen",
        authenticate: false,
    },
    {
        path: pathnames.pageLogin,
        component: PageLogin,
        navBarTitle: "",
        title: "Login",
        authenticate: false,
    },

    {
        path: pathnames.pageVerifyEmail,
        component: PageVerifyEmail,
        navBarTitle: "",
        title: "Verify Email",
        authenticate: false,
    },
    {
        path: pathnames.pageCreatePassword,
        component: PageCreatePassword,
        navBarTitle: "",
        title: "Create Password",
        authenticate: false,
    },
    {
        path: pathnames.pageSignUpSuccess,
        component: PageSignUpSuccess,
        navBarTitle: "",
        title: "Sign Up Success",
        authenticate: false,
    },
    {
        path: pathnames.pageForgotPassword,
        component: PageForgotPassword,
        navBarTitle: "",
        title: "Forget Password",
        authenticate: false,
    },
    {
        path: pathnames.pageForgotPasswordHash,
        component: PageForgotPassword,
        navBarTitle: "",
        title: "Forget Password",
        authenticate: false,
    },
    {
        path: pathnames.page404,
        component: Page404,
        navBarTitle: "",
        title: "404",
        authenticate: false,
    },
    {
        path: pathnames.pageDashboard,
        component: PageDashboard,
        navBarTitle: "Dashboard",
        title: "Dashboard",
        authenticate: true,
    },
    {
        path: pathnames.pageSignUp,
        component: PageSignUp,
        navBarTitle: "",
        title: "Sign Up",
        authenticate: false,
    },
    {
        path: pathnames.pageInventoryProductList,
        component: PageInventoryProductList,
        navBarTitle: "Inventory",
        title: "Inventory Product List",
        authenticate: true,
    },
    {
        path: pathnames.pageInventoryVariants,
        component: PageInventorVariants,
        navBarTitle: "Inventory",
        title: "Inventory Variants",
        authenticate: true,
    },
    {
        path: pathnames.pageRelationshipSuppliers,
        component: PageRelationshipSuppliers,
        navBarTitle: "Relationships",
        title: "Suppliers List",
        authenticate: true,
    },
    {
        path: pathnames.pageEditSupplier,
        component: PageEditSuppliers,
        navBarTitle: "Edit Supplier",
        title: "Edit Supplier",
        authenticate: true,
    },
    {
        path: pathnames.pageAddSupplier,
        component: PageAddSupplier,
        navBarTitle: "Relationships",
        title: "Add Supplier",
        authenticate: true,
    },
    {
        path: pathnames.pageWarehouse,
        component: PageWarehouse,
        navBarTitle: "Warehouse",
        title: "Warehouse",
        authenticate: true,
    },
    {
        path: pathnames.pageAddWarehouse,
        component: PageAddWarehouse,
        navBarTitle: "Warehouse",
        title: "Warehouse",
        authenticate: true,
    },
    {
        path: pathnames.pageAddProduct,
        component: PageAddProduct,
        navBarTitle: "Inventory",
        title: "Inventory",
        authenticate: true,
    },
    {
        path: pathnames.pageProductCategory,
        component: PageProductCategory,
        navBarTitle: "Inventory",
        title: "Inventory",
        authenticate: true,
    },
    ,
    {
        path: pathnames.pageEditWarehouse,
        component: PageEditWarehouse,
        navBarTitle: "Warehouse",
        title: "Warehouse",
        authenticate: true,
    },
    {
        path: pathnames.pageEditProduct,
        component: PageEditProduct,
        navBarTitle: "Inventory",
        title: "Inventory",
        authenticate: true,
    },
    {
        path: pathnames.pageEditVariant,
        component: PageEditVariant,
        navBarTitle: "Inventory",
        title: "Inventory",
        authenticate: true,
    },
    {
        path: pathnames.pageAddNewVariant,
        component: PageAddNewVariant,
        navBarTitle: "Add New Variant",
        title: "Add New Variant",
        authenticate: true,
    },
    {
        path: pathnames.pageCreateStockCount,
        component: PageCreateStockCount,
        navBarTitle: "Inventory",
        title: "Inventory",
        authenticate: true,
    },
    {
        path: pathnames.pageManageStock,
        component: PageManageStock,
        navBarTitle: "Inventory",
        title: "Inventory",
        authenticate: true,
    },
    {
        path: pathnames.pageViewStockPosition,
        component: PageViewStockPosition,
        navBarTitle: "Warehouse",
        title: "Warehouse",
        authenticate: true,
    },
    {
        path: pathnames.pageUserList,
        component: PageUserList,
        navBarTitle: "Settings",
        title: "Settings",
        authenticate: true,
    },
    {
        path: pathnames.pageAddUser,
        component: PageAddUser,
        navBarTitle: "Settings",
        title: "Settings",
        authenticate: true,
    },
    {
        path: pathnames.pageUserProfile,
        component: PageUserProfile,
        navBarTitle: "Profile",
        title: "Profile",
        authenticate: true,
    },
    {
        path: pathnames.pageChangePassword,
        component: PageChangePassword,
        navBarTitle: "Profile",
        title: "Profile",
        authenticate: true,
    },
    {
        path: pathnames.pageViewOtherProfile,
        component: PageViewOtherProfile,
        navBarTitle: "Profile",
        title: "Profile",
        authenticate: true,
    },
    {
        path: pathnames.pageStockMovementList,
        component: PageStockMovementList,
        navBarTitle: "Inventory",
        title: "Stock Movement",
        authenticate: true,
    },
];

export default routes;

import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AiOutlineClose } from "react-icons/ai";

const CommonModal = ({ isOpen, toggle, btnAction, leftBtnLabel, leftBtnToggle, rightBtnLabel, rightBtnToggle, logo, content, title, titleImg, ...rest }) => {
    return (
        <Modal isOpen={isOpen} className="page-modal" contentClassName="page-common-modal__content">
            <ModalHeader className="header">
                <AiOutlineClose className="header__close-button" onClick={leftBtnToggle} />
            </ModalHeader>
            <ModalBody className="body">
                <div className="body__header-logo">{titleImg}</div>
                <div className="body__title">{title}</div>
                <div className="body__description">{content}</div>
            </ModalBody>
            <ModalFooter className="footer">
                <div className="footer__left" onClick={leftBtnToggle}>
                    {leftBtnLabel}
                </div>
                <div className="footer__right">{rightBtnToggle}</div>
            </ModalFooter>
        </Modal>
    );
};

export default CommonModal;

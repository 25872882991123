import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import _ from "lodash";

const AppTableFooter = ({ paginationInfo, setPaginationInfo, loadData, searchQuery, statusFilter, sortHeader, sortAsc }) => {
    const [currentPage, setCurrentPage] = useState("");
    const inputRef = useRef(null);

    useEffect(() => {
        setCurrentPage(paginationInfo.page);
        setInputWidth(paginationInfo.page.toString());
        if (loadData) {
            loadData();
        }
    }, [paginationInfo.page, statusFilter, searchQuery, sortHeader, sortAsc]);

    const setInputWidth = (value) => {
        if (value.length === 0 || value.length === 1) inputRef.current.style.width = "24px";
        else inputRef.current.style.width = value.length * 10 + 12 + "px";
    };

    const setPagination = (page) => {
        // update pagination when onBlur, minus button, add button
        setPaginationInfo({ ...paginationInfo, page });
    };

    const onChangePagination = (e) => {
        if (e.target.value > paginationInfo.totalPages) return false;
        setCurrentPage(e.target.value);
        setInputWidth(e.target.value);
    };

    const onBlurPagination = (e) => {
        if (!e.target.value || e.target.value < 1 || e.target.value.trim() === "") setPagination(1);
        else setPagination(e.target.value);
    };

    const minusPage = () => {
        if (currentPage > 1) setPagination(Number(currentPage) - 1);
    };

    const addPage = () => {
        if (currentPage < paginationInfo.totalPages) setPagination(Number(currentPage) + 1);
    };

    return (
        <div className="app-table-footer">
            {/* <AppItemDropdown selectedItem={showItemsValue} setSelectedItem={setShowItemsValue} /> */}
            <div className="app-table-footer__item-show">Show 20 items</div>
            <div className="app-table-footer-pagination">
                Page
                <input ref={inputRef} value={currentPage} onChange={(e) => onChangePagination(e)} onBlur={(e) => onBlurPagination(e)} type="number" className="app-table-footer-pagination__input" min={1} max={paginationInfo.totalPages} /> of {paginationInfo.totalPages}
                <IoIosArrowBack onClick={minusPage} className="app-table-footer-pagination__arrow-back" />
                <IoIosArrowForward onClick={addPage} className="app-table-footer-pagination__arrow-forward" />
            </div>
        </div>
    );
};

export default AppTableFooter;

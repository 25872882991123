import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import App from "pages/app";
import store, { persistor } from "redux/store";
import InterceptorProvider from "contexts/with-interceptor-provider";
import "styles/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";

const Root = () => {
    return (
        <React.StrictMode>
            <Provider store={store}>
                <InterceptorProvider>
                    <PersistGate loading={null} persistor={persistor}>
                        <App />
                    </PersistGate>
                </InterceptorProvider>
            </Provider>
        </React.StrictMode>
    );
};

ReactDOM.render(<Root />, document.getElementById("root"));

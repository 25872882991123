// libraries
import React, { useState, useEffect } from "react";
import { NotificationContainer } from "react-notifications";
import * as Yup from "yup";
import { Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import api from "services/api";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateApiError } from "redux/slices/api-error-slice";
import InputMask from "react-input-mask";

// components
import AppInput from "components/app-input";
import AppItemDropdown from "components/app-item-dropdown";
import AppEditForm from "components/app-edit-form";
import AppFormRow from "components/app-form-row";
import AppButton from "components/app-button";

//asset
import logo from "../../assets/images/pages/user/newUser.png";
import createNotification from "services/helper";
import { updateOtherUserDetails } from "redux/slices/selected-user-details";

const PageViewOtherProfile = (props) => {
    const { userId } = props.location.state.selectedData;
    const history = useHistory();
    const dispatch = useDispatch();
    const [apiCall, setApiCall] = useState(false);
    const otherUserDetails = useSelector((state) => state.otherUserDetails);
    const [userGroup, setUserGroup] = useState(otherUserDetails?.userRolesGroup);
    const selectList = [
        { id: 1, value: "Admin", label: "Admin" },
        { id: 2, value: "Staff", label: "Staff" },
    ];

    const phoneFormatting = (dasherizedStr) => {
        if (!_.isEmpty(dasherizedStr)) {
            let countryCode = dasherizedStr.substr(0, 2);
            if (countryCode == "+6") {
                let newValue = dasherizedStr.slice(2);
                return newValue.replace(/[_-]/g, " ").replace(/ /g, "");
            } else return dasherizedStr;
        }
    };

    const btnEditUserProfile = async (values) => {
        try {
            let formattedPhone = phoneFormatting(values.contact);
            let payload = {
                userId: values.userId,
                userDisplayName: values.userDisplayName,
                userRolesGroup: values.userRolesGroup,
                userExtendedDetails: {
                    profileInfo: {
                        contact: formattedPhone,
                    },
                    appPreferences: {},
                },
                userStatus: values.userStatus,
            };
            const response = await api.post.editOtherProfile(payload);
            if (response.data["atfc-message"] == "SUCCESS") {
                await createNotification("success", "Profile edited successfully.").then(() =>
                    setTimeout(() => {
                        history.goBack();
                    }, 800)
                );
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    const formatDate = (value) => {
        return moment(value).format("DD/MM/YYYY");
    };

    const loadData = async () => {
        try {
            let response = await api.get.getOtherProfileDetails(userId);
            dispatch(updateOtherUserDetails(response));
        } catch (error) {
            console.log({ error });
            dispatch(updateApiError({ error }));
        }
    };

    useEffect(() => {
        loadData().then(() => setApiCall(true));
    }, []);

    return (
        otherUserDetails.userId == userId &&
        apiCall && (
            <Formik
                initialValues={{ ...otherUserDetails, contact: otherUserDetails?.userExtendedDetails?.profileInfo?.contact }}
                validationSchema={Yup.object({
                    userDisplayName: Yup.string().required("Required"),
                    email: Yup.string().required("Required").email("Invalid email"),
                    userRolesGroup: Yup.string().required("Required"),
                    contact: Yup.string(),
                })}
                onSubmit={(values, actions) => {
                    btnEditUserProfile(values);
                }}
                validator={() => ({})}>
                {({ values, errors, setFieldValue, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <AppEditForm
                            pageTitle={"User Profile"}
                            submitCancelButton={
                                <>
                                    <AppButton type="button" colorType="white" onClick={() => history.goBack()} label={"Cancel"} regularBtn={true} />
                                    <AppButton type="submit" colorType="primary" label={"Update"} regularBtn={true} />
                                </>
                            }>
                            <div className="row-wrapper center" style={{ marginBottom: 10 }}>
                                <div className="margin-right-10">
                                    <img width={70} height={70} src={logo} alt="" />
                                </div>
                                <div style={{ fontSize: 26, fontWeight: 700, marginLeft: 20 }}>{otherUserDetails?.userDisplayName}</div>
                            </div>
                            <table>
                                <tbody>
                                    <AppFormRow leftHeader={"Name"} rightSrc={<AppInput error={errors.userDisplayName} name="userDisplayName" type="text" value={values.userDisplayName} onChange={handleChange} placeholder="Name" />} />
                                    <AppFormRow leftHeader={"Email Address"} rightSrc={<AppInput disabled={true} name="email" type="text" value={values.email} />} />
                                    {/* <AppFormRow leftHeader={"Contact No (optional)"} rightSrc={<AppInput error={errors.contact} name="contact" type="text" value={values?.contact} onChange={handleChange} placeholder="+60 12 3456 789" />} /> */}
                                    <AppFormRow
                                        leftHeader={"Contact No (optional)"}
                                        rightSrc={
                                            <div className="row-wrapper">
                                                <div className="app-input">
                                                    <div className="app-input-text-leftLabel-wrapper">
                                                        <InputMask
                                                            className="app-input-text__long"
                                                            style={{ height: 40, border: "none", paddingLeft: 10 }}
                                                            mask="+6999-99999999"
                                                            name="contact"
                                                            value={values.contact}
                                                            onChange={(e) => {
                                                                const value = e.target.value || "";
                                                                setFieldValue("contact", value);
                                                            }}
                                                        />
                                                        {errors.contact && <div className="error-msg-wrapper">{errors.contact}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    />

                                    <AppFormRow
                                        leftHeader={"User Group"}
                                        rightSrc={<AppItemDropdown error={errors.userGroup} value={values.userRolesGroup} onChange={(value) => setFieldValue("userRolesGroup", value)} type="button" name="userRolesGroup" valueKey="value" labelKey="value" dropdownItems={selectList} labelChange={true} dropdownLabel={userGroup ? userGroup : "Select user group"} toggleButtonClass="solid" caret={true} setSelectedItem={setUserGroup} long={true} />}
                                    />
                                    <AppFormRow
                                        leftHeader={"Status"}
                                        rightSrc={
                                            <>
                                                <AppInput label={"Active"} value={"ACTIVE"} type="radio" name="status" checked={values.userStatus == "ACTIVE"} setValue={() => setFieldValue("userStatus", "ACTIVE")} />
                                                <AppInput label={"Disabled"} value={"DISABLED"} type="radio" name="status" checked={values.userStatus == "DISABLED"} setValue={(value) => setFieldValue("userStatus", "DISABLED")} />
                                            </>
                                        }
                                    />
                                    <AppFormRow leftHeader={"Date Joined"} rightSrc={<AppInput disabled={true} type="text" value={formatDate(values.createDate)} />} />
                                </tbody>
                            </table>
                            <NotificationContainer />
                        </AppEditForm>
                    </form>
                )}
            </Formik>
        )
    );
};

export default PageViewOtherProfile;

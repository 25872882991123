// libraries
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiFillPlusCircle } from "react-icons/ai";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import _ from "lodash";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import pathnames from "routes/pathnames";
import { Formik } from "formik";
import * as Yup from "yup";

// components
import AppBreadcrumb from "components/app-breadcrumb";
import AppInput from "components/app-input";
import AppEditForm from "components/app-edit-form";
import AppFormRow from "components/app-form-row";
import AppButton from "components/app-button";
import AppItemDropdown from "components/app-item-dropdown";

//api
import api from "services/api";

//redux
import { updateSupplierList } from "redux/slices/supplier-slice";
import { updateProductCategoryList } from "redux/slices/product-category.slice";
import PageQuickAddNewSupplier from "pages/relationships/quickAddNewSuppliers";
import AppCheckbox from "components/app-checkbox";
import NewVariantMedia from "components/app-upload-image-new-variant";
import { updateApiError } from "redux/slices/api-error-slice";
import createNotification from "services/helper";

const PageAddNewVariant = (props) => {
    // const { allocatedQuantity, categoryCode, categoryId, categoryName, createBy, createDate, inStockQuantity, modifyBy, modifyDate, productCode, productDescription, productId, productItemCount, productName, productStatus } = props.location.state.selectedData;
    const { productId } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const productCategoryList = useSelector((state) => state.productCategoryList);
    const supplierList = useSelector((state) => state.supplierList);
    const [productName, setProductName] = useState();
    const [productCode, setProductCode] = useState();
    const [barcode, setBarcode] = useState();
    const [supplierName, setSupplierName] = useState();
    const [supplierId, setSupplierId] = useState();
    const [country, setCountry] = useState();
    const [color, setColor] = useState();
    const [weight, setWeight] = useState();
    const [productVariant, setProductVariant] = useState();
    const [dimensionWidth, setDimensionWidth] = useState();
    const [dimensionLength, setDimensionLength] = useState();
    const [dimensionHeight, setDimensionHeight] = useState();
    const [autoGenerateBarcode, setAutoGenerateBarcode] = useState(false);
    const [weightUnit, setWeightUnit] = useState("g");
    const [dimensionUnit, setDimensionUnit] = useState("cm");
    const [status, setStatus] = useState("");
    const [value, setValue] = useState("");
    const [media, setMedia] = useState([]);
    const [addSupplierDisplay, setAddSupplierDisplay] = useState(false);

    const [paginationInfo, setPaginationInfo] = useState({
        page: 1,
        size: 100,
        numberOfElements: 16,
        totalElements: 160,
        totalPages: 100,
    });

    const breadcrumbArray = [
        {
            title: "Inventory",
            onClick: () => {
                history.push(pathnames.pageInventoryProductList);
            },
        },
        {
            title: "Products",
            onClick: () => {
                history.push(pathnames.pageInventoryProductList);
            },
        },
        {
            title: productName,
            onClick: () => {
                history.push(`/inventory/editProduct/${productId}`);
            },
        },
        { title: "Add New Variant", onClick: null },
    ];
    const selectListCountry = [
        { id: 1, value: "Malaysia", label: " Malaysia" },
        { id: 2, value: "Singapore", label: " Singapore" },
    ];
    const weightArray = [
        {
            label: "g",
        },
        {
            label: "kg",
        },
    ];
    const dimensionArray = [
        {
            label: "cm",
        },
        {
            label: "mm",
        },
        {
            label: "m",
        },
    ];

    const addSupplierToggle = () => {
        setAddSupplierDisplay(!addSupplierDisplay);
    };

    const goAddNewSupplier = () => {
        addSupplierToggle();
    };

    const btnAddSupplier = async (payload) => {
        try {
            const response = await api.post.addSupplier(payload);
            if (response.data["atfc-message"] == "SUCCESS") {
                await createNotification("success", "New supplier created successfully.").then(() => {
                    setTimeout(() => {
                        addSupplierToggle();
                    }, 800);
                });
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    const loadData = async () => {
        try {
            let response = await api.get.getSuppliersList({ page: paginationInfo.page, pageSize: paginationInfo.size, statusFilter: "ACTIVE" });
            dispatch(updateSupplierList(response));
            let categoryResponse = await api.get.getProductCategoryList({ size: paginationInfo.size, page: paginationInfo.page });
            dispatch(updateProductCategoryList(categoryResponse));
            let responseDetails = await api.get.getProductDetails(productId);
            setProductName(responseDetails.data["atfc-data"]?.productName);
            setProductCode(responseDetails.data["atfc-data"]?.productCode);
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    };

    useEffect(() => {
        loadData();
    }, [addSupplierDisplay]);

    const formatDate = (value) => {
        return moment(value).format("DD/MM/YYYY");
    };

    const addNewVariant = async (values) => {
        const formData = new FormData();
        media.map((item, index) => {
            return formData.append("file", item?.data, item?.data?.name);
        });
        let payload = {
            productItemVariant: values.productVariant,
            productItemStatus: values.status,
            productItemBarcode: values.barcode,
            productItemAttrs: {
                basicAttrs: {
                    originCountry: values.country,
                },
                extendedAttrs: {
                    color: values.color,
                    weight: values.weight,
                    weightUnit: values.weightUnit,
                    dimensionHeight: values.dimensionHeight,
                    dimensionLength: values.dimensionLength,
                    dimensionWidth: values.dimensionWidth,
                    dimensionUnit: values.dimensionUnit,
                },
            },
            supplierId: values.supplierId,
            productId: productId,
        };
        formData.append("json", JSON.stringify(payload));
        try {
            let response = await api.post.newVariant(formData, "media");
            if (response.data["atfc-message"] == "SUCCESS") {
                await createNotification("success", "New variant created successfully.").then(() => {
                    loadData();
                    setTimeout(() => {
                        history.push(pathnames.pageInventoryVariants);
                    }, 800);
                });
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };
    return (
        <Formik
            initialValues={{ supplierId: "", productVariant: "", status: "ACTIVE", barcode: "", country: "ACTIVE", color: "", weight: "", weightUnit: "g", dimensionHeight: "", dimensionLength: "", dimensionWidth: "", dimensionUnit: "cm" }}
            validationSchema={Yup.object({
                productVariant: Yup.string().required("Required"),
                supplierId: Yup.string().required("Required"),
                status: Yup.string().required("Required"),
            })}
            onSubmit={(values, actions) => {
                addNewVariant(values);
            }}
            validator={() => ({})}>
            {({ values, errors, setFieldValue, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <AppEditForm
                        breadcrumb={<AppBreadcrumb breadcrumbArray={breadcrumbArray} />}
                        pageTitle={"Add New Variant"}
                        submitCancelButton={
                            <>
                                <AppButton colorType="white" type="button" onClick={() => history.goBack()} label={"Cancel"} regularBtn={true} />
                                <AppButton colorType="primary" type="submit" label={"Create"} regularBtn={true} />
                            </>
                        }>
                        <table>
                            <tbody>
                                <AppFormRow leftHeader={"Product ID"} rightSrc={<AppInput disabled={true} type="text" value={productCode} />} />
                                <AppFormRow leftHeader={"Product Name"} rightSrc={<AppInput disabled={true} type="text" value={productName} />} />
                            </tbody>
                        </table>
                        <div className="app-edit-page__subTitle-wrapper">
                            <p className="app-edit-page__subTitle-wrapper__text">Basic Info</p>
                        </div>
                        <table>
                            <tbody>
                                {/* <AppFormRow leftHeader={"SKU"} rightSrc={<AppInput type="text" disabled={true} />} /> */}
                                <AppFormRow
                                    leftHeader={"Barcode"}
                                    rightSrc={
                                        <>
                                            <AppInput type="text" value={values.barcode} name="barcode" onChange={handleChange} setValue={setBarcode} />
                                            {/* <div className="margin-left-10">
                                    <AppCheckbox
                                        setValue={setAutoGenerateBarcode}
                                        // checked={autoGenerateBarcode}
                                        checked={true}
                                        onChange={() => setAutoGenerateBarcode(!autoGenerateBarcode)}
                                        type="square-checkbox"
                                        label="Autogenerate Barcode"
                                    />
                                </div> */}
                                        </>
                                    }
                                />

                                <AppFormRow
                                    leftHeader={"Supplier"}
                                    rightSrc={
                                        <>
                                            <AppItemDropdown
                                                valueKey="supplierId"
                                                labelKey="supplierName"
                                                name="supplierId"
                                                error={errors.supplierId}
                                                onChange={(value) => setFieldValue("supplierId", value, false)}
                                                dropdownItems={supplierList?.content ? supplierList?.content : []}
                                                labelChange={true}
                                                toggleButtonClass="solid"
                                                caret={true}
                                                setSelectedItem={setSupplierName}
                                                setSelectedItemValue={setSupplierId}
                                                dropdownLabel={supplierName ? supplierName : "Select supplier"}
                                                long={true}
                                            />
                                            <AppButton label="Add New Supplier" type="button" onClick={goAddNewSupplier} buttonType="transparent" colorType="primary" leftSrc={<AiFillPlusCircle size={16} />} />
                                        </>
                                    }
                                />
                                <AppFormRow
                                    leftHeader={"Country of Origin"}
                                    rightSrc={
                                        <>
                                            <AppItemDropdown valueKey="value" labelKey="label" name="country" onChange={(value) => setFieldValue("country", value)} dropdownItems={!_.isEmpty(selectListCountry) ? selectListCountry : []} labelChange={true} toggleButtonClass="solid" caret={true} setSelectedItem={setCountry} setSelectedItemValue={setCountry} dropdownLabel={country ? country : "Select country"} long={true} />
                                            {/* <AppInput labelKey="label" valueKey="value" type="select" selectList={!_.isEmpty(selectListCountry) ? selectListCountry : []} value={country} setValue={setCountry} placeholder="Select country" /> */}
                                        </>
                                    }
                                />
                                <AppFormRow
                                    leftHeader={"Status"}
                                    rightSrc={
                                        <>
                                            <AppInput label={"Active"} value={"ACTIVE"} type="radio" name="status" checked={values.status == "ACTIVE"} setValue={() => setFieldValue("status", "ACTIVE")} />
                                            <AppInput label={"Disabled"} value={"DISABLED"} type="radio" name="status" checked={values.status == "DISABLED"} setValue={() => setFieldValue("status", "DISABLED")} error={errors.status} />
                                        </>
                                    }
                                />
                                {/* <AppFormRow leftHeader={"Date Created"} rightSrc={<AppInput disabled={true} type="text" value={formatDate(createDate)} />} />
                    <AppFormRow leftHeader={"Last Updated"} rightSrc={<AppInput disabled={true} type="text" value={formatDate(modifyDate)} />} /> */}
                            </tbody>
                        </table>
                        <div className="app-edit-page__subTitle-wrapper">
                            <p className="app-edit-page__subTitle-wrapper__text">Attributes</p>
                        </div>
                        <table>
                            <tbody>
                                <AppFormRow leftHeader={"Color"} rightSrc={<AppInput type="text" name="color" value={values.color} onChange={handleChange} placeholder="Enter color, eg Red" />} />
                                <AppFormRow
                                    leftHeader={"Weight"}
                                    rightSrc={
                                        <>
                                            <AppInput length="short" type="text" name="weight" value={values.weight} onChange={handleChange} placeholder="Enter weight, eg 100g" />
                                            <AppItemDropdown long={false} valueKey="label" labelKey="label" name="weightUnit" onChange={(value) => setFieldValue("weightUnit", value, false)} dropdownItems={weightArray} labelChange={false} toggleButtonClass="solid" caret={true} setSelectedItem={setWeightUnit} dropdownLabel={values.weightUnit} />
                                        </>
                                    }
                                />
                                <AppFormRow
                                    leftHeader={"Dimension"}
                                    leftHeaderStickUp={true}
                                    rightSrcVertical={true}
                                    rightSrc={
                                        <>
                                            <span style={{ flexDirection: "row", display: "flex", paddingBottom: "20px", width: 420 }}>
                                                <AppInput leftLabel="Width" length="extra_short" type="text" name="dimensionWidth" onChange={handleChange} value={values.dimensionWidth} placeholder="Enter size, eg 80cm" />
                                                <AppItemDropdown type="button" long={false} valueKey="label" labelKey="label" onChange={(value) => setFieldValue("dimensionUnit", value, false)} dropdownItems={dimensionArray} labelChange={false} toggleButtonClass="solid" caret={true} setSelectedItem={setDimensionUnit} dropdownLabel={values.dimensionUnit} />
                                            </span>
                                            <span style={{ flexDirection: "row", display: "flex", paddingBottom: "20px", width: 420 }}>
                                                <AppInput leftLabel="Length" length="extra_short" type="text" name="dimensionLength" onChange={handleChange} value={values.dimensionLength} placeholder="Enter size, eg 80cm" />
                                                <AppItemDropdown disabled={true} long={false} valueKey="label" labelKey="label" dropdownItems={dimensionArray} labelChange={false} toggleButtonClass="solid" setSelectedItem={setDimensionUnit} dropdownLabel={values.dimensionUnit} />
                                            </span>
                                            <span style={{ flexDirection: "row", display: "flex", paddingBottom: "20px", width: 420 }}>
                                                <AppInput leftLabel="Height" length="extra_short" type="text" name="dimensionHeight" onChange={handleChange} value={values.dimensionHeight} placeholder="Enter size, eg 80cm" />
                                                <AppItemDropdown disabled={true} long={false} valueKey="label" labelKey="label" dropdownItems={dimensionArray} labelChange={false} toggleButtonClass="solid" setSelectedItem={setDimensionUnit} dropdownLabel={values.dimensionUnit} />
                                            </span>
                                            <br />
                                        </>
                                    }
                                />
                                <AppFormRow type="textarea" leftHeader={"Variants"} rightSrc={<AppInput type="textarea" name="productVariant" value={values.productVariant} error={errors.productVariant} onChange={handleChange} placeholder="Enter product variants" />} />
                            </tbody>
                        </table>
                        <div>Images</div>
                        <div>* File format can be jpeg or png, maximum size 1MB.</div>
                        <NewVariantMedia setMedia={(value) => setMedia(value)} />
                        <NotificationContainer />
                        <PageQuickAddNewSupplier isOpen={addSupplierDisplay} toggle={addSupplierToggle} btnAction={btnAddSupplier} />
                    </AppEditForm>
                </form>
            )}
        </Formik>
    );
};

export default PageAddNewVariant;

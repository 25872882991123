// libraries
import React, { useState, useEffect } from "react";
import { NotificationContainer, NotificationManager } from "react-notifications";
import * as Yup from "yup";
import { Formik } from "formik";
import _ from "lodash";
import api from "services/api";
import { useHistory } from "react-router-dom";
import pathnames from "routes/pathnames";

// components
import AppInput from "components/app-input";
import AppEditForm from "components/app-edit-form";
import AppFormRow from "components/app-form-row";
import AppButton from "components/app-button";
import AppRowSelect from "components/app-row-select";
import createNotification from "services/helper";

const tableTypeList = [
    { label: "My Profile", value: 0 },
    { label: "Change Password", value: 1 },
];

const PageChangePassword = () => {
    const history = useHistory();
    const [showPass, setShowPass] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);
    const [showConNewPass, setShowConNewPass] = useState(false);
    const [selectedSection, setSelectedSection] = useState(tableTypeList[1].value);

    const tableTypeSelected = (item) => {
        setSelectedSection(item.value);
    };

    const btnEditPassword = async (values) => {
        try {
            let payload = {
                currentPassword: values.previousPassword,
                newPassword: values.newPassword,
                confirmedPassword: values.conNewPassword,
            };
            console.log("payload", payload);
            const response = await api.post.editPassword(payload);
            if (response.data["atfc-message"] == "SUCCESS") {
                createNotification("success", "Password update successfully.");
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    useEffect(() => {
        if (selectedSection == 0) {
            history.push(pathnames.pageUserProfile);
        }
    }, [selectedSection]);

    return (
        <Formik
            initialValues={{ previousPassword: "", newPassword: "", conNewPassword: "" }}
            validationSchema={Yup.object({
                previousPassword: Yup.string().required("Password is required"),
                newPassword: Yup.string()
                    .required("New password is required")
                    .min(8, "Must be 8 characters or more")
                    .matches(/[a-z]+/, "Should contain at least 1 lowercase")
                    .matches(/[A-Z]+/, "Should contain at least 1 uppercase")
                    .matches(/\d+/, "Should contain at least 1 number")
                    .matches(/[@$!%*#?&]+/, "Should contain at least 1 special character"),
                conNewPassword: Yup.string()
                    .required("Confirm password is required")
                    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
            })}
            onSubmit={(values, actions) => {
                btnEditPassword(values);
            }}
            validator={() => ({})}>
            {({ values, errors, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <AppEditForm
                        pageSwitch={<AppRowSelect list={tableTypeList} selected={tableTypeSelected} selectedIndex={selectedSection} />}
                        pageTitle={"Change Password"}
                        submitCancelButton={
                            <>
                                <AppButton type="button" colorType="white" onClick={() => history.goBack()} label={"Cancel"} regularBtn={true} />
                                <AppButton type="submit" colorType="primary" label={"Update"} regularBtn={true} />
                            </>
                        }>
                        <table>
                            <tbody>
                                <AppFormRow leftHeader={"Previous Password"} rightSrc={<AppInput name="previousPassword" error={errors.previousPassword} type={"password"} showInput={showPass} setShowInput={setShowPass} value={values.password} onChange={handleChange} placeholder="Enter password" />} />
                                <AppFormRow leftHeader={"New Password"} rightSrc={<AppInput name="newPassword" error={errors.newPassword} type={"password"} showInput={showNewPass} setShowInput={setShowNewPass} value={values.newPassword} onChange={handleChange} placeholder="Enter new password" />} />
                                <AppFormRow leftHeader={"Confirm New Password"} rightSrc={<AppInput name="conNewPassword" type={"password"} error={errors.conNewPassword} showInput={showConNewPass} setShowInput={setShowConNewPass} value={values.conNewPassword} onChange={handleChange} placeholder="Re-enter new password" />} />
                            </tbody>
                        </table>
                        <NotificationContainer />
                    </AppEditForm>
                </form>
            )}
        </Formik>
    );
};

export default PageChangePassword;

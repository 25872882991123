const CONSTANSTS = {
    ENDPOINT_PATH: {
        //dashboard
        DASHBOARD: "/wms-app/dashboard",

        //authentication
        PRE_SIGN_UP: "/auth/pre-signup",
        VERIFY_PRE_SIGN_UP: "/auth/verify-pre-signup",
        COMPLETE_SIGN_UP: "/auth/signup",
        SIGN_IN: "/auth/signin",
        RESET_PASSWORD: "/auth/reset-password",
        FORGOT_PASSWORD: "/auth/forgot-password",
        VERIFY_FORGOT_PASSWORD: "/auth/verify-forgot-password",
        VERIFY_PRE_SIGNUP: "/auth/verify-pre-signup",

        //user
        USER_LIST: "/auth/user-mgmt/list-users",
        ADD_USER: "/auth/user-mgmt/add-new-user",
        USER_PROFILE: "/auth/user-mgmt/view-user/myself",
        VIEW_OTHER_PROFILE: "/auth/user-mgmt/view-user",
        EDIT_PROFILE: "/auth/user-mgmt/edit-user/myself",
        EDIT_PASSWORD: "/auth/user-mgmt/change-password",
        EDIT_OTHER_PROFILE: "/auth/user-mgmt/edit-user",

        //supplier
        SUPPLIER_LIST: "/wms-app/inventory/suppliers",
        EDIT_SUPPLIER: "/wms-app/inventory/suppliers/edit",
        ADD_SUPPLIER: "/wms-app/inventory/suppliers/new",

        //inventory
        INVENTORY_LIST: "/wms-app/inventory/products",
        PRODUCT_CATEGORY_LIST: "/wms-app/inventory/product-categories",
        ADD_PRODUCT_CATEGORY: "/wms-app/inventory/product-categories/new",
        EDIT_PRODUCT_CATEGORY: "/wms-app/inventory/product-categories/edit",
        GET_PRODUCT_DETAIL: "/wms-app/inventory/products/view",
        ADD_PRODUCT: "/wms-app/inventory/products/new",
        VARIANT_LIST: "/wms-app/inventory/product-items",
        EDIT_PRODUCT: "/wms-app/inventory/products/edit",
        CREATE_STOCK_COUNT: "/wms-app/inventory/product-items/with-warehouse-stock-details",
        ASSIGN_NEW_STOCK_POSITION: "/wms-app/inventory/stocks/assign-stock-new-position",
        VARIANT_LIST_DETAILS: "/wms-app/inventory/product-items/with-warehouse-stock-details",
        ADD_MEDIA: "/wms-app/inventory/product-items/edit/add-media-assets",
        REMOVE_MEDIA: "/wms-app/inventory/product-items/edit/remove-media-asset",
        RETRIEVE_MEDIA: "/wms-app/media-assets/media",
        VARIANT_DETAILS: "/wms-app/inventory/product-items/view",
        EDIT_VARIANT: "/wms-app/inventory/product-items/edit",
        NEW_VARIANT: "/wms-app/inventory/product-items/new",
        VARIANT_DETAILS_WITH_STOCK: (payload) => `/wms-app/inventory/product-items/view/${payload}/with-warehouse-stock-details`,
        UPDATE_VARIANT_STOCK: "/wms-app/inventory/stocks/adjust-stock-for-position",
        STOCK_MOVEMENT: "/wms-app/inventory/product-items/audit-stock",

        //warehouse
        WAREHOUSE_LIST: "/wms-app/warehouse/warehouses",
        EDIT_WAREHOUSE: "/wms-app/warehouse/warehouses/edit",
        NEW_WAREHOUSE: "/wms-app/warehouse/warehouses/new",
        SINGLE_WAREHOUSE_SECTION: "/wms-app/warehouse/warehouse-sections/view",
        GET_RACK_INFO: "/wms-app/warehouse/warehouse-section-maps/view-section-map-for-spath",
        GET_WAREHOUSE_DETAILS: "/wms-app/warehouse/warehouses/view",
    },
    REGEX: {
        // eslint-disable-next-line
        email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        // eslint-disable-next-line
        minEightChar: /^.{8,}$/,
        // eslint-disable-next-line
        checkEmpty: /^(?!\s*$).+/,
        // eslint-disable-next-line
        atLeastOneNumber: /.*[0-9].*/,
        // eslint-disable-next-line
        atLeaseOneUppercase: /.*[A-Z].*/,
        // eslint-disable-next-line
        atLeaseOneSpecialChar: /\W/,
        // eslint-disable-next-line
        name: /^(?!\s*$).+/,
        // eslint-disable-next-line
        spaces: /\s/g,
    },
};

export default CONSTANSTS;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    content: null,
    pageable: null,
    totalPages: 1,
    totalElements: null,
    last: true,
    size: 5,
    number: 0,
    sort: null,
    numberOfElements: null,
    first: true,
    last: false,
};

export const userListSlice = createSlice({
    name: "userList",
    initialState,
    reducers: {
        updateUserList: (state, action) => {
            return {
                ...state,
                ...action.payload.data["atfc-data"],
            };
        },
    },
});

export const { updateUserList } = userListSlice.actions;

export default userListSlice.reducer;

// libraries
import React, { useState, useEffect } from "react";
import { BiExport } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

//api
import api from "services/api";

// components
import AppTableColumnHeader from "components/app-table-column-header";
import AppTableFooter from "components/app-table-footer";
import AppSuccessMessage from "components/app-success-message";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTablePage from "components/app-table-page.jsx";
import AppButton from "components/app-button";

//redux
import { updateCreateStockCount } from "redux/slices/create-stock-count";
import pathnames from "routes/pathnames";
import { AiFillPlusCircle } from "react-icons/ai";
import AppLinkButton from "components/app-link-button";
import { updateApiError } from "redux/slices/api-error-slice";

const PageCreateStockCount = (props) => {
    const dispatch = useDispatch();
    const [sortSelected, setSortSelected] = useState("");
    const createStockCountList = useSelector((state) => state.createStockCount);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [paginationInfo, setPaginationInfo] = useState({
        page: 1,
        size: 16,
        numberOfElements: 16,
        totalElements: 160,
        totalPages: 100,
    });

    const columns = [
        {
            id: "no",
            Header: () => <AppTableColumnHeader title="No" />,
            Cell: (row) => {
                return <div>{row.row.index + 1}</div>;
            },
            width: 50,
            minWidth: 50,
            maxWidth: 50,
        },
        {
            id: "SKU",
            columnsListLabel: "SKU",
            Header: () => <AppTableColumnHeader title="SKU" />,
            accessor: "productItemCode",
            width: 100,
            minWidth: 100,
            maxWidth: 100,
        },
        {
            id: "productName",
            columnsListLabel: "Product Name",
            Header: (header) => <AppTableColumnHeader title="Product Name" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => <div className="page-product-list-table--line-clamp-2">{row.productName}</div>,
            minWidth: 105,
            width: 150,
            maxWidth: 250,
            sorting: true,
        },
        {
            id: "Warehouse",
            columnsListLabel: "Warehouse",
            Header: (header) => <AppTableColumnHeader title="Warehouse" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => <div className="page-product-list-table--line-clamp-2">{row.warehouseName}</div>,

            width: 90,
            minWidth: 90,
            maxWidth: 90,
            sorting: true,
        },
        {
            id: "Variant",
            columnsListLabel: "Variant",
            Header: (header) => <AppTableColumnHeader title="Variant" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => <div className="page-product-list-table--line-clamp-2">{row.productItemVariant}</div>,

            width: 90,
            minWidth: 90,
            maxWidth: 90,
        },
        {
            id: "Stock Position",
            columnsListLabel: "Stock Position",
            Header: (header) => <AppTableColumnHeader title="Stock Position" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => <AppLinkButton label={row.warehouseSectionMapPath1} buttonType="linkBtn" linkTo={pathnames.pageInventoryProductList} buttonColor="primary" />,
            minWidth: 90,
        },
        {
            id: "Quantity",
            columnsListLabel: "Quantity",
            Header: (header) => <AppTableColumnHeader title="Quantity" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => <div className="page-product-list-table--line-clamp-2">{row.inStockQuantity}</div>,

            width: 90,
            minWidth: 90,
            maxWidth: 90,
        },
    ];

    const breadcrumbArray = [
        { title: "Inventory", onClick: null },
        { title: "Variants", onClick: null },
        { title: "Create Stock Count", onClick: null },
    ];

    const loadData = async () => {
        try {
            let response = await api.get.getCreateStockCount({ size: paginationInfo.size, page: paginationInfo.page });
            dispatch(updateCreateStockCount(response));
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <AppTablePage
            popUpMessage={<AppSuccessMessage show={showSuccessMessage} message="Stock amount has been edited." />}
            breadcrumb={<AppBreadcrumb breadcrumbArray={breadcrumbArray} />}
            pageTitle="Create Stock Count"
            showResult="Showing all 2000 results"
            tableFunctions={[<AppButton label="Export All" buttonType="transparent" colorType="primary" leftSrc={<BiExport size={20} />} />]}
            tableColumns={columns}
            tableData={createStockCountList.content ? createStockCountList.content : []}
            tablePageFooter={<AppTableFooter paginationInfo={paginationInfo} loadData={loadData} setPaginationInfo={(paginationInfo) => setPaginationInfo(paginationInfo)} />}
        />
    );
};

export default PageCreateStockCount;

import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { IoMdArrowDropdown } from "react-icons/io";

const AppItemDropdown = ({ dropdownItems, dropdownLabel, toggleButtonClass, dropdownDirection, labelColorChange, caret, dropdownItemDisable, setSelectedItem, setSelectedItemValue, labelChange, long, valueKey, labelKey, error, onChange, ...rest }) => {
    const [showItems, setShowItems] = useState(false);
    const [selectedLabel, setSelectedLabel] = useState("");

    const dropdownItemClicked = (item) => {
        // setSelectedLabel(item[labelKey]);
        setShowItems(false);
        setSelectedItem(item[labelKey]);
        if (setSelectedItemValue) setSelectedItemValue(item[valueKey]);
    };

    const getToggleButtonClassName = (toggleButtonClass, textBlack) => {
        const classNames = ["item-dropdown__toggle-button"];
        if (toggleButtonClass === "solid") classNames.push("item-dropdown__toggle-button-solid");
        if (textBlack) classNames.push("item-dropdown__text--black");
        return classNames.join(" ");
    };

    const getLabelClassName = (rotate) => {
        const classNames = ["item-dropdown__caret"];

        if (rotate) classNames.push("item-dropdown--rotate");

        return classNames.join(" ");
    };

    const getDropdownItemClassName = ({ item, selectedLabel }) => {
        const classNames = ["item-dropdown__menu-item"];

        if (selectedLabel === item[labelKey]) classNames.push("item-dropdown__menu-item--selected");

        return classNames.join(" ");
    };

    const getDropdownClassName = (long) => {
        const classNames = ["item-dropdown"];
        if (long) classNames.push("item-dropdown-long");
        else classNames.push("item-dropdown-short");
        return classNames.join(" ");
    };

    return (
        <Dropdown className={getDropdownClassName(long)} direction={dropdownDirection ? dropdownDirection : "down"} isOpen={showItems} toggle={() => setShowItems(!showItems)} {...rest}>
            <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={showItems} className="item-dropdown__toggle">
                <button type="button" className={getToggleButtonClassName(toggleButtonClass, labelColorChange ? showItems : false)}>
                    {/* <span className="">{selectedLabel && labelChange ? selectedLabel : dropdownLabel}</span> */}
                    <span>{dropdownLabel}</span>
                    {caret && (
                        <div className={getLabelClassName(showItems)}>
                            <IoMdArrowDropdown />
                        </div>
                    )}
                </button>
            </DropdownToggle>
            <DropdownMenu
                className="item-dropdown__menu"
                modifiers={{
                    setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: (data) => {
                            return {
                                ...data,
                                styles: {
                                    ...data.styles,
                                    overflow: "auto",
                                    maxHeight: 200,
                                },
                            };
                        },
                    },
                }}>
                {dropdownItems.map((item, index) => (
                    <DropdownItem
                        disabled={dropdownItemDisable}
                        className={getDropdownItemClassName({ item, selectedLabel })}
                        key={index}
                        onClick={() => {
                            dropdownItemClicked(item);
                            if (valueKey && onChange) {
                                onChange(item[valueKey]);
                            } else if (labelKey && onChange) {
                                onChange(item[labelKey]);
                            }
                        }}>
                        {item.leftSrc ? <span className="item-dropdown__menu-icon">{item.leftSrc}</span> : null}
                        <span className="item-dropdown__menu-item-label">{item[labelKey]}</span>
                    </DropdownItem>
                ))}
            </DropdownMenu>
            {error && <div className="error-msg-wrapper">{error}</div>}
        </Dropdown>
    );
};

export default AppItemDropdown;

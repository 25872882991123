// libraries
import React, { useState } from "react";
import { NotificationContainer, NotificationManager } from "react-notifications";
import * as Yup from "yup";
import { Formik } from "formik";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import pathnames from "routes/pathnames";
import InputMask from "react-input-mask";

// components
import AppBreadcrumb from "components/app-breadcrumb";
import AppInput from "components/app-input";
import AppItemDropdown from "components/app-item-dropdown";
import AppEditForm from "components/app-edit-form";
import AppFormRow from "components/app-form-row";
import AppButton from "components/app-button";
import api from "services/api";

//asset
import logo from "../../assets/images/pages/user/newUser.png";
import createNotification from "services/helper";

const PageAddUser = () => {
    const history = useHistory();
    const [userGroup, setUserGroup] = useState();
    const selectList = [
        { id: 1, value: "Admin", label: "Admin" },
        { id: 2, value: "Staff", label: "Staff" },
    ];

    const breadcrumbArray = [
        {
            title: "Setting",
            onClick: () => {
                history.push(pathnames.pageUserList);
            },
        },
        {
            title: "User List",
            onClick: () => {
                history.push(pathnames.pageUserList);
            },
        },
        { title: "Add New Staff", onClick: null },
    ];

    const phoneFormatting = (dasherizedStr) => {
        let countryCode = dasherizedStr.substr(0, 2);
        if (!_.isEmpty(dasherizedStr) && countryCode == "+6") {
            let newValue = dasherizedStr.slice(2);
            return newValue.replace(/[_-]/g, " ").replace(/ /g, "");
        } else return dasherizedStr;
    };

    const btnAddUser = async (values) => {
        let formattedPhone = phoneFormatting(values.contact);
        try {
            let payload = {
                preSignUpId: values.email,
                preSignUpRolesGroup: values.userGroup,
                extraDetails: {
                    displayName: values.name,
                    contact: formattedPhone,
                },
            };
            const response = await api.post.addUser(payload);
            if (response.data["atfc-message"] == "SUCCESS") {
                await createNotification("success", "New user created successfully.").then(() => {
                    setTimeout(() => {
                        history.goBack();
                    }, 800);
                });
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    return (
        <Formik
            initialValues={{ name: "", email: "", contact: "", userGroup: "" }}
            validationSchema={Yup.object({
                name: Yup.string().required("Required"),
                email: Yup.string().required("Required").email("Invalid email"),
                userGroup: Yup.string().required("Required"),
                contact: Yup.string(),
            })}
            onSubmit={(values, actions) => {
                btnAddUser(values);
            }}
            validator={() => ({})}>
            {({ values, errors, setFieldValue, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <AppEditForm
                        breadcrumb={<AppBreadcrumb breadcrumbArray={breadcrumbArray} />}
                        pageTitle={"New User"}
                        leftSrcImg={
                            <div className="margin-right-10">
                                <img width={70} height={70} src={logo} alt="" />
                            </div>
                        }
                        submitCancelButton={
                            <>
                                <AppButton colorType="white" onClick={() => history.goBack()} label={"Cancel"} regularBtn={true} />
                                <AppButton type="submit" colorType="primary" label={"Create"} regularBtn={true} />
                            </>
                        }>
                        <table>
                            <tbody>
                                <AppFormRow leftHeader={"Name"} rightSrc={<AppInput error={errors.name} name="name" type="text" value={values.name} onChange={handleChange} placeholder="Name" />} />
                                <AppFormRow leftHeader={"Email Address"} rightSrc={<AppInput name="email" error={errors.email} type="text" value={values.email} onChange={handleChange} placeholder="Email address" />} />
                                {/* <AppFormRow leftHeader={"Contact No (optional)"} rightSrc={<AppInput name="contact" type="text" error={errors.contact} value={values.contact} onChange={handleChange} placeholder="+60 12 3456 789" />} /> */}
                                <AppFormRow
                                    leftHeader={"Contact No (optional)"}
                                    rightSrc={
                                        <div className="row-wrapper">
                                            <div className="app-input">
                                                <div className="app-input-text-leftLabel-wrapper">
                                                    <InputMask
                                                        className="app-input-text__long"
                                                        style={{ height: 40, border: "none", paddingLeft: 10 }}
                                                        mask="+6999-99999999"
                                                        name="contact"
                                                        value={values.contact}
                                                        onChange={(e) => {
                                                            const value = e.target.value || "";
                                                            setFieldValue("contact", value);
                                                        }}
                                                    />
                                                    {errors.contact && <div className="error-msg-wrapper">{errors.contact}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />
                                <AppFormRow leftHeader={"User Group"} rightSrc={<AppItemDropdown error={errors.userGroup} onChange={(value) => setFieldValue("userGroup", value)} type="button" name="state" valueKey="value" labelKey="value" dropdownItems={selectList} labelChange={false} dropdownLabel={userGroup ? userGroup : "Select user group"} toggleButtonClass="solid" caret={true} setSelectedItem={setUserGroup} long={true} />} />
                                <AppFormRow leftHeader={"Date Joined"} rightSrc={<AppInput disabled={true} type="text" placeholder="Today" />} />
                            </tbody>
                        </table>
                        <NotificationContainer />
                    </AppEditForm>
                </form>
            )}
        </Formik>
    );
};

export default PageAddUser;

// libraries
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// commons
import CONSTANSTS from "common/constansts";
// routes
import pathnames from "routes/pathnames";
// api
import api from "services/api";
// components
import AppAuthInput from "components/pages/authentication/app-auth-input";
import AppButton from "components/app-button";
import AppAuthLayout from "components/pages/authentication/app-auth-layout";
import AppLinkButton from "components/app-link-button";
// assets
import IconMail from "assets/images/pages/authentication/icon-mail.svg";
import IconLock from "assets/images/pages/authentication/icon-lock.svg";

const inputs = {
    email: {
        placeholder: "Email",
        required: "Email required",
        invalid: "Invalid email",
        regex: CONSTANSTS.REGEX.email,
    },
    password: {
        placeholder: "Password",
        required: "Password required",
        invalid: "Invalid password",
        regex: CONSTANSTS.REGEX.minEightChar,
    },
};

const defaultFields = {
    email: {
        error: "",
        value: "",
    },
    password: {
        error: "",
        value: "",
    },
};

const PageLogin = () => {
    const history = useHistory();
    const [field, setField] = useState(defaultFields);
    const [appButton, setAppButton] = useState({
        disable: false,
        label: "Log In",
    });

    const onHandleSubmit = async () => {
        const emailValidate = CONSTANSTS.REGEX.email.test(field.email.value);
        const passwordValidate = CONSTANSTS.REGEX.minEightChar.test(field.password.value);

        if (emailValidate && passwordValidate) {
            setAppButton({
                disable: true,
                label: "Logging In...",
            });
            try {
                let payload = {
                    signInId: field.email.value,
                    password: field.password.value,
                };
                const response = await api.post.signIn(payload);
                localStorage.setItem("@storage_token", response.data["atfc-data"].authToken);
                history.push(pathnames.pageDashboard);
            } catch (error) {
                setField({
                    email: {
                        ...field.email,
                        error: "Invalid email or password",
                    },
                    password: {
                        ...field.password,
                        error: "Invalid email or password",
                    },
                });
                setAppButton({
                    disable: false,
                    label: "Log In",
                });
            }
        }
    };

    const errorMessage = (name, value) => {
        if (!value) {
            return inputs[name].required;
        } else if (!inputs[name].regex.test(value)) {
            return inputs[name].invalid;
        } else {
            return "";
        }
    };

    const onHandleOnChange = (event) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;

        setField({
            ...field,
            [name]: {
                ...field[name],
                error: errorMessage(name, value),
                value: value.replace(CONSTANSTS.REGEX.spaces, ""),
            },
        });
    };

    const onHandleOnBlur = (event) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;

        setField({
            ...field,
            [name]: {
                ...field[name],
                error: errorMessage(name, value),
                value: value.replace(CONSTANSTS.REGEX.spaces, ""),
            },
        });
    };

    return (
        <AppAuthLayout>
            <div className="page-login">
                <div className="page-login__page-name">Log In</div>
                <div className="page-login__input-wrapper">
                    <AppAuthInput name="email" iconAlt="mail-icon" iconSrc={IconMail} error={field.email.error} placeholder={inputs.email.placeholder} onChange={onHandleOnChange} onBlur={onHandleOnBlur} value={field.email.value} />
                </div>

                <div className="page-login__input-wrapper">
                    <AppAuthInput name="password" type="password" iconAlt="password-icon" iconSrc={IconLock} error={field.password.error} placeholder={inputs.password.placeholder} onChange={onHandleOnChange} onBlur={onHandleOnBlur} value={field.password.value} />
                </div>

                <div className="row-wrapper">
                    <div className="page-login__forgot-password">
                        <AppLinkButton buttonColor="primary" buttonType="link" linkTo={pathnames.pageForgotPassword} label={"Forgot your password?"} />
                    </div>
                    <span>&nbsp;&nbsp;</span>
                    <div className="page-login__forgot-password">
                        <AppLinkButton buttonColor="primary" buttonType="link" linkTo={pathnames.pageVerifyEmail} label={"Activate account here"} />
                    </div>
                </div>

                <div className="page-login__primary-button">
                    <AppButton colorType="primary" onClick={onHandleSubmit} label={appButton.label} disabled={appButton.disable} />
                </div>
            </div>
        </AppAuthLayout>
    );
};

export default PageLogin;

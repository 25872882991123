import React, { useState, useRef } from "react";
import AppButton from "./app-button";
import { AiFillPlusCircle } from "react-icons/ai";
import logo from "../assets/images/empty-image.png";
const NewVariantMedia = (props) => {
    const [pictures, setPictures] = useState([]);
    const hiddenFileInput = useRef(null);
    const handleImageUpload = (e) => {
        const tempArr = [];
        [...e.target.files].forEach((file) => {
            tempArr.push({
                data: file,
                url: URL.createObjectURL(file),
            });
        });
        setPictures(tempArr);
        props.setMedia(tempArr);
    };

    const removePic = (item) => {
        let arr = pictures.filter((i) => i !== item);
        setPictures(arr);
        props.setMedia(arr);
    };
    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    return (
        <div>
            {pictures && (
                <div className="app-upload-image">
                    {pictures?.map((pic, index) => (
                        <div className="image">
                            <img className="imageItem" key={index} width={"200px"} height={"200px"} alt="not found" src={pic.url} />
                            <div className="delete">
                                <AppButton buttonType="transparent" type="button" colorType="closePic" label="X" onClick={() => removePic(pic)} />
                            </div>
                        </div>
                    ))}
                    <div className="emptyImage" onClick={handleClick}>
                        <img src={logo} alt="" />
                    </div>
                    <br />
                </div>
            )}
            <br />
            <AppButton label="Upload Image" type="button" onClick={handleClick} buttonType="transparent" colorType="primary" leftSrc={<AiFillPlusCircle size={16} />} />
            <input ref={hiddenFileInput} type="file" onChange={handleImageUpload} multiple accept="image/*" style={{ display: "none" }} />
        </div>
    );
};

export default NewVariantMedia;

// libraries
import React, { Fragment, useState } from "react";
import Modal from "react-modal";
// components
import AppReactTable from "components/app-react-table";
import AppButton from "./app-button";
// assets
import iconReset from "assets/images/components/app-table-page/icon-reset.svg";
import { IoMdArrowDropdown } from "react-icons/io";

const AppTablePage = ({ totalElements, popUpMessage, breadcrumb, pageTitle, tableTypeSelect, searchBar, tableFunctions, tablePageFooter, tableColumns, tableData, useHideColumn, modalIsOpen, modalOnRequestClose, modalContent }) => {
    const [showViewColumns, setShowViewColumns] = useState(false);

    const TableFunctionsRow = ({ allColumns }) => {
        const trigger = (allColumns) => {
            allColumns.forEach((column, index) => {
                column.toggleHidden(column.initialHideColumn ? true : false);
            });
        };
        return (
            <div className="table-function-row">
                <div className="table-function-row__total-result">Showing all {totalElements} results</div>
                <div className="table-function-row__buttons-list">
                    {tableFunctions.length &&
                        tableFunctions.map((item, index) => (
                            <div key={index} className="table-function-row__button-wrapper">
                                {item}
                            </div>
                        ))}
                    {useHideColumn && (
                        <div onClick={(e) => e.stopPropagation()}>
                            <div className="table-function-row__button-wrapper">
                                <AppButton label="View Columns" buttonType="transparent" colorType="primary" labelColorChange={showViewColumns} onClick={() => setShowViewColumns(!showViewColumns)} rotateSrc={showViewColumns} rightSrc={<IoMdArrowDropdown size={16} />} />
                            </div>
                            <div className="table-function-row__view-columns-dropdown-list">
                                {showViewColumns &&
                                    allColumns.map((column, index) => {
                                        if (index === 0) {
                                            return (
                                                <button key={"reset" + index} className="view-columns-dropdown-list__reset-button" onClick={() => trigger(allColumns)}>
                                                    <img className="view-columns-dropdown-list__reset-image" src={iconReset} alt="" />
                                                    Reset
                                                </button>
                                            );
                                        }
                                        if (column.columnsListLabel && !column.sticky) {
                                            return (
                                                <Fragment key={index}>
                                                    <div className="view-columns-dropdown-list__selection">
                                                        <input className="view-columns-dropdown-list__checkbox" id={column.columnsListLabel} type="checkbox" {...column.getToggleHiddenProps()} />
                                                        <label htmlFor={column.columnsListLabel} className="view-columns-dropdown-list__text">
                                                            {column.columnsListLabel}
                                                        </label>
                                                    </div>
                                                </Fragment>
                                            );
                                        } else return null;
                                    })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    Modal.setAppElement("#root");

    return (
        <Fragment>
            <div className="app-table-page" onClick={() => setShowViewColumns(false)}>
                {popUpMessage}
                <Modal isOpen={modalIsOpen} onRequestClose={modalOnRequestClose} className="app-table-page__modal" overlayClassName="app-table-page__modal-overlay" closeTimeoutMS={500}>
                    <div>{modalContent}</div>
                </Modal>
                <div className="app-table-page__wrapper">
                    {breadcrumb}
                    <div className="app-table-page__title">{pageTitle}</div>
                    {tableTypeSelect && <div className="app-table-page__row-selection-list">{tableTypeSelect}</div>}
                    <div className="app-table-page__searchbar-wrapper">{searchBar}</div>
                    <div className="page-product-list-table">
                        <AppReactTable columns={tableColumns} data={tableData} useHideColumn={useHideColumn}>
                            <TableFunctionsRow />
                        </AppReactTable>
                    </div>
                </div>
            </div>
            {tablePageFooter}
        </Fragment>
    );
};

export default AppTablePage;

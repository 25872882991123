import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    warehousesShelfSummary: [],
    inventoryProductSummary: [],
};

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        updateDashboard: (state, action) => {
            return {
                ...state,
                ...action.payload.data["atfc-data"],
            };
        },
    },
});

export const { updateDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pageSize: null,
    pageNumber: 1,
    numberOfElements: null,
    totalElements: null,
    totalPages: 0,
    first: null,
    last: null,
    hasNext: null,
    hasPrevious: null,
    hasContent: null,
    content: null,
};

export const supplierListSlice = createSlice({
    name: "supplierList",
    initialState,
    reducers: {
        updateSupplierList: (state, action) => {
            return {
                ...state,
                ...action.payload.data["atfc-data"],
            };
        },
    },
});

export const { updateSupplierList } = supplierListSlice.actions;

export default supplierListSlice.reducer;

import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AiOutlineClose } from "react-icons/ai";
import AppFormRow from "components/app-form-row";
import AppInput from "components/app-input";
import { FiGrid } from "react-icons/fi";
import "react-notifications/lib/notifications.css";
import { NotificationContainer, NotificationManager } from "react-notifications";
import api from "services/api";
import { useHistory } from "react-router-dom";
import createNotification from "services/helper";
import _ from "lodash";

const PageEditProductCategory = ({ isOpen, toggle, btnAction, selectedData, loadData }) => {
    const [categoryName, setCategoryName] = useState();
    const [status, setStatus] = useState();
    const history = useHistory();

    useEffect(() => {
        setCategoryName(selectedData?.categoryName);
        setStatus(selectedData?.categoryStatus);
    }, [selectedData]);

    const updateProductCategory = async () => {
        try {
            let payload = {
                categoryName: categoryName,
                categoryStatus: status,
                categoryId: selectedData?.categoryId,
            };
            const response = await api.post.editProductCategory(payload);
            if (response.data["atfc-message"] == "SUCCESS") {
                await createNotification("success", "Product category updated successfully.").then(() =>
                    setTimeout(() => {
                        history.goBack();
                    }, 800)
                );
            } else {
                createNotification("error", "Error occur, please try again.");
            }
            loadData();
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    return (
        <Modal isOpen={isOpen} className="page-add-new-category" contentClassName="page-add-new-category__content">
            <ModalHeader className="header">
                <AiOutlineClose className="header__close-button" onClick={toggle} />
            </ModalHeader>
            <ModalBody className="body">
                <FiGrid size={50} className="page-add-new-category__header-logo" />
                <div className="body__title">Edit Category</div>
                <table>
                    <tbody>
                        <AppFormRow leftHeader={"Category Code"} rightSrc={<AppInput disabled={true} type="text" value={selectedData?.categoryCode} placeholder={selectedData?.categoryCode} />} />
                        <AppFormRow leftHeader={"Category Name"} rightSrc={<AppInput type="text" value={categoryName} setValue={setCategoryName} placeholder={categoryName} />} />
                        <AppFormRow
                            leftHeader={"Status"}
                            rightSrc={
                                <>
                                    <AppInput label={"Active"} value={"ACTIVE"} type="radio" name="status" checked={status == "ACTIVE"} setValue={() => setStatus("ACTIVE")} />
                                    <AppInput label={"Disabled"} value={"DISABLED"} type="radio" name="status" checked={status == "DISABLED"} setValue={() => setStatus("DISABLED")} />
                                </>
                            }
                        />
                    </tbody>
                </table>
            </ModalBody>
            <ModalFooter className="footer">
                <div className="footer__left" onClick={toggle}>
                    Cancel
                </div>
                <div className="footer__right" onClick={updateProductCategory}>
                    Update
                </div>
            </ModalFooter>
            <NotificationContainer />
        </Modal>
    );
};

export default PageEditProductCategory;

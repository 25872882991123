import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    productItemStockSummary: {
        totalInStockQuantity: null,
        totalAllocatedQuantity: null,
        totalAvailableQuantity: null,
    },
    productItemStockDetails: null,
};

export const variantListDetailsSlice = createSlice({
    name: "variantListDetailsWithStock",
    initialState,
    reducers: {
        updateVariantListDetails: (state, action) => {
            return {
                ...state,
                ...action.payload.data["atfc-data"],
            };
        },
    },
});

export const { updateVariantListDetails } = variantListDetailsSlice.actions;

export default variantListDetailsSlice.reducer;

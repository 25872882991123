import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = (props) => {
    const data = {
        labels: ["Loaded", "Empty"],
        datasets: [
            {
                data: [props?.item?.loadedShelfCount, props?.item?.emptyShelfCount],
                backgroundColor: ["#36476F", "#D1B896"],
                borderWidth: 1,
            },
        ],
    };
    return (
        <Doughnut
            data={data}
            options={{
                responsive: true,
                maintainAspectRatio: false,
                //
                plugins: {
                    title: {
                        display: true,
                        text: `${props?.item?.warehouseName}  `,
                        position: "top",
                        align: "end",
                    },
                    subtitle: {
                        display: true,
                        text: [`Total Shelf: ${props?.item?.totalShelfCount}  `, `Loaded: ${props?.item?.loadedShelfCount} (${props?.item?.loadedShelfPercent}%)  `, `Empty: ${props?.item?.emptyShelfCount} (${props?.item?.emptyShelfPercent}%)  `],
                        position: "top",
                        align: "end",
                    },
                    legend: {
                        display: true,
                        position: "right",
                    },
                },
            }}
            // width={500}
            // height={400}
        />
    );
};
export default DoughnutChart;

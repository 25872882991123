import React from "react";

const getButtonClassName = ({ colorType, buttonType, labelColorChange, disabled, regularBtn }) => {
    const classNames = [];
    switch (buttonType) {
        case "transparent":
            classNames.push("app-button__transparent-button");

            if (colorType === "primary") classNames.push("app-button__primary-text");

            if (colorType === "warning") classNames.push("app-button__warning-text");

            if (colorType === "closePic") classNames.push("app-button__closePic");

            if (labelColorChange) classNames.push("app-button--black-text");

            break;
        default:
            regularBtn ? classNames.push("app-button__regular-button") : classNames.push("app-button__button");

            if (colorType === "primary") classNames.push("app-button__primary-button");

            if (colorType === "warning") classNames.push("app-button__warning-button");

            if (colorType === "white") classNames.push("app-button__white-button");

            if (disabled) classNames.push("app-button--disable");

            break;
    }

    return classNames.join(" ");
};

const getRightSrcClassName = (rotateSrc) => {
    const classNames = ["app-button__icon-wrapper"];
    if (rotateSrc) classNames.push("app-button--rotate");
    return classNames.join(" ");
};

const AppButton = ({ buttonType, colorType, labelColorChange, onClick, label, rightSrc, leftSrc, rotateSrc, disabled, regularBtn, children, ...rest }) => {
    return (
        <button disabled={disabled} onClick={onClick} className={getButtonClassName({ colorType, buttonType, labelColorChange, disabled, regularBtn })} {...rest}>
            {leftSrc ? <div className={getRightSrcClassName(rotateSrc)}>{leftSrc}</div> : null}
            <span>{label}</span>
            {rightSrc ? <div className={getRightSrcClassName(rotateSrc)}>{rightSrc}</div> : null}
            {children}
        </button>
    );
};

export default AppButton;

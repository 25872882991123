// libraries
import React, { Fragment } from "react";
import logo from "../assets/images/pages/warehouse/editWarehouseLogo.png";
const AppEditForm = ({ breadcrumb, pageTitle, pageSwitch, children, submitCancelButton, leftSrcImg, pageTitleRightSrc }) => {
    const getClassName = (value) => {
        const classNames = [""];
        if (leftSrcImg === true) classNames.push(value + "-withLogo");
        else classNames.push(value);
        return classNames.join(" ");
    };

    return (
        <Fragment>
            <div className="app-edit-page">
                <div className="app-edit-page__wrapper">
                    {breadcrumb}
                    {pageSwitch}
                    <div className="app-edit-page__title">
                        {leftSrcImg}
                        <span className={getClassName("app-edit-page__title-label")}>{pageTitle}</span>
                        <span className="app-edit-page__title-label-rightSrc">{pageTitleRightSrc}</span>
                    </div>
                    <div className="app-edit-page__children">{children}</div>
                    <div className="app-edit-page__button-container">{submitCancelButton}</div>
                </div>
            </div>
        </Fragment>
    );
};

export default AppEditForm;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pageSize: null,
    pageNumber: null,
    numberOfElements: null,
    totalElements: null,
    totalPages: null,
    first: null,
    last: null,
    hasNext: null,
    hasPrevious: null,
    hasContent: null,
    content: null,
};

export const warehouseListSlice = createSlice({
    name: "productCategoryList",
    initialState,
    reducers: {
        updateWarehouseList: (state, action) => {
            return {
                ...state,
                ...action.payload.data["atfc-data"],
            };
        },
    },
});

export const { updateWarehouseList } = warehouseListSlice.actions;

export default warehouseListSlice.reducer;

import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AiOutlineClose } from "react-icons/ai";
import _ from "lodash";
//api
import api from "services/api"; //routes
import pathnames from "routes/pathnames";

//components
import AppFormRow from "components/app-form-row";
import AppInput from "components/app-input";
import AppViewStockPosition from "components/app-view-stock-position";
import AppRowSelect from "components/app-row-select";
import AppSearchBar from "components/app-search-bar";
import AppLinkButton from "components/app-link-button";
import { AiFillPlusCircle } from "react-icons/ai";
import AppButton from "components/app-button";
import AppSelectRack from "components/app-select-rack";

//asset
import iconBookshelf from "assets/images/icon-bookshelf.svg";
import { FiGrid } from "react-icons/fi";
import { BiExport } from "react-icons/bi";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ModalStockPosition = ({ isOpen, toggle, btnAction, selectedData, warehouseName, selectShelf, setSelectShelf, setSelectRack, stockAmount, setStockAmount, rackInfo, getRackInfo, addBtnDisabledCheck }) => {
    const [searchInput, setSearchInput] = useState("");
    const [shelfLocation, setShelfLocation] = useState();
    const [expandFirstPart, setExpandFirstPart] = useState(true);
    const [expandSecondPart, setExpandSecondPart] = useState(false);
    const tableTypeList = [{ label: "Section 1", value: 0 }];

    const tableTypeSelected = (value) => {
        console.log("value => ", value);
    };

    return (
        <Modal size="xl" isOpen={isOpen} className="page-view-stock-position" contentClassName="page-view-stock-position__content">
            <ModalHeader className="header">
                <AiOutlineClose className="header__close-button" onClick={toggle} />
            </ModalHeader>
            <ModalBody className="body">
                <div className="body__container">
                    <img src={iconBookshelf} alt="icon edit" />
                    <div className="body__title">Select Stock Position</div>
                </div>
                <div style={{ width: "100%" }}>
                    <Accordion defaultExpanded={true} expanded={expandFirstPart} onClick={() => setExpandFirstPart(!expandFirstPart)}>
                        <AccordionSummary
                            style={{
                                backgroundColor: "#36476F",
                                color: "white",
                                flex: 1,
                                display: "flex",
                            }}
                            expandIcon={<ExpandMoreIcon htmlColor="white" />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography>Select Shelf</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ overflowX: "auto" }}>
                            <AppViewStockPosition
                                searchInput={searchInput}
                                getRackInfo={getRackInfo}
                                selectedData={selectedData}
                                mainTitle={"Stock Position"}
                                pageTitle={warehouseName}
                                selectShelf={selectShelf}
                                setSelectShelf={setSelectShelf}
                                setShelfLocation={(value) => {
                                    setShelfLocation(value);
                                    setExpandFirstPart(false);
                                    setExpandSecondPart(true);
                                }}
                                leftSrcImg={true}
                                tableTypeSelect={<AppRowSelect list={tableTypeList} selected={tableTypeSelected} />}
                                searchBar={
                                    <AppSearchBar
                                        placeholder="Search by position"
                                        onKeyEnter={(e) => {
                                            setSearchInput(e.target.value);
                                        }}
                                        onBlur={(e) => {
                                            e.target.value !== searchInput && setSearchInput(e.target.value);
                                        }}
                                    />
                                }
                                showResult="Showing all 2000 results"
                                tableFunctions={[
                                    <AppLinkButton label="Product Category" buttonType="linkBtn" linkTo={pathnames.pageProductCategory} buttonColor="primary" leftSrc={<FiGrid size={20} />} />,
                                    <AppLinkButton label="Add New Product" buttonType="linkBtn" linkTo={pathnames.pageAddProduct} buttonColor="primary" leftSrc={<AiFillPlusCircle size={16} />} />,
                                    <AppButton label="Export All" buttonType="transparent" colorType="primary" leftSrc={<BiExport size={20} />} />,
                                ]}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <br />
                    <Accordion defaultExpanded={false} expanded={expandSecondPart} onClick={() => setExpandSecondPart(!expandSecondPart)}>
                        <AccordionSummary style={{ backgroundColor: "#36476F", color: "white" }} expandIcon={<ExpandMoreIcon htmlColor="white" />} aria-controls="panel2a-content" id="panel2a-header">
                            <Typography>Select Rack</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AppSelectRack selectShelf={selectShelf} shelfLocation={shelfLocation} data={rackInfo} setSelectRack={setSelectRack} />
                        </AccordionDetails>
                    </Accordion>
                </div>
                <table>
                    <tbody>
                        <AppFormRow leftHeader={"Stock Amount"} rightSrc={<AppInput type="text" value={stockAmount} setValue={setStockAmount} placeholder="Enter stock amount to add" />} />
                    </tbody>
                </table>
            </ModalBody>
            <ModalFooter className="footer">
                <div className="footer__left" onClick={toggle}>
                    Cancel
                </div>
                <button type="button" className="footer__right" style={{ backgroundColor: "transparent", borderWidth: 0 }} onClick={() => btnAction()}>
                    Add
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalStockPosition;

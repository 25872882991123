// libraries
import React, { useState, useEffect } from "react";
import { NotificationContainer, NotificationManager } from "react-notifications";
import pathnames from "routes/pathnames";
import { useHistory, useParams } from "react-router-dom";
import InputMask from "react-input-mask";
import _ from "lodash";

// components
import AppBreadcrumb from "components/app-breadcrumb";
import AppInput from "components/app-input";
import AppItemDropdown from "components/app-item-dropdown";
import AppEditForm from "components/app-edit-form";
import AppFormRow from "components/app-form-row";
import AppButton from "components/app-button";

//api
import api from "services/api";
import createNotification from "services/helper";

const PageEditSuppliers = (props) => {
    const {
        supplierId,
        supplierName,
        supplierBusinessNo,
        personInCharge,
        supplierEmail,
        supplierContact,
        supplierAddress: { address1, address2, city, country, postcode, state },
        supplierStatus,
        createDate,
        modifyDate,
    } = props.location.state.selectedData;
    const history = useHistory();
    const [suppName, setSuppName] = useState(supplierName);
    const [suppBusinessNo, setSuppBusinessNo] = useState(supplierBusinessNo);
    const [sPersonInCharge, setSPersonInCharge] = useState(personInCharge);
    const [suppEmail, setSuppEmail] = useState(supplierEmail);
    const [suppContact, setSuppContact] = useState(supplierContact);
    const [saddress1, setSAddres1] = useState(address1);
    const [saddress2, setSAddres2] = useState(address2);
    const [sCity, setSCity] = useState(city);
    const [sCountry, setSCountry] = useState(country);
    const [spostCode, setSPostcode] = useState(postcode);
    const [sState, setSState] = useState(state);
    const [suppStatus, setSuppStatus] = useState(supplierStatus);
    const selectList = [
        { id: 1, value: "Johor", label: "Johor" },
        { id: 2, value: "Kedah", label: "Kedah" },
        { id: 3, value: "Kelantan", label: "Kelantan" },
        { id: 4, value: "Kuala Lumpur", label: "Kuala Lumpur" },
        { id: 5, value: "Malacca", label: "Malacca" },
        { id: 6, value: "Negeri Sembilan", label: "Negeri Sembilan" },
        { id: 7, value: "Pahang", label: "Pahang" },
        { id: 8, value: "Penang", label: "Penang" },
        { id: 9, value: "Perak", label: "Perak" },
        { id: 10, value: "Perlis", label: "Perlis" },
        { id: 11, value: "Sabah", label: "Sabah" },
        { id: 12, value: "Sarawak", label: "Sarawak" },
        { id: 13, value: "Selangor", label: "Selangor" },
        { id: 14, value: "Terengganu", label: "Terengganu" },
    ];
    const selectListCountry = [
        { id: 1, value: "Malaysia", label: "Malaysia" },
        { id: 2, value: "Singapore", label: "Singapore" },
    ];

    const breadcrumbArray = [
        {
            title: "Relationships",
            onClick: () => {
                history.push(pathnames.pageRelationshipSuppliers);
            },
        },
        {
            title: "Suppliers",
            onClick: () => {
                history.push(pathnames.pageRelationshipSuppliers);
            },
        },
        { title: "Edit Supplier Info", onClick: null },
    ];

    const phoneFormatting = (dasherizedStr) => {
        let countryCode = dasherizedStr.substr(0, 2);
        if (!_.isEmpty(dasherizedStr) && countryCode == "+6") {
            let newValue = dasherizedStr.slice(2);
            return newValue.replace(/[_-]/g, " ").replace(/ /g, "");
        } else return dasherizedStr;
    };

    const btnEditSupplier = async () => {
        try {
            let payload = {
                supplierId: supplierId,
                supplierName: suppName,
                supplierBusinessNo: suppBusinessNo,
                supplierContact: phoneFormatting(suppContact),
                supplierEmail: suppEmail,
                supplierStatus: suppStatus,
                personInCharge: sPersonInCharge,
                supplierAddress: {
                    address1: saddress1,
                    address2: saddress2,
                    postcode: spostCode,
                    city: sCity,
                    state: sState,
                    country: sCountry,
                },
            };
            const response = await api.post.editSupplier(payload);
            if (response.data["atfc-message"] == "SUCCESS") {
                await createNotification("success", "Supplier info update successfully.").then(() =>
                    setTimeout(() => {
                        history.goBack();
                    }, 800)
                );
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    return (
        <AppEditForm
            breadcrumb={<AppBreadcrumb breadcrumbArray={breadcrumbArray} />}
            pageTitle={supplierName}
            submitCancelButton={
                <>
                    <AppButton colorType="white" onClick={() => history.goBack()} label={"Cancel"} regularBtn={true} />
                    <AppButton colorType="primary" onClick={() => btnEditSupplier()} label={"Update"} regularBtn={true} />
                </>
            }>
            <table>
                <tbody>
                    <AppFormRow leftHeader={"Company Name"} rightSrc={<AppInput type="text" value={suppName} setValue={setSuppName} placeholder="Enter product name" />} />
                    <AppFormRow leftHeader={"Registration No"} rightSrc={<AppInput type="text" value={suppBusinessNo} setValue={setSuppBusinessNo} placeholder="Enter product name" />} />
                    <AppFormRow leftHeader={"Person In Charge"} rightSrc={<AppInput type="text" value={sPersonInCharge} setValue={setSPersonInCharge} placeholder="Enter product name" />} />
                    <AppFormRow leftHeader={"Email Address"} rightSrc={<AppInput type="text" value={suppEmail} setValue={setSuppEmail} placeholder="Enter product name" />} />
                    {/* <AppFormRow leftHeader={"Contact No"} rightSrc={<AppInput type="text" value={suppContact} setValue={setSuppContact} placeholder="Enter product name" />} /> */}
                    <AppFormRow
                        leftHeader={"Contact No"}
                        rightSrc={
                            <div className="row-wrapper">
                                <div className="app-input">
                                    <div className="app-input-text-leftLabel-wrapper">
                                        <InputMask
                                            className="app-input-text__long"
                                            style={{ height: 40, border: "none", paddingLeft: 10 }}
                                            mask="+6999-99999999"
                                            name="contact"
                                            value={suppContact}
                                            onChange={(e) => {
                                                const value = e.target.value || "";
                                                setSuppContact(value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    />

                    <AppFormRow leftHeader={"Address Line 1"} rightSrc={<AppInput type="text" value={saddress1} setValue={setSAddres1} placeholder="Enter product name" />} />
                    <AppFormRow leftHeader={"Address Line 2"} rightSrc={<AppInput type="text" value={saddress2} setValue={setSAddres2} placeholder="Enter product name" />} />
                    <AppFormRow leftHeader={"Postcode"} rightSrc={<AppInput type="text" value={spostCode} setValue={setSPostcode} placeholder="Enter product name" />} />
                    <AppFormRow leftHeader={"City"} rightSrc={<AppInput type="text" value={sCity} setValue={setSCity} placeholder="City" />} />
                    <AppFormRow leftHeader={"State"} rightSrc={<AppItemDropdown valueKey="value" labelKey="label" dropdownItems={selectList} labelChange={setSState} dropdownLabel={sState} toggleButtonClass="solid" caret={true} setSelectedItem={setSState} long={true} />} />
                    <AppFormRow leftHeader={"Country"} rightSrc={<AppItemDropdown valueKey="value" labelKey="label" dropdownItems={selectListCountry} labelChange={setSCountry} dropdownLabel={sCountry} toggleButtonClass="solid" caret={true} setSelectedItem={setSCountry} long={true} />} />
                    <AppFormRow
                        leftHeader={"Status"}
                        rightSrc={
                            <>
                                <AppInput label={"Active"} value={"ACTIVE"} type="radio" name="status" checked={suppStatus == "ACTIVE"} setValue={() => setSuppStatus("ACTIVE")} />
                                <AppInput label={"Disabled"} value={"DISABLED"} type="radio" name="status" checked={suppStatus == "DISABLED"} setValue={() => setSuppStatus("DISABLED")} />
                            </>
                        }
                    />
                    <AppFormRow leftHeader={"Date Created"} rightSrc={createDate} />
                    <AppFormRow leftHeader={"Last Updated"} rightSrc={modifyDate} />
                </tbody>
            </table>
            <NotificationContainer />
        </AppEditForm>
    );
};

export default PageEditSuppliers;

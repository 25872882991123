import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    productItemId: null,
    productItemCode: null,
    productItemVariant: null,
    productItemAttrs: null,
    productItemMediaAssets: [],
    productItemStatus: null,
    supplierId: null,
    productId: null,
    createDate: null,
    modifyDate: null,
    createBy: null,
    modifyBy: null,
};

export const selectedVariantDetails = createSlice({
    name: "selectedVariantDetails",
    initialState,
    reducers: {
        updateSelectedVariantDetails: (state, action) => {
            return {
                ...state,
                ...action.payload.data["atfc-data"],
            };
        },
    },
});

export const { updateSelectedVariantDetails } = selectedVariantDetails.actions;

export default selectedVariantDetails.reducer;

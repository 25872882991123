// libraries
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiFillPlusCircle, AiOutlineCheckCircle } from "react-icons/ai";
import "react-notifications/lib/notifications.css";
import { NotificationContainer, NotificationManager } from "react-notifications";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

// components
import AppBreadcrumb from "components/app-breadcrumb";
import AppInput from "components/app-input";
import AppEditForm from "components/app-edit-form";
import AppFormRow from "components/app-form-row";
import AppButton from "components/app-button";

//api
import api from "services/api";
import PageAddProductCategory from "./addProductCategory";

//redux
import { updateProductCategoryList } from "redux/slices/product-category.slice";
import AppItemDropdown from "components/app-item-dropdown";
import CommonModal from "components/app-common-modal";
import AppLinkButton from "components/app-link-button";
import pathnames from "routes/pathnames";
import { FiGrid } from "react-icons/fi";
import { updateApiError } from "redux/slices/api-error-slice";
import createNotification from "services/helper";

const PageAddProduct = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [addNewCategoryDisplay, setAddNewCategoryDisplay] = useState(false);
    const [productName, setProductName] = useState();
    const [productCategory, setProductCategory] = useState();
    const [productCategoryId, setProductCategoryId] = useState();
    const [productDescription, setProductDescription] = useState();
    const [productStatus, setProductStatus] = useState();
    const [statusRemark, setStatusRemark] = useState();
    const [commonModal, setCommonModal] = useState(false);
    const [productId, setProductId] = useState();
    const productCategoryList = useSelector((state) => state.productCategoryList);
    const [paginationInfo, setPaginationInfo] = useState({
        page: 1,
        size: 100,
        numberOfElements: 16,
        totalElements: 160,
        totalPages: 100,
    });
    const breadcrumbArray = [
        {
            title: "Inventory",
            onClick: () => {
                history.push(pathnames.pageInventoryProductList);
            },
        },
        {
            title: "Products",
            onClick: () => {
                history.push(pathnames.pageInventoryProductList);
            },
        },
        { title: "Add New Product", onClick: null },
    ];

    const addBtnToggle = () => {
        setAddNewCategoryDisplay(!addNewCategoryDisplay);
    };

    const goAddNewCategory = () => {
        addBtnToggle();
    };

    const btnAddNewCategory = async (payload) => {
        try {
            const response = await api.post.addNewProductCategory(payload);
            if (response.data["atfc-message"] == "SUCCESS") {
                await createNotification("success", "New category created successfully.").then(() => {
                    loadData();
                    setTimeout(() => {
                        setAddNewCategoryDisplay(!addNewCategoryDisplay);
                    }, 800);
                });
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    const loadData = async () => {
        try {
            let response = await api.get.getProductCategoryList({ page: paginationInfo.page, pageSize: paginationInfo.size });
            dispatch(updateProductCategoryList(response));
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const addProductBtn = async (values) => {
        try {
            let payload = {
                productName: values.productName,
                productDescription: values.productDescription,
                productStatus: values.productStatus,
                categoryId: values.categoryId,
                statusRemark: values.statusRemark,
            };
            const response = await api.post.addProduct(payload);
            if (response.data["atfc-message"] == "SUCCESS") {
                setCommonModal(true);
                setProductId(response?.data["atfc-data"]?.productId);
            } else {
                createNotification("error", "Error occur, please try again.");
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    const cancelToggle = () => {
        history.goBack();
    };
    return (
        <>
            <Formik
                initialValues={{ productName: "", productDescription: "", productStatus: "ACTIVE", categoryId: "", statusRemark: "" }}
                validationSchema={Yup.object({
                    productDescription: Yup.string().required("Required"),
                    productName: Yup.string().required("Required"),
                    categoryId: Yup.string().required("Required"),
                    productStatus: Yup.string().required("Required"),
                })}
                onSubmit={(values, actions) => {
                    addProductBtn(values);
                }}
                validator={() => ({})}>
                {({ values, errors, setFieldValue, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <AppEditForm
                            breadcrumb={<AppBreadcrumb breadcrumbArray={breadcrumbArray} />}
                            pageTitle={"Add New Product"}
                            submitCancelButton={
                                <>
                                    <AppButton colorType="white" type={"button"} onClick={cancelToggle} label={"Cancel"} regularBtn={true} />
                                    <AppButton colorType="primary" type={"submit"} label={"Create"} regularBtn={true} />
                                </>
                            }>
                            <table>
                                <tbody>
                                    <AppFormRow leftHeader={"Product ID"} rightSrc={<AppInput type="text" disabled={true} placeholder="Auto generate" />} />
                                    <AppFormRow leftHeader={"Product Name"} rightSrc={<AppInput name={"productName"} type="text" error={errors.productName} value={values.productName} onChange={handleChange} placeholder="Enter product name" />} />
                                    <AppFormRow
                                        leftHeader={"Product Category"}
                                        rightSrc={
                                            <>
                                                <AppItemDropdown
                                                    valueKey="categoryId"
                                                    labelKey="categoryName"
                                                    name={"categoryId"}
                                                    onChange={(value) => setFieldValue("categoryId", value)}
                                                    error={errors.categoryId}
                                                    dropdownItems={!_.isEmpty(productCategoryList?.content) ? productCategoryList?.content : []}
                                                    labelChange={setProductCategory}
                                                    dropdownLabel={productCategory}
                                                    toggleButtonClass="solid"
                                                    caret={true}
                                                    setSelectedItem={setProductCategory}
                                                    setSelectedItemValue={setProductCategoryId}
                                                    long={true}
                                                />
                                                <AppButton label="Add New Category" onClick={goAddNewCategory} buttonType="transparent" colorType="primary" leftSrc={<AiFillPlusCircle size={16} />} />
                                            </>
                                        }
                                    />
                                    <AppFormRow type="textarea" leftHeader={"Product Description"} rightSrc={<AppInput name="productDescription" type="textarea" error={errors.productDescription} value={values.productDescription} onChange={handleChange} placeholder="Describe a little bit about your product..." />} />
                                    <AppFormRow
                                        leftHeader={"Status"}
                                        rightSrc={
                                            <>
                                                <AppInput label={"Active"} value={"ACTIVE"} type="radio" name="productStatus" checked={values.productStatus == "ACTIVE"} setValue={() => setFieldValue("productStatus", "ACTIVE")} />
                                                <AppInput label={"Disabled"} value={"DISABLED"} error={errors.productStatus} type="radio" name="productStatus" checked={values.productStatus == "DISABLED"} setValue={() => setFieldValue("productStatus", "DISABLED")} />
                                            </>
                                        }
                                    />
                                    <AppFormRow type="textarea" leftHeader={"Status Remarks"} rightSrc={<AppInput name={"statusRemark"} type="textarea" error={errors.statusRemark} value={values.statusRemark} onChange={handleChange} placeholder="Please enter remarks if disable the product" />} />
                                    <AppFormRow leftHeader={"Date Created"} rightSrc={<AppInput disabled={true} type="text" placeholder="Today" />} />
                                    <AppFormRow leftHeader={"Last Updated"} rightSrc={<AppInput disabled={true} type="text" placeholder="Today" />} />
                                </tbody>
                            </table>
                            <NotificationContainer />
                            <CommonModal
                                titleImg={<AiOutlineCheckCircle color="#3E868E" size={50} className="page-common-modal__header-logo" />}
                                isOpen={commonModal}
                                rightBtnToggle={<AppLinkButton label="Add Variant" buttonType="linkBtn" linkTo={`/inventory/variants/addNewVariant/${productId}`} buttonColor="primary" />}
                                leftBtnToggle={() => {
                                    setCommonModal(false);
                                    setTimeout(() => {
                                        history.goBack();
                                    }, 800);
                                }}
                                leftBtnLabel={"Cancel"}
                                rightBtnLabel={"Add Variant"}
                                title={"New Product Added"}
                                content={"New product has been added successfully"}
                            />
                            <PageAddProductCategory isOpen={addNewCategoryDisplay} toggle={addBtnToggle} btnAction={btnAddNewCategory} />
                        </AppEditForm>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default PageAddProduct;

import React from "react";

const getCheckMarkClassNames = ({ error, type }) => {
    const classNames = ["app-checkbox__checkmark"];

    if (error) classNames.push("app-checkbox__checkmark--error");

    if (type === "table-checkbox") classNames.push("app-checkbox__table-checkbox");

    if (type === "square-checkbox") classNames.push("app-checkbox__square-checkbox");

    return classNames.join(" ");
};

const AppCheckbox = ({ error, checked, onChange, type, label, rest }) => {
    return (
        <div className="app-checkbox">
            <label className="app-checkbox__container">
                <input type="checkbox" checked={checked} onChange={onChange} {...rest} />
                <span className={getCheckMarkClassNames({ error, type })}></span>
            </label>
            {label}
        </div>
    );
};

export default AppCheckbox;

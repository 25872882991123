// libraries
import React, { useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

//api
import api from "services/api";

//routes
import pathnames from "routes/pathnames";

// components
import AppSearchBar from "components/app-search-bar";
import AppTableColumnHeader from "components/app-table-column-header";
import AppTableFooter from "components/app-table-footer";
import AppSuccessMessage from "components/app-success-message";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTablePage from "components/app-table-page.jsx";

// assets
import iconEdit from "assets/images/icon-edit.svg";

//redux
import AppLinkButton from "components/app-link-button";
import { updateUserList } from "redux/slices/user-list-slice";
import { updateApiError } from "redux/slices/api-error-slice";

const PageUserList = () => {
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState("");
    const [sortSelected, setSortSelected] = useState("");
    const [searchError, setSearchError] = useState();
    const userList = useSelector((state) => state.userList);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [sortHeader, setSortHeader] = useState();
    const [sortAsc, setSortAsc] = useState(true);
    const [paginationInfo, setPaginationInfo] = useState({
        page: 1,
        size: 20,
        numberOfElements: 16,
        totalElements: 10,
        totalPages: 1,
    });

    const columns = [
        {
            id: "no",
            Header: () => <AppTableColumnHeader title="No" />,
            Cell: (row) => {
                return <div>{row.row.index + 1}</div>;
            },
            width: 50,
            minWidth: 50,
            maxWidth: 50,
        },
        {
            id: "staffName",
            columnsListLabel: "Staff Name",
            Header: () => <AppTableColumnHeader title="Staff Name" />,
            accessor: (row) => <div className="page-product-list-table--line-clamp-2">{row.userDisplayName}</div>,
            width: 100,
            minWidth: 100,
            maxWidth: 100,
            disableSortBy: true,
        },
        {
            id: "emailAddress",
            columnsListLabel: "Email Address",
            Header: (header) => <AppTableColumnHeader title="Email Address" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => <div className="page-product-list-table--line-clamp-2">{row.email}</div>,
            minWidth: 105,
            width: 150,
            maxWidth: 250,
            disableSortBy: true,
        },
        {
            id: "userRolesGroup",
            columnsListLabel: "User Group",
            Header: (header) => <AppTableColumnHeader title="User Group" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => <div className="page-product-list-table--line-clamp-2">{row.userRolesGroup}</div>,
            width: 90,
            minWidth: 90,
            maxWidth: 90,
            sorting: true,
            sortType: (a, b) => {
                if (a.values["userRolesGroup"].props.children < b.values["userRolesGroup"].props.children) {
                    return -1;
                } else if (a.values["userRolesGroup"].props.children > b.values["userRolesGroup"].props.children) {
                    return 1;
                }
                return 0;
            },
            onClick: () => {
                setSortHeader("userRolesGroup");
                if (sortHeader == "userRolesGroup") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "userRolesGroup" && sortAsc == "",
            sortDecs: sortHeader == "userRolesGroup" && sortAsc == "-",
        },
        {
            id: "userStatus",
            columnsListLabel: "Status",
            Header: (header) => <AppTableColumnHeader title="Status" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => {
                if (row.userStatus == "ACTIVE") {
                    return <span className="page-product-list-table__status--active">Active</span>;
                } else {
                    return <span className="page-product-list-table__status--disabled">Disabled</span>;
                }
            },
            width: 70,
            minWidth: 70,
            maxWidth: 70,
            sorting: true,
            sortType: (a, b) => {
                if (a.values["userStatus"].props.children < b.values["userStatus"].props.children) {
                    return -1;
                } else if (a.values["userStatus"].props.children > b.values["userStatus"].props.children) {
                    return 1;
                }
                return 0;
            },
            onClick: () => {
                setSortHeader("userStatus");
                if (sortHeader == "userStatus") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "userStatus" && sortAsc == "",
            sortDecs: sortHeader == "userStatus" && sortAsc == "-",
        },

        {
            id: "lastLogin",
            columnsListLabel: "Last Login",
            Header: (header) => <AppTableColumnHeader title="Last Login" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: "createDate",
            width: 135,
            minWidth: 135,
            maxWidth: 135,
            sorting: true,
            onClick: () => {
                setSortHeader("lastLogin");
                if (sortHeader == "lastLogin") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "lastLogin" && sortAsc == "",
            sortDecs: sortHeader == "lastLogin" && sortAsc == "-",
        },
        {
            id: "buttons",
            Header: () => <AppTableColumnHeader title="Action" style={{ justifyContent: "center" }} />,
            Cell: (row) => {
                return <AppLinkButton buttonColor="primary" buttonType="link" linkTo={`/user/viewOtherProfile/${row.row.original.userId}`} label={<img src={iconEdit} alt="icon edit" />} selectedData={row.row.original} />;
            },
            width: 50,
            minWidth: 50,
            maxWidth: 50,
        },
    ];

    const breadcrumbArray = [
        { title: "Settings", onClick: null },
        { title: "User List", onClick: null },
    ];

    const loadData = async () => {
        try {
            let req = {
                page: paginationInfo.page,
                pageSize: paginationInfo.size,
            };
            if (!_.isEmpty(searchQuery)) {
                req.searchQuery = searchQuery;
            }
            if (!_.isEmpty(sortHeader)) {
                req.sort = sortHeader;
            }
            let response = await api.get.getUserList(req);
            setPaginationInfo({
                ...paginationInfo,
                numberOfElements: response.data["atfc-data"]?.numberOfElements,
                totalElements: response.data["atfc-data"]?.totalElements,
                totalPages: response.data["atfc-data"]?.totalPages,
            });
            dispatch(updateUserList(response));
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    };

    const searchSuccess = (e) => {
        setSearchError();
        setPaginationInfo({ ...paginationInfo, page: 1 });
        setSearchQuery(e.target.value);
    };

    const searchEnterBtn = (e) => {
        if (e.target.value && e.target.value.length > 0 && e.target.value.length < 3) {
            setSearchError("Cannot be lesser than 3 characters");
        } else {
            searchSuccess(e);
        }
    };

    return (
        <AppTablePage
            popUpMessage={<AppSuccessMessage show={showSuccessMessage} message="Stock amount has been edited." />}
            breadcrumb={<AppBreadcrumb breadcrumbArray={breadcrumbArray} />}
            pageTitle="User List"
            searchBar={<AppSearchBar placeholder="Search by staff name" onKeyEnter={searchEnterBtn} searchError={searchError} />}
            showResult="Showing all 2000 results"
            tableFunctions={[<AppLinkButton label="Add New Staff" buttonType="linkBtn" linkTo={pathnames.pageAddUser} buttonColor="primary" leftSrc={<AiFillPlusCircle size={16} />} />]}
            tableColumns={columns}
            totalElements={paginationInfo.totalElements}
            tableData={userList.content ? userList.content : []}
            tablePageFooter={<AppTableFooter paginationInfo={paginationInfo} sortHeader={sortHeader} searchQuery={searchQuery} loadData={loadData} setPaginationInfo={(paginationInfo) => setPaginationInfo(paginationInfo)} />}
        />
    );
};

export default PageUserList;

// libraries
import React, { useState, useEffect } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { BiExport } from "react-icons/bi";
import { Link } from "react-router-dom";
import AppLinkButton from "components/app-link-button";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import _ from "lodash";

//api
import api from "services/api";

//routes
import pathnames from "routes/pathnames";

// components
import AppSearchBar from "components/app-search-bar";
import AppTableColumnHeader from "components/app-table-column-header";
import AppRowSelect from "components/app-row-select";
import AppTableFooter from "components/app-table-footer";
import AppSuccessMessage from "components/app-success-message";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTablePage from "components/app-table-page.jsx";
import AppButton from "components/app-button";

// assets
import iconEdit from "assets/images/icon-edit.svg";

//redux
import { updateSupplierList } from "redux/slices/supplier-slice";
import { updateApiError } from "redux/slices/api-error-slice";

const PageRelationshipSuppliers = () => {
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState("");
    const [sortSelected, setSortSelected] = useState("");
    const [searchError, setSearchError] = useState();
    const supplierList = useSelector((state) => state.supplierList);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [sortHeader, setSortHeader] = useState();
    const [sortAsc, setSortAsc] = useState("");
    const [paginationInfo, setPaginationInfo] = useState({
        page: 1,
        size: 20,
        numberOfElements: 16,
        totalElements: 10,
        totalPages: 1,
    });

    const columns = [
        {
            id: "no",
            Header: () => <AppTableColumnHeader title="No" />,
            Cell: (row) => {
                return <div>{row.row.index + 1}</div>;
            },
            width: 50,
            minWidth: 50,
            maxWidth: 50,
        },
        {
            id: "supplierName",
            columnsListLabel: "Company Name",
            Header: () => <AppTableColumnHeader title="Company Name" />,
            accessor: "supplierName",
            width: 120,
            minWidth: 120,
            maxWidth: 120,
            sorting: true,
            sortType: "string",
            onClick: () => {
                setSortHeader("supplierName");
                if (sortHeader == "supplierName") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "supplierName" && sortAsc == "",
            sortDecs: sortHeader == "supplierName" && sortAsc == "-",
        },
        {
            id: "personInCharge",
            columnsListLabel: "Person In Charge",
            Header: (header) => <AppTableColumnHeader title="Person In Charge" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => <div className="page-product-list-table--line-clamp-2">{row.personInCharge}</div>,
            minWidth: 105,
            width: 150,
            maxWidth: 250,
            sorting: true,
            sortType: (a, b) => {
                if (a.values["personInCharge"].props.children < b.values["personInCharge"].props.children) {
                    return -1;
                } else if (a.values["personInCharge"].props.children > b.values["personInCharge"].props.children) {
                    return 1;
                }
                return 0;
            },
            onClick: () => {
                setSortHeader("personInCharge");
                if (sortHeader == "personInCharge") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "personInCharge" && sortAsc == "",
            sortDecs: sortHeader == "personInCharge" && sortAsc == "-",
        },
        {
            id: "supplierEmail",
            columnsListLabel: "Email Address",
            Header: (header) => <AppTableColumnHeader title="Email Address" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => <div className="page-product-list-table--line-clamp-2">{row.supplierEmail}</div>,
            width: 90,
            minWidth: 90,
            maxWidth: 90,
            disableSortBy: true,
        },
        {
            id: "supplierContact",
            columnsListLabel: "Contact No",
            Header: (header) => <AppTableColumnHeader title="Contact No" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => <div className="page-product-list-table--line-clamp-2">{row.supplierContact}</div>,
            width: 105,
            minWidth: 105,
            maxWidth: 105,
            disableSortBy: true,
        },
        {
            id: "supplierStatus",
            columnsListLabel: "Status",
            Header: (header) => <AppTableColumnHeader title="Status" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => {
                if (row.supplierStatus == "ACTIVE") {
                    return <span className="page-product-list-table__status--active">Active</span>;
                } else {
                    return <span className="page-product-list-table__status--disabled">Disabled</span>;
                }
            },
            width: 90,
            minWidth: 90,
            maxWidth: 90,
            sorting: true,
            sortType: (a, b) => {
                if (a.values["supplierStatus"].props.children < b.values["supplierStatus"].props.children) {
                    return -1;
                } else if (a.values["supplierStatus"].props.children > b.values["supplierStatus"].props.children) {
                    return 1;
                }

                return 0;
            },
            onClick: () => {
                setSortHeader("supplierStatus");
                if (sortHeader == "supplierStatus") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "supplierStatus" && sortAsc == "",
            sortDecs: sortHeader == "supplierStatus" && sortAsc == "-",
        },

        {
            id: "modifyDate",
            columnsListLabel: "Last Update",
            Header: (header) => <AppTableColumnHeader title="Last Update" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: "modifyDate",
            width: 105,
            minWidth: 105,
            maxWidth: 105,
            sorting: true,
            onClick: () => {
                setSortHeader("modifyDate");
                if (sortHeader == "modifyDate") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "modifyDate" && sortAsc == "",
            sortDecs: sortHeader == "modifyDate" && sortAsc == "-",
        },
        {
            id: "buttons",
            Header: () => <AppTableColumnHeader title="Action" style={{ justifyContent: "center" }} />,
            Cell: (row) => {
                return <AppLinkButton buttonColor="primary" buttonType="link" linkTo={`/relationships/suppliers/edit/${row.row.original.supplierId}`} label={<img src={iconEdit} alt="icon edit" />} selectedData={row.row.original} />;
            },
            width: 50,
            minWidth: 50,
            maxWidth: 50,
        },
    ];

    const headers = [
        { label: "Id", key: "supplierId" },
        { label: "Supplier Name", key: "supplierName" },
        { label: "Business No", key: "supplierBusinessNo" },
        { label: "Contact", key: "supplierContact" },
        { label: "Email", key: "supplierEmail" },
        { label: "Status", key: "supplierStatus" },
        { label: "Person In Charge", key: "personInCharge" },
        { label: "Address 1", key: "supplierAddress.address1" },
        { label: "Address 2", key: "supplierAddress.address2" },
        { label: "Postcode", key: "supplierAddress.postcode" },
        { label: "City", key: "supplierAddress.city" },
        { label: "State", key: "supplierAddress.state" },
        { label: "Country", key: "supplierAddress.country" },
        { label: "Product Item Count", key: "productItemCount" },
        { label: "Create Date", key: "createDate" },
        { label: "Modify Date", key: "modifyDate" },
        { label: "Create By", key: "createBy" },
        { label: "Modify By", key: "modifyBy" },
    ];

    const loadData = async () => {
        try {
            let req = {
                page: paginationInfo.page,
                pageSize: paginationInfo.size,
            };
            if (!_.isEmpty(searchQuery)) {
                req.searchQuery = searchQuery;
            }
            if (!_.isEmpty(sortHeader)) {
                req.sort = `${sortAsc}${sortHeader}`;
            }
            let response = await api.get.getSuppliersList(req);
            setPaginationInfo({
                ...paginationInfo,
                numberOfElements: response.data["atfc-data"]?.numberOfElements,
                totalElements: response.data["atfc-data"]?.totalElements,
                totalPages: response.data["atfc-data"]?.totalPages,
            });
            dispatch(updateSupplierList(response));
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    };

    const searchSuccess = (e) => {
        setSearchError();
        setPaginationInfo({ ...paginationInfo, page: 1 });
        setSearchQuery(e.target.value);
    };

    const searchEnterBtn = (e) => {
        if (e.target.value && e.target.value.length > 0 && e.target.value.length < 3) {
            setSearchError("Cannot be lesser than 3 characters");
        } else {
            searchSuccess(e);
        }
    };

    return (
        <AppTablePage
            popUpMessage={<AppSuccessMessage show={showSuccessMessage} message="Stock amount has been edited." />}
            pageTitle="Suppliers"
            searchBar={<AppSearchBar placeholder="Search by company name, person in charge, email, contact no" onKeyEnter={searchEnterBtn} searchError={searchError} />}
            showResult="Showing all 2500 results"
            tableFunctions={[
                <AppLinkButton label="Add New Suppliers" buttonType="linkBtn" linkTo={pathnames.pageAddSupplier} buttonColor="primary" leftSrc={<AiFillPlusCircle size={16} />} />,
                <AppButton label="" buttonType="transparent" colorType="primary" leftSrc={<BiExport size={20} />}>
                    <CSVLink style={{ color: "#3e868e" }} headers={headers} filename={"wms_supplier_list.csv"} data={supplierList.content ? supplierList.content : []}>
                        Export All
                    </CSVLink>
                </AppButton>,
            ]}
            totalElements={paginationInfo.totalElements}
            tableColumns={columns}
            tableData={supplierList.content ? supplierList.content : []}
            tablePageFooter={<AppTableFooter sortAsc={sortAsc} sortHeader={sortHeader} searchQuery={searchQuery} loadData={loadData} paginationInfo={paginationInfo} setPaginationInfo={(paginationInfo) => setPaginationInfo(paginationInfo)} />}
        />
    );
};

export default PageRelationshipSuppliers;

import React, { useState } from "react";

const getButtonClassNames = ({ labelSelected, item }) => {
    const classNames = ["app-row-select__button"];
    if (labelSelected === item) classNames.push("app-row-select__selecting");
    return classNames.join(" ");
};

const AppRowSelect = ({ list, selected, selectedIndex }) => {
    const [labelSelected, setLabelSelected] = useState(list[selectedIndex]);

    const labelSelectedFunction = (item) => {
        setLabelSelected(item);
        selected(item);
    };

    return (
        <div className="app-row-select">
            {list.map((item, index) => {
                return (
                    <button key={index} type="button" onClick={() => labelSelectedFunction(item)} className={getButtonClassNames({ labelSelected, item })}>
                        {item.label}
                    </button>
                );
            })}
        </div>
    );
};

export default AppRowSelect;

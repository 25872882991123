import React, { memo } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import routes from "routes/routes";
import iconBell from "assets/images/components/app-nav/icon-bell.svg";
import iconLogout from "assets/images/components/app-nav/icon-logout.svg";
import AppLinkButton from "./app-link-button";
import pathnames from "routes/pathnames";

const AppNav = memo(({ logoutToggle }) => {
    const location = useLocation();
    const route = routes.filter((route) => route.path === location.pathname)[0];
    const userProfile = useSelector((state) => state.userProfile);
    const profileInfo = {
        username: userProfile?.userDisplayName,
        userImage: "https://4.img-dpreview.com/files/p/E~TS590x0~articles/3925134721/0266554465.jpeg",
    };

    const buttonNotification = () => {};

    return (
        <nav className="nav-bar">
            <div className="nav-bar__title">{route?.navBarTitle || ""}</div>
            <div className="nav-bar__contents">
                <div className="nav-bar__icon-wrapper">
                    <img className="nav-bar__profile-picture-img" src={profileInfo.userImage} alt="" />
                    <span className="nav-bar--green-dot"></span>
                </div>
                <div className="nav-bar__username">
                    <AppLinkButton label={profileInfo.username} buttonType="link" linkTo={pathnames.pageUserProfile} />
                </div>
                <span className="nav-bar__divider"></span>
                <div className="nav-bar__icon-wrapper">
                    <img onClick={buttonNotification} className="nav-bar__icon" src={iconBell} alt="" />
                    <span className="nav-bar--red-dot"></span>
                </div>
                <img onClick={logoutToggle} className="nav-bar__icon" src={iconLogout} alt="" />
            </div>
        </nav>
    );
});

export default AppNav;

// libraries
import React, { useState, useEffect } from "react";
import { NotificationContainer, NotificationManager } from "react-notifications";
import * as Yup from "yup";
import { Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import api from "services/api";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import pathnames from "routes/pathnames";
import InputMask from "react-input-mask";

// components
import AppInput from "components/app-input";
import AppItemDropdown from "components/app-item-dropdown";
import AppEditForm from "components/app-edit-form";
import AppFormRow from "components/app-form-row";
import AppButton from "components/app-button";
import AppRowSelect from "components/app-row-select";

//asset
import logo from "../../assets/images/pages/user/newUser.png";
import { updateUserProfile } from "redux/slices/user-profile-slice";
import { updateApiError } from "redux/slices/api-error-slice";
import createNotification from "services/helper";

const tableTypeList = [
    { label: "My Profile", value: 0 },
    { label: "Change Password", value: 1 },
];

const PageUserProfile = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userProfile = useSelector((state) => state.userProfile);
    const [apiCall, setApiCall] = useState(false);
    const [userRolesGroup, setUserRolesGroup] = useState(userProfile?.userRolesGroup);
    const [selectedSection, setSelectedSection] = useState(tableTypeList[0].value);
    const selectList = [
        { id: 1, value: "Owner", label: "Owner" },
        { id: 2, value: "Staff", label: "Staff" },
    ];

    const phoneFormatting = (dasherizedStr) => {
        let countryCode = dasherizedStr.substr(0, 2);
        if (!_.isEmpty(dasherizedStr) && countryCode == "+6") {
            let newValue = dasherizedStr.slice(2);
            return newValue.replace(/[_-]/g, " ").replace(/ /g, "");
        } else return dasherizedStr;
    };

    const tableTypeSelected = (item) => {
        setSelectedSection(item.value);
    };

    const btnEditUserProfile = async (values) => {
        try {
            let payload = {
                userDisplayName: values.userDisplayName,
                userExtendedDetails: {
                    profileInfo: {
                        contact: phoneFormatting(values.contact),
                    },
                    appPreferences: {},
                },
            };
            const response = await api.post.editProfile(payload);
            if (response.data["atfc-message"] == "SUCCESS") {
                await createNotification("success", "Profile edited successfully.").then(() => {
                    setTimeout(() => {
                        history.goBack();
                    }, 800);
                });
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    const loadData = async () => {
        try {
            let response = await api.get.getUserProfile();
            dispatch(updateUserProfile(response));
            setApiCall(true);
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    };

    const formatDate = (value) => {
        return moment(value).format("DD/MM/YYYY");
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (selectedSection == 1) {
            history.push(pathnames.pageChangePassword);
        }
    }, [selectedSection]);

    // const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
    return (
        apiCall && (
            <Formik
                initialValues={{ ...userProfile, contact: userProfile?.userExtendedDetails?.profileInfo?.contact }}
                validationSchema={Yup.object({
                    userDisplayName: Yup.string().required("Required"),
                    // contact: Yup.string().matches(phoneRegExp, "Invalid contact number"),
                })}
                onSubmit={(values, actions) => {
                    btnEditUserProfile(values);
                }}
                validator={() => ({})}>
                {({ values, errors, setFieldValue, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <AppEditForm
                            pageSwitch={<AppRowSelect list={tableTypeList} selected={tableTypeSelected} selectedIndex={selectedSection} />}
                            pageTitle={"My Profile"}
                            submitCancelButton={
                                <>
                                    <AppButton type="button" colorType="white" onClick={() => history.goBack()} label={"Cancel"} regularBtn={true} />
                                    <AppButton type="submit" colorType="primary" label={"Update"} regularBtn={true} />
                                </>
                            }>
                            <div className="row-wrapper center" style={{ marginBottom: 10 }}>
                                <div className="margin-right-10">
                                    <img width={70} height={70} src={logo} alt="" />
                                </div>
                                <div style={{ fontSize: 26, fontWeight: 700, marginLeft: 20 }}>{userProfile?.userDisplayName}</div>
                            </div>
                            <table>
                                <tbody>
                                    <AppFormRow leftHeader={"Name"} rightSrc={<AppInput error={errors.userDisplayName} name="userDisplayName" type="text" value={values.userDisplayName} onChange={handleChange} placeholder="Name" />} />
                                    <AppFormRow leftHeader={"Email Address"} rightSrc={<AppInput disabled={true} name="email" type="text" value={values.email} />} />
                                    {/* <AppFormRow leftHeader={"Contact No (optional)"} rightSrc={<AppInput name="contact" error={errors.contact} type="text" value={values.userExtendedDetails?.profileInfo?.contact} onChange={handleChange} placeholder="+60 12 3456 789" />} /> */}
                                    <AppFormRow
                                        leftHeader={"Contact No (optional)"}
                                        rightSrc={
                                            <div className="row-wrapper">
                                                <div className="app-input">
                                                    <div className="app-input-text-leftLabel-wrapper">
                                                        <InputMask
                                                            className="app-input-text__long"
                                                            style={{ height: 40, border: "none", paddingLeft: 10 }}
                                                            mask="+6999-99999999"
                                                            name="contact"
                                                            value={values.contact}
                                                            onChange={(e) => {
                                                                const value = e.target.value || "";
                                                                setFieldValue("contact", value);
                                                            }}
                                                        />
                                                        {errors.contact && <div className="error-msg-wrapper">{errors.contact}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    />
                                </tbody>
                            </table>
                            <NotificationContainer />
                        </AppEditForm>
                    </form>
                )}
            </Formik>
        )
    );
};

export default PageUserProfile;

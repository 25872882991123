// libraries
import React, { useState, useEffect } from "react";
import Scrollbars from "rc-scrollbars";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import pathnames from "routes/pathnames";
// components
import AppNav from "components/app-nav";
import AppSideNav from "components/app-side-nav";
// pages
import PageLogout from "pages/logout";

//api
import api from "services/api";

//redux
import { updateUserProfile } from "redux/slices/user-profile-slice";
import { loadProgressBar } from "axios-progress-bar";
import PageSessionExpired from "pages/sessionExpired";
import { updateApiError } from "redux/slices/api-error-slice";

loadProgressBar();

const AppLayout = ({ children }) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const apiError = useSelector((state) => state.apiError);
    const { error } = apiError;
    const [showLogout, setShowLogout] = useState(false);
    const [showSessionExpired, setShowSessionExpired] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const logoutToggle = () => {
        setShowLogout(!showLogout);
    };

    const RESET_ACTION = {
        type: "logout",
    };

    const logout = () => {
        logoutToggle();
        localStorage.clear();
        dispatch(RESET_ACTION);
        history.push(pathnames.pageLogin);
    };

    const expired = () => {
        localStorage.clear();
        dispatch(RESET_ACTION);
        history.push(pathnames.pageLogin);
        setShowSessionExpired(false);
    };

    useEffect(() => {
        if (error == "Token Expired") {
            setShowSessionExpired(true);
        }
    }, [error]);

    useEffect(() => {
        const authenticated = localStorage.getItem("@storage_token");
        if (authenticated) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [location]);

    const loadUserProfile = async () => {
        try {
            let response = await api.get.getUserProfile();
            dispatch(updateUserProfile(response));
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            loadUserProfile();
        }
    }, [isAuthenticated]);

    const getScrollBarsClassName = (isAuthenticated) => {
        const classNames = ["rc-scrollbars"];
        if (isAuthenticated) classNames.push("rc-scrollbars__with-navbar");
        return classNames.join(" ");
    };

    return (
        <div className="root-page app-layout">
            {isAuthenticated && <AppSideNav />}
            <main className="root-main">
                {isAuthenticated && <AppNav logoutToggle={logoutToggle} />}

                <Scrollbars id="rc-scroll" className={getScrollBarsClassName(isAuthenticated)}>
                    {children}
                </Scrollbars>
            </main>
            <PageLogout isOpen={showLogout} toggle={logoutToggle} logout={logout} />
            <PageSessionExpired isOpen={showSessionExpired} toggle={expired} />
        </div>
    );
};

export default AppLayout;

import React from "react";
import iconRight from "assets/images/icon-arrow-right.svg";

const getBreadcrumbTitleClassNames = (onClick) => {
    const classNames = ["app-breadcrumb__title"];

    if (onClick) return (classNames.push = ["app-breadcrumb__title--clicked"]);

    return classNames.join(" ");
};

const AppBreadcrumb = ({ breadcrumbArray }) => {
    return (
        <div className="app-breadcrumb">
            {breadcrumbArray.map((item, index) => (
                <div key={index}>
                    <span onClick={item.onClick} className={getBreadcrumbTitleClassNames(item.onClick)}>
                        {item.title}
                    </span>
                    {index <= breadcrumbArray.length - 2 ? <img alt="" className="app-breadcrumb__icon-right" src={iconRight} /> : null}
                </div>
            ))}
        </div>
    );
};

export default AppBreadcrumb;

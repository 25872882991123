// libraries
import React, { useState, useEffect } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { BiExport } from "react-icons/bi";
import { FiGrid } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import _ from "lodash";

//api
import api from "services/api";

//routes
import pathnames from "routes/pathnames";

// components
import AppSearchBar from "components/app-search-bar";
import AppTableColumnHeader from "components/app-table-column-header";
import AppRowSelect from "components/app-row-select";
import AppTableFooter from "components/app-table-footer";
import AppSuccessMessage from "components/app-success-message";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTablePage from "components/app-table-page.jsx";
import AppButton from "components/app-button";

// assets
import iconEdit from "assets/images/icon-edit.svg";

//redux
import { updateInventoryList } from "redux/slices/inventory-slice";
import AppLinkButton from "components/app-link-button";
import { updateApiError } from "redux/slices/api-error-slice";

const tableTypeList = [
    { label: "All", value: "ALL" },
    { label: "Active", value: "ACTIVE" },
    { label: "Disabled", value: "DISABLED" },
];

const PageInventoryProductList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [searchQuery, setSearchQuery] = useState("");
    const [sortSelected, setSortSelected] = useState("");
    const [searchError, setSearchError] = useState();
    const inventoryList = useSelector((state) => state.inventoryList);
    const [statusFilter, setStatusFilter] = useState("ALL");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [sortHeader, setSortHeader] = useState();
    const [sortAsc, setSortAsc] = useState("");
    const [paginationInfo, setPaginationInfo] = useState({
        page: 1,
        size: 20,
        numberOfElements: 16,
        totalElements: 10,
        totalPages: 1,
    });

    const tableTypeSelected = (value) => {
        setStatusFilter(value.value);
    };

    const headers = [
        { label: "Product Code", key: "productCode" },
        { label: "Product Id", key: "productId" },
        { label: "Product Name", key: "productName" },
        { label: "Product Description", key: "productDescription" },
        { label: "Product Status", key: "productStatus" },
        { label: "Status Remark", key: "productStatusRemark" },
        { label: "Category Id", key: "categoryId" },
        { label: "Create Date", key: "createDate" },
        { label: "Modify Date", key: "modifyDate" },
        { label: "Create By", key: "createBy" },
        { label: "Modify By", key: "modifyBy" },
        { label: "Category Code", key: "categoryCode" },
        { label: "Category Name", key: "categoryName" },
        { label: "In Stock Quantity", key: "inStockQuantity" },
        { label: "Allocated Quantity", key: "allocatedQuantity" },
        { label: "Product Item Count", key: "productItemCount" },
    ];

    const columns = [
        {
            id: "no",
            Header: () => <AppTableColumnHeader title="No" />,
            Cell: (row) => {
                return <div>{row.row.index + 1}</div>;
            },
            width: 50,
            minWidth: 50,
            maxWidth: 50,
        },
        {
            id: "product Id",
            columnsListLabel: "Product ID",
            Header: () => <AppTableColumnHeader title="Product ID" />,
            accessor: "productCode",
            width: 85,
            minWidth: 85,
            maxWidth: 100,
            disableSortBy: true,
        },
        {
            id: "productName",
            columnsListLabel: "Product Name",
            Header: (header) => <AppTableColumnHeader title="Product Name" />,
            accessor: "productName",
            sorting: true,
            sortType: "string",
            onClick: () => {
                setSortHeader("productName");
                if (sortHeader == "productName") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "productName" && sortAsc == "",
            sortDecs: sortHeader == "productName" && sortAsc == "-",
        },
        {
            id: "categoryName",
            columnsListLabel: "Category",
            Header: (header) => <AppTableColumnHeader title="Category" />,
            accessor: "categoryName",
            sorting: true,
            sortType: "string",
            onClick: () => {
                setSortHeader("categoryName");
                if (sortHeader == "categoryName") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "categoryName" && sortAsc == "",
            sortDecs: sortHeader == "categoryName" && sortAsc == "-",
        },
        {
            id: "productStatus",
            columnsListLabel: "Status",
            Header: (header) => <AppTableColumnHeader title="Status" />,
            accessor: (row) => {
                if (row.productStatus == "ACTIVE") {
                    return <span className="page-product-list-table__status--active">Active</span>;
                } else {
                    return <span className="page-product-list-table__status--disabled">Disabled</span>;
                }
            },
            sortType: (a, b) => {
                if (a.values["productStatus"].props.children < b.values["productStatus"].props.children) {
                    return -1;
                } else if (a.values["productStatus"].props.children > b.values["productStatus"].props.children) {
                    return 1;
                }

                return 0;
            },
            width: 80,
            minWidth: 80,
            sorting: true,
            onClick: () => {
                setSortHeader("productStatus");
                if (sortHeader == "productStatus") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "productStatus" && sortAsc == "",
            sortDecs: sortHeader == "productStatus" && sortAsc == "-",
        },

        {
            id: "createDate",
            columnsListLabel: "Date Created",
            Header: (header) => <AppTableColumnHeader title="Date Created" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: "createDate",
            width: 135,
            minWidth: 135,
            maxWidth: 135,
            sorting: true,
            onClick: () => {
                setSortHeader("createDate");
                if (sortHeader == "createDate") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "createDate" && sortAsc == "",
            sortDecs: sortHeader == "createDate" && sortAsc == "-",
        },
        {
            id: "modifyDate",
            columnsListLabel: "Last Updated",
            Header: (header) => <AppTableColumnHeader title="Last Updated" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: "modifyDate",
            width: 135,
            minWidth: 135,
            maxWidth: 135,
            sorting: true,
            onClick: () => {
                setSortHeader("modifyDate");
                if (sortHeader == "modifyDate") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "modifyDate" && sortAsc == "",
            sortDecs: sortHeader == "modifyDate" && sortAsc == "-",
        },
        {
            id: "buttons",
            Header: () => <AppTableColumnHeader title="Action" style={{ justifyContent: "center" }} />,
            Cell: (row) => {
                return <AppButton buttonType="transparent" onClick={() => history.push(`/inventory/editProduct/${row.row.original.productId}`, { selectedData: row.row.original })} label={<img src={iconEdit} alt="icon edit" />} />;
            },
            width: 50,
            minWidth: 50,
            maxWidth: 50,
        },
    ];

    const breadcrumbArray = [
        {
            title: "Inventory",
        },
        {
            title: "Products",
        },
    ];

    const loadData = async () => {
        try {
            let req = {
                page: paginationInfo.page,
                pageSize: paginationInfo.size,
                sort: "categoryName",
                statusFilter,
            };
            if (!_.isEmpty(searchQuery)) {
                req.searchQuery = searchQuery;
            }
            if (!_.isEmpty(sortHeader)) {
                req.sort = `${sortAsc}${sortHeader}`;
            }
            let response = await api.get.getInventoryList(req);
            setPaginationInfo({
                ...paginationInfo,
                numberOfElements: response.data["atfc-data"]?.numberOfElements,
                totalElements: response.data["atfc-data"]?.totalElements,
                totalPages: response.data["atfc-data"]?.totalPages,
            });
            dispatch(updateInventoryList(response));
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    };

    const searchSuccess = (e) => {
        setSearchError();
        setPaginationInfo({ ...paginationInfo, page: 1 });
        setSearchQuery(e.target.value);
    };

    const searchEnterBtn = (e) => {
        if (!_.isEmpty(e.target.value) && e.target.value.length > 0 && e.target.value.length < 3) {
            setSearchError("Cannot be lesser than 3 characters");
        } else {
            searchSuccess(e);
        }
    };

    return (
        <>
            <AppTablePage
                popUpMessage={<AppSuccessMessage show={showSuccessMessage} message="Stock amount has been edited." />}
                breadcrumb={<AppBreadcrumb breadcrumbArray={breadcrumbArray} />}
                pageTitle="Products"
                tableTypeSelect={<AppRowSelect list={tableTypeList} selected={tableTypeSelected} />}
                searchBar={<AppSearchBar placeholder="Search by product ID, product name" onKeyEnter={searchEnterBtn} searchError={searchError} />}
                showResult="Showing all 2000 results"
                tableFunctions={[
                    <AppLinkButton label="Product Category" buttonType="linkBtn" linkTo={pathnames.pageProductCategory} buttonColor="primary" leftSrc={<FiGrid size={20} />} />,
                    <AppLinkButton label="Add New Product" buttonType="linkBtn" linkTo={pathnames.pageAddProduct} buttonColor="primary" leftSrc={<AiFillPlusCircle size={16} />} />,
                    <AppButton label="" buttonType="transparent" colorType="primary" leftSrc={<BiExport size={20} />}>
                        <CSVLink style={{ color: "#3e868e" }} filename={"wms_product_list.csv"} data={inventoryList.content ? inventoryList.content : []} headers={headers}>
                            Export All
                        </CSVLink>
                    </AppButton>,
                ]}
                totalElements={paginationInfo.totalElements}
                tableColumns={columns}
                tableData={inventoryList?.content ? inventoryList?.content : []}
                tablePageFooter={<AppTableFooter sortAsc={sortAsc} sortHeader={sortHeader} paginationInfo={paginationInfo} statusFilter={statusFilter} searchQuery={searchQuery} loadData={loadData} setPaginationInfo={(paginationInfo) => setPaginationInfo(paginationInfo)} />}
            />
        </>
    );
};

export default PageInventoryProductList;

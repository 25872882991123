// libraries
import React, { useState, useEffect } from "react";
import { BiExport } from "react-icons/bi";
import { AiOutlineBarcode } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import _ from "lodash";

//redux
import { useDispatch, useSelector } from "react-redux";
import { updateVariantList } from "redux/slices/variant-slice";

// components
import AppSearchBar from "components/app-search-bar";
import AppTableColumnHeader from "components/app-table-column-header";
import AppRowSelect from "components/app-row-select";
import AppTableFooter from "components/app-table-footer";
import AppSuccessMessage from "components/app-success-message";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTablePage from "components/app-table-page.jsx";
import AppLinkButton from "components/app-link-button";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppItemDropdown from "components/app-item-dropdown";

// assets
import iconEdit from "assets/images/icon-edit.svg";
import { ReactComponent as IconPrintCyan } from "assets/images/pages/inventory/icon-print-cyan.svg";
import iconBookshelf from "assets/images/icon-bookshelf.svg";

//api
import pathnames from "routes/pathnames";
import api from "services/api";
import { updateApiError } from "redux/slices/api-error-slice";

const tableTypeList = [
    { label: "All", value: "ALL" },
    { label: "Active", value: "ACTIVE" },
    // { label: "Available Stock", value: "AVAILABLE" },
    // { label: "Low Stock", value: "LOW" },
    // { label: "Out of Stock", value: "OUT" },
    { label: "Disabled", value: "DISABLED" },
];

const dropdownItems = [
    {
        id: 1,
        label: "Print Barcode",
        leftSrc: <IconPrintCyan />,
    },
    {
        id: 2,
        label: "Create Stock Count",
        leftSrc: <AiOutlineBarcode size={18} color={"#3e868e"} />,
    },
];

const PageInventoryVariants = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const variantList = useSelector((state) => state.variantList);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchError, setSearchError] = useState();
    const [sortSelected, setSortSelected] = useState("");
    const [statusFilter, setStatusFilter] = useState("ALL");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [sortHeader, setSortHeader] = useState();
    const [sortAsc, setSortAsc] = useState("");
    const [paginationInfo, setPaginationInfo] = useState({
        page: 1,
        size: 20,
        numberOfElements: 20,
        totalElements: 100,
        totalPages: 1,
    });
    const [selectedProductItemId, setSelectedProductItemId] = useState();
    const [barcodeModalShow, setBarcodeModalShow] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [selectedRow, setSelectedRow] = useState([]);

    const tableTypeSelected = (value) => {
        setStatusFilter(value.value);
    };

    const headers = [
        { label: "Product Item Id", key: "productItemId" },
        { label: "Product Item Code", key: "productItemCode" },
        { label: "Product Item Variant", key: "productItemVariant" },
        { label: "Origin Country", key: "productItemAttrs.basicAttrs.originCountry" },
        { label: "Color", key: "productItemAttrs.extendedAttrs.color" },
        { label: "Dimension Width", key: "productItemAttrs.extendedAttrs.dimensionWidth" },
        { label: "Dimension Length", key: "productItemAttrs.extendedAttrs.dimensionLength" },
        { label: "Dimension Height", key: "productItemAttrs.extendedAttrs.dimensionHeight" },
        { label: "Dimension Unit", key: "productItemAttrs.extendedAttrs.dimensionUnit" },
        { label: "Weight", key: "productItemAttrs.extendedAttrs.Weight" },
        { label: "Weight Unit", key: "productItemAttrs.extendedAttrs.weightUnit" },
        { label: "Product Item Status", key: "productItemStatus" },
        { label: "Supplier Id", key: "supplierId" },
        { label: "Supplier Name", key: "supplierName" },
        { label: "Product Id", key: "productId" },
        { label: "Product Name", key: "productName" },
        { label: "In Stock Quantity", key: "inStockQuantity" },
        { label: "Allocated Quantity", key: "allocatedQuantity" },
        { label: "Create Date", key: "createDate" },
        { label: "Modify Date", key: "modifyDate" },
        { label: "Create By", key: "createBy" },
        { label: "Modify By", key: "modifyBy" },
    ];

    const showModalContent = (type, values) => {
        if (type === "showBarcode") {
            setBarcodeModalShow(!barcodeModalShow);
            setModalContent(<img src={values} className="page-variants__barcode-image" alt="" />);
        }
    };

    const rowCheck = (selectedValue) => {
        if (selectedRow.includes(selectedValue)) {
            setSelectedRow([...selectedRow.filter((item) => item !== selectedValue)]);
        } else {
            setSelectedRow([...selectedRow, selectedValue]);
        }
    };

    useEffect(() => {
        const selectedProductItemIds = selectedRow.map((item) => item.productItemId).join(",");
        setSelectedProductItemId(selectedProductItemIds);
    }, [selectedRow]);

    const columns = [
        {
            id: "selection",
            Cell: ({ row }) => <AppCheckbox checked={selectedRow.includes(row.original)} onChange={() => rowCheck(row.original)} type="table-checkbox" />,
            // sticky: "left",
            width: 50,
            minWidth: 50,
            maxWidth: 50,
        },
        {
            id: "no",
            Header: () => <AppTableColumnHeader title="No" />,
            Cell: (row) => {
                return <div>{row.row.index + 1}</div>;
            },
            width: 50,
            minWidth: 50,
            maxWidth: 50,
            // sticky: "left",
        },
        {
            id: "productItemCode",
            columnsListLabel: "SKU",
            Header: () => <AppTableColumnHeader title="SKU" />,
            accessor: "productItemCode",
            width: 120,
            minWidth: 120,
            maxWidth: 120,
            // sticky: "left",
            disableSortBy: true,
        },
        {
            id: "productName",
            columnsListLabel: "Product Name",
            Header: (header) => <AppTableColumnHeader title="Product Name" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => <div className="page-variants--line-clamp-2">{row.productName}</div>,
            minWidth: 200,
            width: 200,
            maxWidth: 200,
            // sticky: "left",
            sorting: true,
            onClick: () => {
                setSortHeader("productName");
                if (sortHeader == "productName") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "productName" && sortAsc == "",
            sortDecs: sortHeader == "productName" && sortAsc == "-",
        },
        {
            id: "productItemVariant",
            columnsListLabel: "Variant",
            Header: () => <AppTableColumnHeader title="Variant" />,
            accessor: "productItemVariant",
            minWidth: 170,
            width: 170,
            maxWidth: 170,
            // sticky: "left",
            disableSortBy: true,
        },
        {
            id: "supplierName",
            columnsListLabel: "Supplier",
            Header: (header) => <AppTableColumnHeader title="Supplier" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: "supplierName",
            width: 150,
            minWidth: 150,
            maxWidth: 150,
            sorting: true,
            onClick: () => {
                setSortHeader("supplierName");
                if (sortHeader == "supplierName") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "supplierName" && sortAsc == "",
            sortDecs: sortHeader == "supplierName" && sortAsc == "-",
        },
        {
            id: "inStockQuantity",
            columnsListLabel: "Stock On Hand",
            Header: (header) => <AppTableColumnHeader title="Stock On Hand" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: "inStockQuantity",
            width: 110,
            minWidth: 110,
            maxWidth: 110,
            sorting: true,
            onClick: () => {
                setSortHeader("inStockQuantity");
                if (sortHeader == "inStockQuantity") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "inStockQuantity" && sortAsc == "",
            sortDecs: sortHeader == "inStockQuantity" && sortAsc == "-",
        },
        {
            id: "allocatedQuantity",
            columnsListLabel: "Allocated Stock",
            Header: (header) => <AppTableColumnHeader title="Allocated Stock" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: "allocatedQuantity",
            width: 110,
            minWidth: 110,
            maxWidth: 110,
            sorting: true,
            onClick: () => {
                setSortHeader("allocatedQuantity");
                if (sortHeader == "allocatedQuantity") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "allocatedQuantity" && sortAsc == "",
            sortDecs: sortHeader == "allocatedQuantity" && sortAsc == "-",
        },
        {
            id: "Available Stock",
            columnsListLabel: "Available Stock",
            Header: (header) => <AppTableColumnHeader title="Available Stock" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: "inStockQuantity",
            width: 110,
            minWidth: 110,
            maxWidth: 110,
            sorting: true,
            onClick: () => {
                setSortHeader("inStockQuantity");
                if (sortHeader == "inStockQuantity") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "inStockQuantity" && sortAsc == "",
            sortDecs: sortHeader == "inStockQuantity" && sortAsc == "-",
        },
        {
            id: "barcode",
            columnsListLabel: "Barcode",
            Header: () => <AppTableColumnHeader title="Barcode" />,
            accessor: (row) => <AppLinkButton buttonType="button" fontSize="small" label="View" buttonColor="primary" onClick={() => showModalContent("showBarcode", row.barcode)} />,
            width: 105,
            minWidth: 105,
            maxWidth: 105,
            initialHideColumn: true,
        },
        {
            id: "category",
            columnsListLabel: "Category",
            Header: (header) => <AppTableColumnHeader title="Category" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: "category",
            width: 105,
            minWidth: 105,
            maxWidth: 105,
            initialHideColumn: true,
            sorting: true,
        },
        {
            id: "productItemStatus",
            columnsListLabel: "Status",
            Header: (header) => <AppTableColumnHeader title="Status" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => {
                if (row.productItemStatus == "ACTIVE") {
                    return <span className="page-product-list-table__status--active">Active</span>;
                } else {
                    return <span className="page-product-list-table__status--disabled">Disabled</span>;
                }
            },
            width: 105,
            minWidth: 105,
            maxWidth: 105,
            initialHideColumn: true,
            sorting: true,
            onClick: () => {
                setSortHeader("productItemStatus");
                if (sortHeader == "productItemStatus") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "productItemStatus" && sortAsc == "",
            sortDecs: sortHeader == "productItemStatus" && sortAsc == "-",
        },
        {
            id: "createDate",
            columnsListLabel: "Date Created",
            Header: (header) => <AppTableColumnHeader title="Date Created" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: "createDate",
            width: 105,
            minWidth: 105,
            maxWidth: 105,
            initialHideColumn: true,
            sorting: true,
            onClick: () => {
                setSortHeader("createDate");
                if (sortHeader == "createDate") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "createDate" && sortAsc == "",
            sortDecs: sortHeader == "createDate" && sortAsc == "-",
        },
        {
            id: "modifyDate",
            columnsListLabel: "Last Updated",
            Header: (header) => <AppTableColumnHeader title="Last Updated" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: "modifyDate",
            width: 105,
            minWidth: 105,
            maxWidth: 105,
            initialHideColumn: true,
            sorting: true,
            onClick: () => {
                setSortHeader("modifyDate");
                if (sortHeader == "modifyDate") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "modifyDate" && sortAsc == "",
            sortDecs: sortHeader == "modifyDate" && sortAsc == "-",
        },
        {
            id: "buttons",
            Header: () => <AppTableColumnHeader title="Action" style={{ justifyContent: "center" }} />,
            Cell: (row) => {
                return (
                    <div className="row-wrapper">
                        <AppButton buttonType="transparent" onClick={() => history.push(`/inventory/variants/manageStock/${row.row.original.productItemId}`, { selectedData: row.row.original, selectedProductItemId: selectedProductItemId })} label={<img src={iconBookshelf} alt="icon edit" />} />
                        <AppButton buttonType="transparent" onClick={() => history.push(`/inventory/variants/edit/${row.row.original.productItemId}`, { selectedData: row.row.original, selectedProductItemId: selectedProductItemId })} label={<img src={iconEdit} alt="icon edit" />} />
                    </div>
                );
            },
            width: 80,
            minWidth: 80,
            maxWidth: 80,
            // sticky: "right",
        },
    ];

    const breadcrumbArray = [
        { title: "Inventory", onClick: null },
        { title: "Variants", onClick: null },
    ];

    const actionDropdownItemClicked = (value) => {
        history.push("/inventory/variants/createStockCount", { selectedRow: selectedRow });
    };

    const loadData = async () => {
        try {
            let req = {
                page: paginationInfo.page,
                pageSize: paginationInfo.size,
            };
            if (!_.isEmpty(searchQuery)) {
                req.searchQuery = searchQuery;
            }
            if (!_.isEmpty(statusFilter)) {
                req.statusFilter = statusFilter;
            }
            if (!_.isEmpty(sortHeader)) {
                req.sort = `${sortAsc}${sortHeader}`;
            }
            let response = await api.get.getVariantList(req);
            setPaginationInfo({
                ...paginationInfo,
                numberOfElements: response.data["atfc-data"]?.numberOfElements,
                totalElements: response.data["atfc-data"]?.totalElements,
                totalPages: response.data["atfc-data"]?.totalPages,
            });
            dispatch(updateVariantList(response));
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    };

    const searchSuccess = (e) => {
        setSearchError();
        setPaginationInfo({ ...paginationInfo, page: 1 });
        setSearchQuery(e.target.value);
    };

    const searchEnterBtn = (e) => {
        if (e.target.value && e.target.value.length > 0 && e.target.value.length < 3) {
            setSearchError("Cannot be lesser than 3 characters");
        } else {
            searchSuccess(e);
        }
    };

    return (
        <AppTablePage
            popUpMessage={<AppSuccessMessage show={showSuccessMessage} message="Stock amount has been edited." />}
            breadcrumb={<AppBreadcrumb breadcrumbArray={breadcrumbArray} />}
            pageTitle="Variants"
            tableTypeSelect={<AppRowSelect list={tableTypeList} selected={tableTypeSelected} />}
            searchBar={<AppSearchBar placeholder="Search by SKU, product name, supplier" onKeyEnter={searchEnterBtn} searchError={searchError} />}
            showResult="Showing all 2000 results"
            tableFunctions={[
                <AppButton buttonType="transparent" label="" colorType="primary" leftSrc={<BiExport size={20} />}>
                    <CSVLink style={{ color: "#3e868e" }} headers={headers} filename={"wms_variant_list.csv"} data={variantList.content ? variantList.content : []}>
                        Export All
                    </CSVLink>
                </AppButton>,
                <AppItemDropdown labelKey="label" valueKey="label" dropdownLabel="Action" labelColorChange={true} setSelectedItem={actionDropdownItemClicked} dropdownItems={dropdownItems} caret={true} dropdownItemDisable={!selectedRow.length} />,
            ]}
            useHideColumn={true}
            tableColumns={columns}
            tableData={variantList.content ? variantList.content : []}
            modalIsOpen={barcodeModalShow}
            modalOnRequestClose={() => setBarcodeModalShow(!barcodeModalShow)}
            modalContent={modalContent}
            totalElements={paginationInfo.totalElements}
            tablePageFooter={<AppTableFooter sortAsc={sortAsc} sortHeader={sortHeader} searchQuery={searchQuery} statusFilter={statusFilter} loadData={loadData} paginationInfo={paginationInfo} setPaginationInfo={(paginationInfo) => setPaginationInfo(paginationInfo)} />}
        />
    );
};

export default PageInventoryVariants;

// libraries
import React, { Fragment, useEffect, useState } from "react";

// assets
import logo from "../assets/images/pages/warehouse/editWarehouseLogo.png";
import grayCircle from "../assets/images/pages/warehouse/circle_gray.png";
import brownCircle from "../assets/images/pages/warehouse/circle_brown.png";

const AppViewStockPosition = ({ searchInput, selectShelf, setSelectShelf, selectedData, mainTitle, pageTitleRightSrc, leftSrcImg, breadcrumb, pageTitle, tableTypeSelect, searchBar, setShelfLocation, getRackInfo }) => {
    const { colCount, rackCountPerShelf, rowCount, shelfCountPerCol, warehouseSectionMapPath2, ...other } = selectedData;
    const getClassName = (value) => {
        const classNames = [""];
        if (leftSrcImg === true) classNames.push(value + "-withLogo");
        else classNames.push(value);
        return classNames.join(" ");
    };
    // const [selectedShelf, setSelectedShelf] = useState();
    const row = [...Array(rowCount).keys()];
    const col = [...Array(colCount).keys()];
    const shelfCol = [...Array(shelfCountPerCol).keys()];

    useEffect(() => {
        row.map((i) =>
            col.map((j) =>
                shelfCol.map((k) => {
                    if (searchInput == warehouseSectionMapPath2?.[i]?.[j]?.[k]) {
                        setSelectShelf(warehouseSectionMapPath2?.[i]?.[j]?.[k]);
                        setShelfLocation(k + 1);
                        getRackInfo(warehouseSectionMapPath2?.[i]?.[j]?.[k]);
                    }
                })
            )
        );
    }, [searchInput]);

    return (
        <Fragment>
            <div className="app-view-stock-position">
                <div className="app-view-stock-position__wrapper">
                    {breadcrumb}
                    <span className={"app-view-stock-position__mainTitle"}>{mainTitle}</span>
                    <div className="app-view-stock-position__title">
                        {leftSrcImg && <img width={70} height={70} src={logo} alt="" style={{ marginRight: 10 }} />}
                        <span className={getClassName("app-view-stock-position__title__label")}>{pageTitle}</span>
                        <span className="app-view-stock-position__title-label-rightSrc">{pageTitleRightSrc}</span>
                        <div className="app-view-stock-position__searchbar-wrapper">{searchBar}</div>
                    </div>
                    <table className="app-view-stock-position__table">
                        <tbody>
                            <tr>
                                <td>{tableTypeSelect && <div className="app-view-stock-position__row-selection-list">{tableTypeSelect}</div>}</td>
                            </tr>
                            <div className="app-view-stock-position__logoWrapper">
                                <img width={10} height={10} src={brownCircle} alt="" />
                                &nbsp; Loaded
                            </div>
                            <div className="app-view-stock-position__logoWrapper">
                                <img width={10} height={10} src={grayCircle} alt="" />
                                &nbsp; Empty
                            </div>
                        </tbody>
                    </table>
                    <div style={{ overflowX: "auto" }}>
                        <div
                            style={{
                                borderTop: "0.5px solid #d9d9d9",
                                paddingTop: 20,
                                marginTop: 20,
                                display: "flex",
                                flexDirection: "column",
                                width: shelfCountPerCol > 2 && colCount > 2 && shelfCountPerCol * colCount * 150,
                            }}>
                            {row.map((i) => {
                                return (
                                    <div className="row" style={{ overflowX: "auto" }}>
                                        {col.map((j) => {
                                            return (
                                                <>
                                                    <div class="col" style={{ display: "flex", flexDirection: "column" }}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                fontSize: 12,
                                                                overflowX: "auto",
                                                                flex: 1,
                                                                textAlign: "center",
                                                            }}>
                                                            {i == 0 && <p>Column {j + 1}</p>}
                                                        </div>
                                                        <div className="col" style={{ display: "flex" }}>
                                                            {shelfCol.map((k) => {
                                                                return (
                                                                    <>
                                                                        {j == 0 && k == 0 && (
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    fontSize: 12,
                                                                                    width: 60,
                                                                                }}>
                                                                                Row {i + 1}
                                                                            </div>
                                                                        )}

                                                                        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                                                            {i == 0 && (
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        flex: 0.5,
                                                                                        justifyContent: "center",
                                                                                        marginTop: 3,
                                                                                        marginBottom: 3,
                                                                                        textAlign: "center",
                                                                                        borderTop: "0.5px solid gray",
                                                                                        fontSize: 12,
                                                                                    }}>
                                                                                    Shelf {k + 1}
                                                                                </div>
                                                                            )}
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                }}>
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => {
                                                                                        setSelectShelf(warehouseSectionMapPath2?.[i]?.[j]?.[k]);
                                                                                        setShelfLocation(k + 1);
                                                                                        getRackInfo(warehouseSectionMapPath2?.[i]?.[j]?.[k]);
                                                                                        // setSelectedShelf(warehouseSectionMapPath2?.[i]?.[j]?.[k]);
                                                                                    }}
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        backgroundColor: "#eaeaea",
                                                                                        flex: 1,
                                                                                        textAlign: "center",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        paddingTop: 20,
                                                                                        paddingBottom: 20,
                                                                                        border: selectShelf == warehouseSectionMapPath2?.[i]?.[j]?.[k] ? "1px solid #3e868e" : "0.1px solid white",
                                                                                        fontSize: 12,
                                                                                    }}>
                                                                                    {warehouseSectionMapPath2?.[i]?.[j]?.[k]}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AppViewStockPosition;

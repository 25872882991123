import React from "react";
import { IoMdCheckmark } from "react-icons/io";

const AppSuccessMessage = ({ message, show }) => {
    return (
        show && (
            <div className="app-success-message">
                <IoMdCheckmark size={20} /> {message}
            </div>
        )
    );
};

export default AppSuccessMessage;

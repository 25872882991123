import React, { useRef } from "react";
import AppButton from "./app-button";
import { AiFillPlusCircle } from "react-icons/ai";
import logo from "../assets/images/empty-image.png";
import api from "services/api";
import { useDispatch } from "react-redux";
import { NotificationContainer } from "react-notifications";
import { updateSelectedVariantDetails } from "redux/slices/selected-variant-details";
import { updateApiError } from "redux/slices/api-error-slice";
import createNotification from "services/helper";
import _ from "lodash";

const UploadAndDisplayImage = (props) => {
    const dispatch = useDispatch();
    const { productItemId, productItemMediaAsset } = props;
    const hiddenFileInput = useRef(null);

    const handleImageUpload = (e) => {
        const tempArr = [];
        if (e) {
            [...e.target.files].forEach((file) => {
                tempArr.push({
                    data: file,
                    url: URL.createObjectURL(file),
                });
            });
            uploadImage(tempArr);
        }
    };

    const loadData = async () => {
        try {
            let details = await api.get.getVariantDetails(productItemId);
            dispatch(updateSelectedVariantDetails(details));
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    };

    const removePic = async (removeImg) => {
        productItemMediaAsset.filter((i) => i !== removeImg);
        let filename = removeImg.split("/").pop();
        let payload = {
            productItemId: productItemId,
            productItemMediaAsset: filename,
        };

        try {
            let response = await api.post.removeMedia(payload);
            if (response.data["atfc-message"] == "SUCCESS") {
                createNotification("success", "Image removed successfully.");
                loadData();
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const uploadImage = async (uploadImg) => {
        const formData = new FormData();
        uploadImg.map((item, index) => {
            return formData.append("files", item?.data, item?.data?.name);
        });
        try {
            let response = await api.post.addMedia(productItemId, formData, "media");
            if (response.data["atfc-message"] == "SUCCESS") {
                createNotification("success", "Image upload successfully.");
                loadData();
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    return (
        <div>
            {productItemMediaAsset && (
                <div className="app-upload-image">
                    {productItemMediaAsset.length > 0 &&
                        productItemMediaAsset?.map((pic, index) => (
                            <div className="image">
                                <>
                                    <img className="imageItem" key={index} width={"200px"} height={"200px"} alt="not found" src={pic} />
                                    <div className="delete">
                                        <AppButton buttonType="transparent" colorType="closePic" label="X" onClick={() => removePic(pic)} />
                                    </div>
                                </>
                            </div>
                        ))}
                    <div className="emptyImage" onClick={handleClick}>
                        <img src={logo} alt="" />
                    </div>
                    <br />
                </div>
            )}
            <br />
            <AppButton label="Upload Image" onClick={handleClick} buttonType="transparent" colorType="primary" leftSrc={<AiFillPlusCircle size={16} />} />
            <input ref={hiddenFileInput} type="file" onChange={handleImageUpload} multiple accept="image/*" style={{ display: "none" }}></input>
            <NotificationContainer />
        </div>
    );
};

export default UploadAndDisplayImage;

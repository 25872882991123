// libraries
import React, { useState, useEffect } from "react";
import { NotificationContainer } from "react-notifications";
import { BsTrash } from "react-icons/bs";
import pathnames from "routes/pathnames";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { updateApiError } from "redux/slices/api-error-slice";
import _ from "lodash";
// components
import AppBreadcrumb from "components/app-breadcrumb";
import AppInput from "components/app-input";
import AppItemDropdown from "components/app-item-dropdown";
import AppEditForm from "components/app-edit-form";
import AppFormRow from "components/app-form-row";
import AppButton from "components/app-button";
import api from "services/api";
import CommonModal from "components/app-common-modal";
import AppLinkButton from "components/app-link-button";
import createNotification from "services/helper";

const PageEditWarehouse = (props) => {
    const {
        createBy,
        createDate,
        modifyBy,
        modifyDate,
        warehouseAddress: { address1, address2, city, country, postcode, state },
        warehouseCode,
        warehouseDescription,
        // warehouseId,
        warehouseName,
        warehouseSectionCount,
        warehouseStatus,
    } = props.location.state.selectedData;
    const history = useHistory();
    const dispatch = useDispatch();
    const { warehouseId } = useParams();
    const [warehouse, setWarehouse] = useState(warehouseName);
    const [localWarehouseCode, setLocalWarehouseCode] = useState(warehouseCode);
    const [localWarehouseDescription, setLocalWarehouseDescription] = useState(warehouseDescription);
    const [addressState, setAddressState] = useState(address1);
    const [addressState2, setAddressState2] = useState(address2);
    const [status, setStatus] = useState(warehouseStatus);
    const [localCity, setLocalCity] = useState(city);
    const [localCountry, setLocalCountry] = useState(country);
    const [localPostcode, setLocalPostcode] = useState(postcode);
    const [localState, setLocalState] = useState(state);
    const [section, setSection] = useState();
    const [rows, setRows] = useState();
    const [columns, setColumns] = useState();
    const [shelves, setShelves] = useState();
    const [racks, setRacks] = useState();
    const [commonModal, setCommonModal] = useState(false);

    const formatDate = (value) => {
        return moment(value).format("DD/MM/YYYY");
    };
    const selectList = [
        { id: 1, value: "Johor", label: "Johor" },
        { id: 2, value: "Kedah", label: "Kedah" },
        { id: 3, value: "Kelantan", label: "Kelantan" },
        { id: 4, value: "Kuala Lumpur", label: "Kuala Lumpur" },
        { id: 5, value: "Malacca", label: "Malacca" },
        { id: 6, value: "Negeri Sembilan", label: "Negeri Sembilan" },
        { id: 7, value: "Pahang", label: "Pahang" },
        { id: 8, value: "Penang", label: "Penang" },
        { id: 9, value: "Perak", label: "Perak" },
        { id: 10, value: "Perlis", label: "Perlis" },
        { id: 11, value: "Sabah", label: "Sabah" },
        { id: 12, value: "Sarawak", label: "Sarawak" },
        { id: 13, value: "Selangor", label: "Selangor" },
        { id: 14, value: "Terengganu", label: "Terengganu" },
    ];

    const selectListCountry = [
        { id: 1, value: "Malaysia", label: "Malaysia" },
        { id: 2, value: "Singapore", label: "Singapore" },
    ];

    const breadcrumbArray = [
        {
            title: "Warehouse",
            onClick: () => {
                history.push(pathnames.pageWarehouse);
            },
        },
        {
            title: "Warehouse List",
            onClick: () => {
                history.push(pathnames.pageWarehouse);
            },
        },
        { title: "Edit Warehouse", onClick: null },
    ];

    const btnEditSupplier = async () => {
        try {
            let payload = {
                warehouseId: warehouseId,
                warehouseName: warehouse,
                warehouseDescription: localWarehouseDescription,
                warehouseStatus: status,
                warehouseAddress: {
                    address1: addressState,
                    address2: addressState2,
                    postcode: localPostcode,
                    city: localCity,
                    state: localState,
                    country: localCountry,
                },
            };
            const response = await api.post.editWarehouse(payload);
            if (response.data["atfc-message"] == "SUCCESS") {
                await createNotification("success", "Warehouse info update successfully.").then(() =>
                    setTimeout(() => {
                        history.goBack();
                    }, 800)
                );
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    const loadData = async () => {
        try {
            let response = await api.get.getWarehouseDetails(warehouseId);
            setRows(response.data["atfc-data"]?.warehouseSectionList[0]?.rowCount);
            setColumns(response.data["atfc-data"]?.warehouseSectionList[0]?.colCount);
            setShelves(response.data["atfc-data"]?.warehouseSectionList[0]?.shelfCountPerCol);
            setRacks(response.data["atfc-data"]?.warehouseSectionList[0]?.rackCountPerShelf);
            setSection(response.data["atfc-data"]?.warehouseSectionList?.length);
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    };

    useEffect(() => {
        if (!_.isEmpty(warehouseId)) loadData();
    }, [warehouseId]);

    return (
        <AppEditForm
            breadcrumb={<AppBreadcrumb breadcrumbArray={breadcrumbArray} />}
            pageTitle={warehouseName}
            leftSrcImg={true}
            // pageTitleRightSrc={
            //     <div className="row-wrapper">
            //         <AppButton onClick={() => setCommonModal(true)} label="" buttonType="transparent" colorType="primary" leftSrc={<BsTrash size={16} />} />
            //     </div>
            // }
            submitCancelButton={
                <>
                    <AppButton colorType="white" onClick={() => history.goBack()} label={"Cancel"} regularBtn={true} />
                    <AppButton colorType="primary" onClick={() => btnEditSupplier()} label={"Update"} regularBtn={true} />
                </>
            }>
            <table>
                <tbody>
                    <AppFormRow leftHeader={"Warehouse Code"} rightSrc={<AppInput type="text" value={localWarehouseCode} setValue={setLocalWarehouseCode} placeholder="Enter warehouse code" />} />
                    <AppFormRow leftHeader={"Warehouse Name"} rightSrc={<AppInput type="text" value={warehouse} setValue={setWarehouse} placeholder="Warehouse name" />} />
                    <AppFormRow type="textarea" leftHeader={"Warehouse Description"} rightSrc={<AppInput type="textarea" value={localWarehouseDescription} setValue={setLocalWarehouseDescription} placeholder="Describe a little bit about your warehouse" />} />
                    <AppFormRow leftHeader={"Address Line 1"} rightSrc={<AppInput type="text" value={addressState} setValue={setAddressState} placeholder="Eg unit no, apartment, suite" />} />
                    <AppFormRow leftHeader={"Address Line 2"} rightSrc={<AppInput type="text" value={addressState2} setValue={setAddressState2} placeholder="Eg street" />} />
                    <AppFormRow leftHeader={"Postcode"} rightSrc={<AppInput type="text" value={localPostcode} setValue={setLocalPostcode} placeholder="Postcode" />} />
                    <AppFormRow leftHeader={"City"} rightSrc={<AppInput type="text" value={localCity} setValue={setLocalCity} placeholder="City" />} />
                    <AppFormRow leftHeader={"State"} rightSrc={<AppItemDropdown valueKey="value" labelKey="value" dropdownItems={selectList} labelChange={false} dropdownLabel={localState} toggleButtonClass="solid" caret={true} setSelectedItem={setLocalState} long={true} />} />
                    <AppFormRow leftHeader={"Country"} rightSrc={<AppItemDropdown valueKey="value" labelKey="value" dropdownItems={selectListCountry} labelChange={false} dropdownLabel={localCountry} toggleButtonClass="solid" caret={true} setSelectedItem={setLocalCountry} long={true} />} />
                    <AppFormRow leftHeader={"No. of Section"} rightSrc={<AppInput disabled={true} type="number-with-units" value={section} setValue={setSection} placeholder="No. of section" />} />
                    <AppFormRow leftHeader={"No. of Rows"} rightSrc={<AppInput disabled={true} type="number-with-units" value={rows} setValue={setRows} placeholder="No. of rows" />} />
                    <AppFormRow leftHeader={"No. of Columns"} rightSrc={<AppInput disabled={true} type="number-with-units" value={columns} setValue={setColumns} placeholder="No. of columns" />} />
                    <AppFormRow leftHeader={"No. of Shelves Per Column"} rightSrc={<AppInput disabled={true} type="number-with-units" value={shelves} setValue={setShelves} placeholder="No. of shelves per column" />} />
                    <AppFormRow leftHeader={"No. of Racks Per Shelf"} rightSrc={<AppInput disabled={true} type="number-with-units" value={racks} setValue={setRacks} placeholder="No. of racks per shelf" />} />

                    <AppFormRow
                        leftHeader={"Status"}
                        rightSrc={
                            <>
                                <AppInput checked={status == "ACTIVE"} label={"Active"} value={"ACTIVE"} type="radio" name="status" setValue={() => setStatus("ACTIVE")} />
                                <AppInput checked={status == "DISABLED"} label={"Disabled"} value={"DISABLED"} type="radio" name="status" setValue={() => setStatus("DISABLED")} />
                            </>
                        }
                    />
                    <AppFormRow leftHeader={"Date Created"} rightSrc={<AppInput type="text" value={formatDate(createDate)} disabled={true} placeholder="Today" />} />
                    <AppFormRow leftHeader={"Last Updated"} rightSrc={<AppInput type="text" value={formatDate(modifyDate)} disabled={true} placeholder="Today" />} />
                </tbody>
            </table>
            <NotificationContainer />
            <CommonModal titleImg={<BsTrash color="#3E868E" size={40} className="page-common-modal__header-logo" />} isOpen={commonModal} rightBtnToggle={<AppLinkButton onClick={() => console.log("click")} label={"Yes, Confirm"} />} leftBtnToggle={() => setCommonModal(false)} leftBtnLabel={"Cancel"} rightBtnLabel={"Add Variant"} title={"Remove Warehouse"} content={"Are you sure to remove this warehouse?"} />
        </AppEditForm>
    );
};

export default PageEditWarehouse;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: false,
};

export const apiErrorSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        updateApiError: (state, action) => {
            console.log("action", action);
            return {
                ...state,
                error: action.payload?.error?.response?.data["atfc-message"],
            };
        },
    },
});

export const { updateApiError } = apiErrorSlice.actions;

export default apiErrorSlice.reducer;

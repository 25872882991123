// libraries
import React, { useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import "react-notifications/lib/notifications.css";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom";
import pathnames from "routes/pathnames";

//api
import api from "services/api";

// components
import AppSearchBar from "components/app-search-bar";
import AppTableColumnHeader from "components/app-table-column-header";
import AppTableFooter from "components/app-table-footer";
import AppSuccessMessage from "components/app-success-message";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTablePage from "components/app-table-page.jsx";

// assets
import iconEdit from "assets/images/icon-edit.svg";

//redux
import { updateProductCategoryList } from "redux/slices/product-category.slice";
import PageAddProductCategory from "./addProductCategory";
import AppButton from "components/app-button";
import PageEditProductCategory from "./editProductCategory";
import { updateApiError } from "redux/slices/api-error-slice";
import createNotification from "services/helper";

const PageAddProduct = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [addNewCategoryDisplay, setAddNewCategoryDisplay] = useState(false);
    const [editCategoryDisplay, setEditCategoryDisplay] = useState(false);
    const [selectedData, setSelectedData] = useState();
    const [searchQuery, setSearchQuery] = useState("");
    const [searchError, setSearchError] = useState();
    const [sortSelected, setSortSelected] = useState("");
    const productCategoryList = useSelector((state) => state.productCategoryList);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [sortHeader, setSortHeader] = useState();
    const [sortAsc, setSortAsc] = useState("");
    const [paginationInfo, setPaginationInfo] = useState({
        page: 1,
        size: 20,
        numberOfElements: 100,
        totalElements: 10,
        totalPages: 1,
    });

    const columns = [
        {
            id: "no",
            Header: () => <AppTableColumnHeader title="No" />,
            Cell: (row) => {
                return <div>{row.row.index + 1}</div>;
            },
            width: 50,
            minWidth: 50,
            maxWidth: 50,
        },
        {
            id: "categoryCode",
            columnsListLabel: "Category Code",
            Header: () => <AppTableColumnHeader title="Category Code" />,
            accessor: "categoryCode",
            width: 120,
            minWidth: 120,
            maxWidth: 120,
            disableSortBy: true,
        },
        {
            id: "categoryName",
            columnsListLabel: "Category",
            Header: (header) => <AppTableColumnHeader title="Category" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => <div className="page-product-list-table--line-clamp-2">{row.categoryName}</div>,
            minWidth: 105,
            width: 150,
            maxWidth: 250,
            sorting: true,
            onClick: () => {
                setSortHeader("categoryName");
                if (sortHeader == "categoryName") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "categoryName" && sortAsc == "",
            sortDecs: sortHeader == "categoryName" && sortAsc == "-",
        },
        {
            id: "categoryStatus",
            columnsListLabel: "Status",
            Header: (header) => <AppTableColumnHeader title="Status" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => {
                if (row.categoryStatus == "ACTIVE") {
                    return <span className="page-product-list-table__status--active">Active</span>;
                } else {
                    return <span className="page-product-list-table__status--disabled">Disabled</span>;
                }
            },
            width: 90,
            minWidth: 90,
            maxWidth: 90,
            sorting: true,
            sortType: (a, b) => {
                if (a.values["categoryStatus"].props.children < b.values["categoryStatus"].props.children) {
                    return -1;
                } else if (a.values["categoryStatus"].props.children > b.values["categoryStatus"].props.children) {
                    return 1;
                }

                return 0;
            },
            onClick: () => {
                setSortHeader("categoryStatus");
                if (sortHeader == "categoryStatus") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "categoryStatus" && sortAsc == "",
            sortDecs: sortHeader == "categoryStatus" && sortAsc == "-",
        },
        {
            id: "createDate",
            columnsListLabel: "Date Created",
            Header: (header) => <AppTableColumnHeader title="Date Created" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: "createDate",
            width: 105,
            minWidth: 105,
            maxWidth: 105,
            sorting: true,
            onClick: () => {
                setSortHeader("createDate");
                if (sortHeader == "createDate") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "createDate" && sortAsc == "",
            sortDecs: sortHeader == "createDate" && sortAsc == "-",
        },

        {
            id: "modifyDate",
            columnsListLabel: "Last Updated",
            Header: (header) => <AppTableColumnHeader title="Last Updated" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: "modifyDate",
            width: 105,
            minWidth: 105,
            maxWidth: 105,
            sorting: true,
            onClick: () => {
                setSortHeader("modifyDate");
                if (sortHeader == "modifyDate") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "modifyDate" && sortAsc == "",
            sortDecs: sortHeader == "modifyDate" && sortAsc == "-",
        },
        {
            id: "buttons",
            Header: () => <AppTableColumnHeader title="Action" style={{ justifyContent: "center" }} />,
            Cell: (row) => {
                return <AppButton label="" onClick={() => goEditCategory(row.row.original)} buttonType="transparent" colorType="primary" leftSrc={<img src={iconEdit} />} />;
            },
            width: 50,
            minWidth: 50,
            maxWidth: 50,
        },
    ];

    const breadcrumbArray = [
        {
            title: "Inventory",
            onClick: () => {
                history.push(pathnames.pageInventoryProductList);
            },
        },
        {
            title: "Products",
            onClick: () => {
                history.push(pathnames.pageInventoryProductList);
            },
        },
        { title: "Product Category", onClick: null },
    ];

    const loadData = async () => {
        try {
            let req = {
                page: paginationInfo.page,
                pageSize: paginationInfo.size,
            };
            if (!_.isEmpty(searchQuery)) {
                req.searchQuery = searchQuery;
            }
            if (!_.isEmpty(sortHeader)) {
                req.sort = `${sortAsc}${sortHeader}`;
            }
            let response = await api.get.getProductCategoryList(req);
            setPaginationInfo({
                ...paginationInfo,
                numberOfElements: response.data["atfc-data"]?.numberOfElements,
                totalElements: response.data["atfc-data"]?.totalElements,
                totalPages: response.data["atfc-data"]?.totalPages,
            });
            dispatch(updateProductCategoryList(response));
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    };

    const addBtnToggle = () => {
        setAddNewCategoryDisplay(!addNewCategoryDisplay);
    };

    const goAddNewCategory = () => {
        addBtnToggle();
    };

    const editBtnToggle = () => {
        setEditCategoryDisplay(!editCategoryDisplay);
    };

    const goEditCategory = (value) => {
        setSelectedData(value);
        editBtnToggle();
    };

    const btnAddNewCategory = async (payload) => {
        try {
            const response = await api.post.addNewProductCategory(payload);
            console.log("response", response);
            if (response.data["atfc-message"] == "SUCCESS") {
                loadData();
                createNotification("success", "New category created successfully.").then(() => {
                    setTimeout(() => {
                        setAddNewCategoryDisplay(!addNewCategoryDisplay);
                    }, 800);
                });
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    const searchSuccess = (e) => {
        setSearchError();
        setPaginationInfo({ ...paginationInfo, page: 1 });
        setSearchQuery(e.target.value);
    };
    const searchEnterBtn = (e) => {
        if (e.target.value && e.target.value.length > 0 && e.target.value.length < 3) {
            setSearchError("Cannot be lesser than 3 characters");
        } else {
            searchSuccess(e);
        }
    };

    return (
        <>
            <AppTablePage
                popUpMessage={<AppSuccessMessage show={showSuccessMessage} message="Stock amount has been edited." />}
                breadcrumb={<AppBreadcrumb breadcrumbArray={breadcrumbArray} />}
                pageTitle="Product Category"
                searchBar={<AppSearchBar placeholder="Search by category" onKeyEnter={searchEnterBtn} searchError={searchError} />}
                showResult="Showing all 2500 results"
                tableFunctions={[<AppButton label="Add New Category" onClick={goAddNewCategory} buttonType="transparent" colorType="primary" leftSrc={<AiFillPlusCircle size={16} />} />]}
                tableColumns={columns}
                totalElements={paginationInfo.totalElements}
                tableData={productCategoryList.content ? productCategoryList.content : []}
                tablePageFooter={<AppTableFooter sortAsc={sortAsc} sortHeader={sortHeader} paginationInfo={paginationInfo} searchQuery={searchQuery} loadData={loadData} setPaginationInfo={(paginationInfo) => setPaginationInfo(paginationInfo)} />}
            />
            <PageAddProductCategory isOpen={addNewCategoryDisplay} toggle={addBtnToggle} btnAction={(value) => btnAddNewCategory(value)} />
            <PageEditProductCategory loadData={loadData} isOpen={editCategoryDisplay} toggle={editBtnToggle} btnAction={goEditCategory} selectedData={selectedData} />
            <NotificationContainer />
        </>
    );
};

export default PageAddProduct;

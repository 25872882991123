import React, { useState } from "react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

const getInputClassName = (error) => {
    const classNames = ["app-auth-input__input"];
    if (error) classNames.push("app-auth-input--error");
    return classNames.join(" ");
};

const AppAuthInput = ({ iconSrc, iconAlt, error, type = "text", ...props }) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className="app-auth-input">
            <div className="app-auth-input__container">
                <img className="app-auth-input__icon" src={iconSrc} alt={iconAlt} />
                <div className="app-auth-input__input-wrapper">
                    <input className={getInputClassName(error)} type={showPassword ? "text" : type} {...props} />
                    {type === "password" ? (
                        <div className="app-auth-input__password-eye" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <IoEyeOutline size={24} /> : <IoEyeOffOutline size={24} />}
                        </div>
                    ) : null}
                </div>
            </div>
            <p className="app-auth-input__error">{error}</p>
        </div>
    );
};

export default AppAuthInput;

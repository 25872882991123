import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AiOutlineClose } from "react-icons/ai";

const PageSessionExpired = ({ isOpen, toggle }) => {
    return (
        <Modal isOpen={isOpen} className="page-logout" contentClassName="page-logout__content">
            <ModalHeader className="header">
                <AiOutlineClose className="header__close-button" onClick={toggle} />
            </ModalHeader>
            <ModalBody className="body">
                <div className="body__title">Session expired</div>
                <div className="body__description">This session has been expired, please login again.</div>
            </ModalBody>
            <ModalFooter className="footer">
                <div className="footer__left" onClick={toggle}>
                    Ok
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default PageSessionExpired;

import React from "react";
import { IoMdArrowDropdown } from "react-icons/io";

const getButtonClassName = (selecting) => {
    const classNames = ["app-table-column-header__sort-button"];
    if (selecting) classNames.push("app-table-column-header__selecting");
    return classNames.join(" ");
};

const AppTableColumnHeader = ({ title, sortButtonClick, selecting, style }) => {
    return (
        <div className="app-table-column-header" style={style}>
            {title + " "}
        </div>
    );
};

export default AppTableColumnHeader;

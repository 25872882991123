// libraries
import React, { useState, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { IoArrowBack, IoArrowForward, IoCaretDown } from "react-icons/io5";
import { VscCircleFilled } from "react-icons/vsc";
import { useSelector } from "react-redux";

// routes
import pathnames from "routes/pathnames";
// components
import DashboardIcon from "assets/images/components/app-side-nav/dashboard.svg";
import WarehouseIcon from "assets/images/components/app-side-nav/warehouse.svg";
import InventoryIcon from "assets/images/components/app-side-nav/inventory.svg";
import FurfillmentIcon from "assets/images/components/app-side-nav/furfillment.svg";
import RelationshipsIcon from "assets/images/components/app-side-nav/relationships.svg";
import ReportingIcon from "assets/images/components/app-side-nav/reporting.svg";
import SettingsIcon from "assets/images/components/app-side-nav/settings.svg";

const getAppSideSubsListClassName = (active) => {
    const classNames = ["app-side-nav__subs-list"];
    if (active) classNames.push("app-side-nav__subs-list-active");
    return classNames.join(" ");
};

const getAppSideNavClassName = (active) => {
    const classNames = ["app-side-nav__item"];
    if (active) classNames.push("app-side-nav__item--active");
    return classNames.join(" ");
};

const getAppSideNavCaretDropdownClassName = (active) => {
    const classNames = ["app-side-nav__caret-dropdown"];
    if (active) classNames.push("app-side-nav__caret-dropdown--active");
    return classNames.join(" ");
};

const getAppSideNavSubsItemClassName = (active, selected) => {
    const classNames = ["app-side-nav__subs-item"];
    if (active) classNames.push("app-side-nav__subs-item--active");
    if (selected) classNames.push("app-side-nav__subs-item--selected");
    return classNames.join(" ");
};

const AppSideNav = () => {
    const sideNavRef = useRef();
    const sideNavItem = useRef();
    const location = useLocation();
    const history = useHistory();
    const [active, setActive] = useState(false);
    const userProfile = useSelector((state) => state.userProfile);

    const navList = [
        {
            label: "Dashboard",
            to: pathnames.pageDashboard,
            icon: DashboardIcon,
        },
        {
            label: "Warehouse",
            icon: WarehouseIcon,
            to: pathnames.pageWarehouse,
        },
        {
            label: "Inventory",
            icon: InventoryIcon,
            to: null,
            childrens: [
                {
                    label: "Products",
                    to: pathnames.pageInventoryProductList,
                },
                {
                    label: "Variants",
                    to: pathnames.pageInventoryVariants,
                },
            ],
        },
        {
            label: "Relationships",
            icon: RelationshipsIcon,
            to: null,
            childrens: [
                {
                    label: "Suppliers",
                    to: pathnames.pageRelationshipSuppliers,
                },
            ],
        },
    ];

    const settingsVisible = () => {
        let array = [];
        if (userProfile?.userRolesGroup == "OWNER" || userProfile?.userRolesGroup == "ADMIN") {
            array.push(...navList, {
                label: "Settings",
                icon: SettingsIcon,
                to: null,
                childrens: [
                    {
                        label: "Users",
                        to: pathnames.pageUserList,
                    },
                ],
            });
        } else {
            array.push(...navList);
        }
        return array;
    };

    const navListVisible = settingsVisible();

    const onHandleToggle = () => {
        setActive(!active);

        if (!active) {
            sideNavRef.current.classList.add("app-side-nav--shrink");
            sideNavRef.current.classList.remove("app-side-nav--expand");
            sideNavItem.current.classList.remove("app-side-nav__lists--expand");
            sideNavItem.current.classList.add("app-side-nav__lists--shrink");
        } else {
            sideNavRef.current.classList.remove("app-side-nav--shrink");
            sideNavRef.current.classList.add("app-side-nav--expand");
            sideNavItem.current.classList.add("app-side-nav__lists--expand");
            sideNavItem.current.classList.remove("app-side-nav__lists--shrink");
        }
    };

    const onHandleToggleSubNav = (event) => {
        const items = [...event.currentTarget.children];

        event.currentTarget.classList.toggle("app-side-nav__subs-list--active");
        items.forEach((item) => {
            if (item.className.includes("app-side-nav__subs-item")) {
                item.classList.toggle("app-side-nav__subs-item--active");
            }
        });
    };

    const onHandleToggleSubNavItem = (event, pathname) => {
        event.stopPropagation();
        history.push(pathname);
    };

    return (
        <div className="app-side-nav" ref={sideNavRef}>
            <div className="app-side-nav__action" onClick={onHandleToggle}>
                {active ? <IoArrowForward size={28} /> : <IoArrowBack size={28} />}
            </div>
            <ul className="app-side-nav__lists" ref={sideNavItem}>
                {navListVisible.map((o, i) => {
                    const key = `${i}-item`;

                    if (o.childrens) {
                        const activeChild = o.childrens.filter((child) => child.to === location.pathname).length !== 0;
                        const iconClassName = activeChild ? "app-side-nav__icon--active" : "app-side-nav__icon";

                        return (
                            <ul className={getAppSideSubsListClassName(activeChild)} key={key} onClick={onHandleToggleSubNav}>
                                <li className={getAppSideNavClassName(activeChild)}>
                                    <img className={iconClassName} src={o.icon} alt="" />
                                    <p className="app-side-nav__text">{o.label}</p>
                                    <IoCaretDown className={getAppSideNavCaretDropdownClassName(activeChild)} />
                                </li>
                                {o.childrens.map((c, j) => {
                                    const ckey = `${j}-c-item`;
                                    const selectedChild = c.to === location.pathname;

                                    return (
                                        <li className={getAppSideNavSubsItemClassName(activeChild, selectedChild)} key={ckey} onClick={(e) => onHandleToggleSubNavItem(e, c.to)}>
                                            <div className="app-side-nav__subs-item-dot">
                                                <VscCircleFilled />
                                            </div>
                                            <p className="app-side-nav__text">{c.label}</p>
                                        </li>
                                    );
                                })}
                            </ul>
                        );
                    } else {
                        const active = location.pathname === o.to;
                        const iconClassName = active ? "app-side-nav__icon--active" : "app-side-nav__icon";

                        return (
                            <Link to={o.to} key={key} className="app-side-nav__link">
                                <li className={getAppSideNavClassName(active)}>
                                    <img className={iconClassName} src={o.icon} alt="" />
                                    <p className="app-side-nav__text">{o.label}</p>
                                </li>
                            </Link>
                        );
                    }
                })}
            </ul>
        </div>
    );
};

export default AppSideNav;

// libraries
import React, { Fragment } from "react";

const DashboardBox = ({ leftSrc, rightSrc, leftLogo }) => {
    return (
        <Fragment>
            <div className="app-dashboard-box">
                <div className="app-dashboard-box__leftLogo">{leftLogo}</div>
                <div className="app-dashboard-box__leftSrc">{leftSrc}</div>
                <hr className="app-dashboard-box__line" />
                <div className="app-dashboard-box__rightSrc">{rightSrc}</div>
            </div>
        </Fragment>
    );
};

export default DashboardBox;

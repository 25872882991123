import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pageSize: null,
    pageNumber: null,
    numberOfElements: null,
    totalElements: null,
    totalPages: null,
    first: true,
    last: true,
    hasNext: false,
    hasPrevious: false,
    hasContent: true,
    content: [],
};

export const stockMovementSlice = createSlice({
    name: "stockMovement",
    initialState,
    reducers: {
        updateStockMovement: (state, action) => {
            return {
                ...state,
                ...action.payload.data["atfc-data"],
            };
        },
    },
});

export const { updateStockMovement } = stockMovementSlice.actions;

export default stockMovementSlice.reducer;

// libraries
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { VscDebugStackframeDot } from "react-icons/vsc";
// common
import CONSTANSTS from "common/constansts";
// api
import api from "services/api";
// routes
import pathnames from "routes/pathnames";
// components
import AppAuthInput from "components/pages/authentication/app-auth-input";
import AppButton from "components/app-button";
import AppAuthLayout from "components/pages/authentication/app-auth-layout";
import AppLinkButton from "components/app-link-button";
// asset
import IconMail from "assets/images/pages/authentication/icon-mail.svg";
import IconLock from "assets/images/pages/authentication/icon-lock.svg";
import IconTick from "assets/images/icon-green-tick.svg";
import IconCross from "assets/images/icon-red-cross.svg";

const steps = {
    step1: "Enter Email",
    step2: "Enter Temp-password",
    step3: "Reset Password",
    step4: "Password Success updated Message",
};

const inputs = {
    email: {
        placeholder: "Email",
        required: "Email required",
        invalid: "Invalid email",
        regex: CONSTANSTS.REGEX.email,
        responseFailed: "Please try again",
    },
    temporaryPassword: {
        placeholder: "Enter temporary password",
        required: "Temporary password required",
        responseFailed: "Please try again",
    },
    newPassword: {
        placeholder: "Enter new password",
        required: "Password required",
        passwordDoNotMatch: "Passwords do not match",
        responseFailed: "Please try again",
        regex: {
            passwordMinEightChar: CONSTANSTS.REGEX.minEightChar,
            passwordMinOneUppercase: CONSTANSTS.REGEX.atLeaseOneUppercase,
            passwordMinOneNumber: CONSTANSTS.REGEX.atLeastOneNumber,
            passwordMinOneSpecialChar: CONSTANSTS.REGEX.atLeaseOneSpecialChar,
        },
    },
    confirmPassword: {
        placeholder: "Confirm new password",
        required: "Confirm password required",
        invalid: "Password does not meet the requirements",
        passwordDoNotMatch: "Passwords do not match",
        responseFailed: "Please try again",
    },
};

const initialField = {
    email: {
        error: "",
        value: "",
    },
    temporaryPassword: {
        error: "",
        value: "",
    },
    newPassword: {
        error: "",
        value: "",
    },
    confirmPassword: {
        error: "",
        value: "",
    },
};

const defaultPasswordState = {
    passwordMinEightChar: {
        value: 0,
        style: "",
        icon: <VscDebugStackframeDot />,
    },
    passwordMinOneUppercase: {
        value: 0,
        style: "",
        icon: <VscDebugStackframeDot />,
    },
    passwordMinOneNumber: {
        value: 0,
        style: "",
        icon: <VscDebugStackframeDot />,
    },
    passwordMinOneSpecialChar: {
        value: 0,
        style: "",
        icon: <VscDebugStackframeDot />,
    },
};

const PageForgotPassword = ({ match }) => {
    const history = useHistory();
    const [currentStep, setCurrentStep] = useState(steps.step1);
    const [field, setField] = useState(initialField);
    const [appButton, setAppButton] = useState({
        step: "",
        disable: false,
        disableLabel: "",
    });

    // 0 = first time, -1 = error, 1 = success
    const [passwordRequirement, setPasswordRequirement] = useState(defaultPasswordState);

    useEffect(() => {
        const forgotPasswordByHash = async () => {
            if (match.params.forgotPasswordHash && match.params.forgotPasswordHash.length === 64 && !field.email.value && !field.temporaryPassword.value) {
                try {
                    let response = await api.get.verifyForgotPasswordByHash(match.params.forgotPasswordHash);
                    let signUpId = response.data["atfc-data"].signUpId;
                    let temporaryPassword = response.data["atfc-data"].verificationCode;
                    if (signUpId && temporaryPassword) {
                        setField({
                            ...field,
                            email: {
                                ...field.email,
                                value: signUpId,
                            },
                            temporaryPassword: {
                                ...field.temporaryPassword,
                                value: temporaryPassword,
                            },
                        });
                        setCurrentStep(steps.step3);
                    }
                } catch (error) {
                    history.push("/forgot-password");
                }
            }
        };
        forgotPasswordByHash();
    }, [field, match, history]);

    const errorMessage = (name, value) => {
        if (!value) {
            return inputs[name].required;
        } else if (inputs[name].regex && !inputs[name].regex.test(value)) {
            return inputs[name].invalid;
        } else {
            return "";
        }
    };

    const onHandleOnChange = (event) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;

        setField({
            ...field,
            [name]: {
                ...field[name],
                error: errorMessage(name, value),
                value: value.replace(CONSTANSTS.REGEX.spaces, ""),
            },
        });
    };

    const getResetPasswordRequirementClassNames = (state) => {
        if (state) {
            return "forgot-password--success";
        } else {
            return "forgot-password--error";
        }
    };

    const getResetPasswordRequirementIcon = (state) => {
        if (state) {
            return <img alt="icon-tick" src={IconTick} className="forgot-password__tick-cross-img" />;
        } else {
            return <img alt="icon-cross" src={IconCross} className="forgot-password__tick-cross-img" />;
        }
    };

    const onHandleOnChangeNewPassword = (event) => {
        const value = event.currentTarget.value;

        const passwordMinEightChar = inputs.newPassword.regex.passwordMinEightChar.test(value);
        const passwordMinOneUppercase = inputs.newPassword.regex.passwordMinOneUppercase.test(value);
        const passwordMinOneNumber = inputs.newPassword.regex.passwordMinOneNumber.test(value);
        const passwordMinOneSpecialChar = inputs.newPassword.regex.passwordMinOneSpecialChar.test(value);

        setPasswordRequirement({
            passwordMinEightChar: {
                value: passwordMinEightChar,
                style: getResetPasswordRequirementClassNames(passwordMinEightChar),
                icon: getResetPasswordRequirementIcon(passwordMinEightChar),
            },
            passwordMinOneUppercase: {
                value: passwordMinOneUppercase,
                style: getResetPasswordRequirementClassNames(passwordMinOneUppercase),
                icon: getResetPasswordRequirementIcon(passwordMinOneUppercase),
            },
            passwordMinOneNumber: {
                value: passwordMinOneNumber,
                style: getResetPasswordRequirementClassNames(passwordMinOneNumber),
                icon: getResetPasswordRequirementIcon(passwordMinOneNumber),
            },
            passwordMinOneSpecialChar: {
                value: passwordMinOneSpecialChar,
                style: getResetPasswordRequirementClassNames(passwordMinOneSpecialChar),
                icon: getResetPasswordRequirementIcon(passwordMinOneSpecialChar),
            },
        });

        const onChangeErrorMessage = (type) => {
            if (type === "newPassword" && value === "") {
                return inputs.newPassword.required;
            } else if (type === "confirmPassword" && field.confirmPassword.value === "") {
                return inputs.confirmPassword.required;
            } else if (value !== "" && field.confirmPassword.value !== value) {
                return inputs.newPassword.passwordDoNotMatch;
            } else {
                return "";
            }
        };

        setField({
            ...field,
            newPassword: {
                value: value.replace(CONSTANSTS.REGEX.spaces, ""),
                error: onChangeErrorMessage("newPassword"),
            },
            confirmPassword: {
                ...field.confirmPassword,
                error: onChangeErrorMessage("confirmPassword"),
            },
        });
    };

    const onHandleOnChangeConfirmPassword = (event) => {
        const value = event.currentTarget.value;

        const onChangeErrorMessage = (type) => {
            if (type === "confirmPassword" && value === "") {
                return inputs.confirmPassword.required;
            } else if (type === "newPassword" && field.newPassword.value === "") {
                return inputs.newPassword.required;
            } else if (field.newPassword.value !== value) {
                return inputs.confirmPassword.passwordDoNotMatch;
            } else {
                return "";
            }
        };

        setField({
            ...field,
            confirmPassword: {
                value: value.replace(CONSTANSTS.REGEX.spaces, ""),
                error: onChangeErrorMessage("confirmPassword"),
            },
            newPassword: {
                ...field.newPassword,
                error: onChangeErrorMessage("newPassword"),
            },
        });
    };

    const onHandleSubmit = async (step) => {
        switch (step) {
            case steps.step1:
                let nameStep1 = "email";
                setField({
                    ...field,
                    [nameStep1]: {
                        ...field[nameStep1],
                        error: errorMessage(nameStep1, field.email.value),
                    },
                });
                if (!errorMessage(nameStep1, field.email.value)) {
                    setAppButton({
                        step: steps.step1,
                        disable: true,
                        disableLabel: "Sending Temporary Password...",
                    });
                    try {
                        await api.post.forgotPassword(field.email.value);
                        setCurrentStep(steps.step2);
                    } catch (error) {
                        setField({
                            ...field,
                            email: {
                                ...field.email,
                                error: inputs.email.responseFailed,
                            },
                        });
                    } finally {
                        setAppButton({
                            step: steps.step1,
                            disable: false,
                            disableLabel: "Get Temporary Password",
                        });
                    }
                }
                break;
            case steps.step2:
                let nameStep2 = "temporaryPassword";
                setField({
                    ...field,
                    [nameStep2]: {
                        ...field[nameStep2],
                        error: errorMessage(nameStep2, field.temporaryPassword.value),
                    },
                });
                if (!errorMessage(nameStep2, field.temporaryPassword.value)) {
                    setAppButton({
                        step: steps.step2,
                        disable: true,
                        disableLabel: "Validating...",
                    });
                    try {
                        let payload = {
                            signInId: field.email.value,
                            verificationCode: field.temporaryPassword.value,
                        };
                        await api.post.verifyForgotPassword(payload);
                        setCurrentStep(steps.step3);
                    } catch (error) {
                        setField({
                            ...field,
                            temporaryPassword: {
                                ...field.temporaryPassword,
                                error: inputs.temporaryPassword.responseFailed,
                            },
                        });
                    } finally {
                        setAppButton({
                            step: steps.step2,
                            disable: false,
                            disableLabel: "Next",
                        });
                    }
                }
                break;
            case steps.step3:
                const passwordMinEightChar = inputs.newPassword.regex.passwordMinEightChar.test(field.confirmPassword.value);
                const passwordMinOneUppercase = inputs.newPassword.regex.passwordMinOneUppercase.test(field.confirmPassword.value);
                const passwordMinOneNumber = inputs.newPassword.regex.passwordMinOneNumber.test(field.confirmPassword.value);
                const passwordMinOneSpecialChar = inputs.newPassword.regex.passwordMinOneSpecialChar.test(field.confirmPassword.value);

                setPasswordRequirement({
                    passwordMinEightChar: {
                        value: passwordMinEightChar,
                        style: getResetPasswordRequirementClassNames(passwordMinEightChar),
                        icon: getResetPasswordRequirementIcon(passwordMinEightChar),
                    },
                    passwordMinOneUppercase: {
                        value: passwordMinOneUppercase,
                        style: getResetPasswordRequirementClassNames(passwordMinOneUppercase),
                        icon: getResetPasswordRequirementIcon(passwordMinOneUppercase),
                    },
                    passwordMinOneNumber: {
                        value: passwordMinOneNumber,
                        style: getResetPasswordRequirementClassNames(passwordMinOneNumber),
                        icon: getResetPasswordRequirementIcon(passwordMinOneNumber),
                    },
                    passwordMinOneSpecialChar: {
                        value: passwordMinOneSpecialChar,
                        style: getResetPasswordRequirementClassNames(passwordMinOneSpecialChar),
                        icon: getResetPasswordRequirementIcon(passwordMinOneSpecialChar),
                    },
                });

                const onClickErrorMessageText = (fieldType) => {
                    if (field.newPassword.value === "" && fieldType === "newPassword") {
                        return inputs.newPassword.required;
                    } else if (field.confirmPassword.value === "" && fieldType === "confirmPassword") {
                        return inputs.confirmPassword.required;
                    } else if (fieldType === "confirmPassword" && field.confirmPassword.value !== "" && field.confirmPassword.value !== field.newPassword.value) {
                        return inputs.confirmPassword.passwordDoNotMatch;
                    } else {
                        return "";
                    }
                };

                setField({
                    ...field,
                    newPassword: {
                        ...field.newPassword,
                        error: onClickErrorMessageText("newPassword"),
                    },
                    confirmPassword: {
                        ...field.confirmPassword,
                        error: onClickErrorMessageText("confirmPassword"),
                    },
                });
                if (passwordMinEightChar && passwordMinOneUppercase && passwordMinOneNumber && passwordMinOneSpecialChar) {
                    setAppButton({
                        step: steps.step3,
                        disable: true,
                        disableLabel: "Resetting Password...",
                    });
                    try {
                        let payload = {
                            signInId: field.email.value,
                            newResetPassword: field.newPassword.value,
                            verificationCode: field.temporaryPassword.value,
                        };
                        await api.post.resetPassword(payload);
                        setCurrentStep(steps.step4);
                    } catch (error) {
                        setField({
                            ...field,
                            newPassword: {
                                ...field.newPassword,
                                error: inputs.newPassword.responseFailed,
                            },
                            confirmPassword: {
                                ...field.confirmPassword,
                                error: inputs.confirmPassword.responseFailed,
                            },
                        });
                    } finally {
                        setAppButton({
                            step: steps.step3,
                            disable: true,
                            disableLabel: "Reset Password",
                        });
                    }
                }
                break;
            default:
                break;
        }
    };

    const buttonResendPassword = async () => {
        setAppButton({
            step: "resendPassword",
            disable: true,
            disableLabel: "Sending password to email...",
        });
        try {
            await api.post.forgotPassword(field.email.value);
        } catch (error) {
            console.log(error);
        } finally {
            setAppButton({
                step: "resendPassword",
                disable: false,
                disableLabel: "Resend Password",
            });
        }
    };

    const renderStepSelection = () => {
        if (currentStep === steps.step1)
            return (
                <div className="forgot-password">
                    <div className="forgot-password__title">Forgot Password</div>
                    <div className="forgot-password__description">Please enter the email address you sign up with Ogistic.</div>
                    <div className="forgot-password__input-wrapper">
                        <AppAuthInput name="email" iconAlt="mail-icon" iconSrc={IconMail} error={field.email.error} placeholder={inputs.email.placeholder} onChange={onHandleOnChange} value={field.email.value} />
                    </div>

                    <div className="forgot-password__primary-button">
                        <AppButton colorType="primary" onClick={() => onHandleSubmit(steps.step1)} disabled={currentStep === appButton.step && appButton.disable} label={currentStep === appButton.step && appButton.disable ? appButton.disableLabel : "Get Temporary Password"} />
                    </div>
                </div>
            );

        if (currentStep === steps.step2)
            return (
                <div className="forgot-password">
                    <div className="forgot-password__title">Forgot Password</div>
                    <div className="forgot-password__description">We have send you an email with temporary password.</div>
                    <div className="forgot-password__input-wrapper">
                        <AppAuthInput name="temporaryPassword" type="name" iconAlt="lock-icon" iconSrc={IconLock} error={field.temporaryPassword.error} placeholder={inputs.temporaryPassword.placeholder} onChange={onHandleOnChange} value={field.temporaryPassword.value} />
                    </div>

                    <AppLinkButton className="forgot-password__resend-url" buttonColor="primary" buttonType="button" onClick={appButton.step === "resendPassword" && appButton.disable ? null : buttonResendPassword} label={appButton.step === "resendPassword" && appButton.disable ? appButton.disableLabel : "Resend Password"} />

                    <div className="forgot-password__primary-button">
                        <AppButton colorType="primary" onClick={() => onHandleSubmit(steps.step2)} disabled={currentStep === appButton.step && appButton.disable} label={currentStep === appButton.step && appButton.disable ? appButton.disableLabel : "Next"} />
                    </div>
                </div>
            );

        if (currentStep === steps.step3)
            return (
                <div className="forgot-password">
                    <div className="forgot-password__reset-password-title">Reset Password</div>
                    <AppAuthInput key="new password" name="newPassword" type="password" iconAlt="password-icon" iconSrc={IconLock} placeholder={inputs.newPassword.placeholder} value={field.newPassword.value} error={field.newPassword.error} onChange={onHandleOnChangeNewPassword} />

                    <AppAuthInput key="confirm password" name="confirmPassword" type="password" iconAlt="password-icon" iconSrc={IconLock} placeholder={inputs.confirmPassword.placeholder} value={field.confirmPassword.value} error={field.confirmPassword.error} onChange={onHandleOnChangeConfirmPassword} />

                    <div className="forgot-password__password-requirement-wrapper">
                        <div className={passwordRequirement.passwordMinEightChar.style}>
                            {passwordRequirement.passwordMinEightChar.icon}
                            Use at least 8 characters
                        </div>
                        <div className={passwordRequirement.passwordMinOneUppercase.style}>
                            {passwordRequirement.passwordMinOneUppercase.icon}
                            Should contain at least 1 uppercase
                        </div>
                        <div className={passwordRequirement.passwordMinOneNumber.style}>
                            {passwordRequirement.passwordMinOneNumber.icon}
                            Should contain at least 1 numeric
                        </div>
                        <div className={passwordRequirement.passwordMinOneSpecialChar.style}>
                            {passwordRequirement.passwordMinOneSpecialChar.icon}
                            Should contain at least 1 special character
                        </div>
                    </div>
                    <div className="forgot-password__primary-button">
                        <AppButton colorType="primary" onClick={() => onHandleSubmit(steps.step3)} disabled={currentStep === appButton.step && appButton.disable} label={currentStep === appButton.step && appButton.disable ? appButton.disableLabel : "Reset Password"} />
                    </div>
                </div>
            );

        if (currentStep === steps.step4)
            return (
                <div className="forgot-password">
                    <div className="forgot-password__title">Password Updated</div>
                    <div className="forgot-password__description">Your password has been reset successfully. Please use your new password to log in.</div>
                    <div className="forgot-password__primary-button">
                        <AppButton colorType="primary" onClick={() => history.push(pathnames.pageLogin)} label="Log In" />
                    </div>
                </div>
            );
    };

    return (
        <AppAuthLayout>
            <div className="page-forgot-password">{renderStepSelection()}</div>
        </AppAuthLayout>
    );
};

export default PageForgotPassword;

import { NotificationManager } from "react-notifications";

const createNotification = async (type, message, title) => {
    switch (type) {
        case "info":
            NotificationManager.info(message, "", 500);
            break;
        case "success":
            NotificationManager.success(message, "", 500);
            break;
        case "warning":
            NotificationManager.warning(message, "", 500);
            break;
        case "error":
            NotificationManager.error(title, message, 1500);
            break;
    }
};

export default createNotification;

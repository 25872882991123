// libraries
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiFillPlusCircle } from "react-icons/ai";
import "react-notifications/lib/notifications.css";
import { NotificationContainer, NotificationManager } from "react-notifications";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import pathnames from "routes/pathnames";

// components
import AppBreadcrumb from "components/app-breadcrumb";
import AppInput from "components/app-input";
import AppEditForm from "components/app-edit-form";
import AppFormRow from "components/app-form-row";
import AppButton from "components/app-button";

//api
import api from "services/api";

//redux
import AppItemDropdown from "components/app-item-dropdown";
import { updateWarehouseList } from "redux/slices/warehouse-slice";
import ModalStockPosition from "./modalStockPosition";
import { updateVariantListDetails } from "redux/slices/variant-details-slices-with-stock";
import { updateApiError } from "redux/slices/api-error-slice";
import createNotification from "services/helper";

const PageManageStock = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { productItemId, categoryCode, categoryId, categoryName, createBy, createDate, inStockQuantity, modifyBy, modifyDate, productCode, productDescription, productId, allocatedQuantity, productItemCount, productName, productStatus } = props.location.state.selectedData;
    const { selectedProductItemId } = props.location.state;

    const warehouseList = useSelector((state) => state.warehouseList);
    const variantDetailsWithStock = useSelector((state) => state.variantDetailsWithStock);
    const [stockPositionDisplay, setStockPositionDisplay] = useState(false);
    const [warehouse, setWarehouse] = useState();
    const [warehouseCode, setWarehouseCode] = useState();
    const [section, setSection] = useState();
    const [sectionCode, setSectionCode] = useState();
    const [apiRes, setApiRes] = useState();
    const [apiResStatus, setApiResStatus] = useState();
    const [shelfApiRes, setShelfApiRes] = useState();
    const [selectShelf, setSelectShelf] = useState();
    const [selectRack, setSelectRack] = useState();
    const [stockAmount, setStockAmount] = useState();
    const [remarks, setRemarks] = useState([]);
    const [err, setErr] = useState([]);
    const [addValue, setAddValue] = useState([]);
    const [minusValue, setMinusValue] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState({
        page: 1,
        size: 100,
        numberOfElements: 16,
        totalElements: 160,
        totalPages: 100,
    });

    const stockPositionToggle = () => {
        setStockPositionDisplay(!stockPositionDisplay);
    };

    const breadcrumbArray = [
        {
            title: "Inventory",
            onClick: () => {
                history.push(pathnames.pageInventoryProductList);
            },
        },
        {
            title: "Variants",
            onClick: () => {
                history.push(pathnames.pageInventoryVariants);
            },
        },
        {
            title: productName,
            onClick: () => {
                history.push(pathnames.pageInventoryVariants);
            },
        },
        { title: "Manage Stock", onClick: null },
    ];

    const sectionArray = [{ name: "Section 1", code: "S1" }];
    const btnAddStockPosition = async () => {
        try {
            let payload = {
                productItemId: productItemId,
                warehouseSectionMapId: selectRack,
                initialStockCount: stockAmount,
            };
            const response = await api.post.assignNewStockPosition(payload);
            if (response.data["atfc-message"] == "SUCCESS") {
                createNotification("success", "New stock position added successfully.");
                stockPositionToggle();
                loadData();
                loadDetails();
                setStockAmount();
                setSelectShelf();
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg =
                err?.error?.response?.data["atfc-data"]["data-detail"] ||
                err?.error?.response?.data["atfc-data"].map((item) => {
                    return item;
                });
            let concatMsg = _.join(msg, " ");
            createNotification("error", concatMsg);
        }
    };

    const btnUpdateVariantStock = async (data) => {
        if (_.isEmpty(data.adjustmentRemark)) {
            err[data.index] = "Remarks is required";
            setErr([...err]);
        } else {
            try {
                let numbers = data.addValue > 0 ? data.addValue : `-${data.minusValue}`;
                let payload = {
                    stockId: data.stockId,
                    adjustmentStockCount: numbers,
                    adjustmentRemark: data.adjustmentRemark,
                };
                minusValue[data.index] = 0;
                addValue[data.index] = 0;
                setMinusValue([...minusValue]);
                setAddValue([...addValue]);
                const response = await api.post.updateVariantStock(payload);
                if (response.data["atfc-message"] == "SUCCESS") {
                    setMinusValue([]);
                    setAddValue([]);
                    createNotification("success", "Stock updated successfully.");
                    loadDetails();
                }
            } catch (error) {
                console.log("error", { error });
                let err = { error };
                let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
                createNotification("error", msg);
            }
        }
    };

    const loadData = async () => {
        try {
            let response = await api.get.getWarehouseList({ page: paginationInfo.page, pageSize: paginationInfo.size, statusFilter: "ACTIVE" });
            dispatch(updateWarehouseList(response));
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    };

    const loadDetails = async () => {
        try {
            let response = await api.get.getVariantDetailsWithStock(productItemId);
            dispatch(updateVariantListDetails(response));
        } catch (error) {
            console.log({ error });
        }
    };

    useEffect(() => {
        loadData();
        loadDetails();
    }, []);

    const getWarehouseApi = async () => {
        if (warehouseCode && sectionCode)
            try {
                const warehouseRes = await api.get.getSingleWarehouseSection({ warehouseCode: warehouseCode, section: sectionCode });
                await setApiRes(warehouseRes.data["atfc-data"]);
                await setApiResStatus(warehouseRes.data["atfc-message"]);
            } catch (error) {
                setApiResStatus("");
                console.log(error);
            }
        else return;
    };

    const getRackInfo = async (value) => {
        try {
            const selectShelfRes = await api.get.getRackInfo({ shelf: value });
            await setShelfApiRes(selectShelfRes.data["atfc-data"]);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getWarehouseApi();
    }, [warehouseCode, sectionCode]);

    const renderBody = () => {
        return (
            variantDetailsWithStock?.productItemStockDetails?.length > 0 &&
            variantDetailsWithStock?.productItemStockDetails?.map(
                (item, index) =>
                    item.warehouseCode &&
                    item.warehouseName && (
                        <>
                            <table style={{ borderTop: "1px dashed #C4C4C4", display: "flex", marginTop: 20 }}>
                                <tbody style={{ marginTop: 20 }}>
                                    <AppFormRow leftHeader={"Warehouse"} rightSrc={<AppInput type="text" disabled={true} value={item.warehouseCode + " - " + item.warehouseName} />} />
                                    <AppFormRow leftHeader={"Section"} rightSrc={<AppInput type="text" disabled={true} value={item.warehouseSectionCode} />} />
                                    <AppFormRow leftHeader={"Stock Position 1"} rightSrc={<AppInput type="text" disabled={true} value={item.warehouseSectionMapPath1} />} />
                                    <AppFormRow leftHeader={"Stock On Hand"} rightSrc={<AppInput type="text" disabled={true} value={item.inStockQuantity} />} />
                                    <AppFormRow
                                        leftHeader={"Add Stock"}
                                        rightSrc={
                                            <AppInput
                                                name="sections"
                                                type="number-with-units"
                                                value={addValue[index]}
                                                onChange={(e) => {
                                                    addValue[index] = e.target.value;
                                                    minusValue[index] = 0;
                                                    setAddValue([...addValue]);
                                                    setMinusValue([...minusValue]);
                                                }}
                                                placeholder="Enter amount"
                                            />
                                        }
                                    />
                                    <AppFormRow
                                        leftHeader={"Minus Stock"}
                                        rightSrc={
                                            <AppInput
                                                name="sections"
                                                type="number-with-units"
                                                value={minusValue[index]}
                                                onChange={(e) => {
                                                    minusValue[index] = e.target.value;
                                                    addValue[index] = 0;
                                                    setMinusValue([...minusValue]);
                                                    setAddValue([...addValue]);
                                                }}
                                                placeholder="Enter amount"
                                            />
                                        }
                                    />
                                    <AppFormRow
                                        leftHeader={"Remarks/Reason"}
                                        rightSrc={
                                            <AppInput
                                                type="text"
                                                value={remarks[index]}
                                                onChange={(e) => {
                                                    err[index] = null;
                                                    setErr([...err]);
                                                    remarks[index] = e.target.value;
                                                    setRemarks([...remarks]);
                                                }}
                                                placeholder={"Enter reason for add/minus stock"}
                                                error={err[index]}
                                            />
                                        }
                                    />
                                </tbody>
                            </table>
                            <div style={{ display: "flex", flexDirection: "row", maxWidth: 705, justifyContent: "flex-end" }}>
                                <AppButton colorType="white" onClick={() => history.goBack()} label={"Cancel"} regularBtn={true} />
                                <AppButton colorType="primary" onClick={() => btnUpdateVariantStock({ index: index, stockId: item?.stockId, adjustmentRemark: remarks[index], addValue: addValue[index], minusValue: minusValue[index] })} label={"Update"} regularBtn={true} />
                            </div>
                        </>
                    )
            )
        );
    };

    const addBtnDisabledCheck = () => {
        if (!_.isEmpty(selectRack) && !_.isEmpty(stockAmount)) {
            return false;
        } else {
            return true;
        }
    };

    useEffect(() => {
        try {
            if (!_.isEmpty(selectShelf)) {
                getRackInfo(selectShelf);
            }
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    }, [selectShelf]);

    return (
        <AppEditForm breadcrumb={<AppBreadcrumb breadcrumbArray={breadcrumbArray} />} pageTitle={productName}>
            <div className="app-edit-page__subTitle-wrapper">
                <p className="app-edit-page__subTitle-wrapper__text">Manage Stock</p>
            </div>
            <table>
                <tbody>
                    <AppFormRow
                        leftHeader={"Warehouse"}
                        rightSrc={
                            <>
                                <AppItemDropdown valueKey="warehouseCode" labelKey="warehouseName" dropdownItems={warehouseList?.content ? warehouseList.content : []} labelChange={true} toggleButtonClass="solid" caret={true} setSelectedItemValue={setWarehouseCode} setSelectedItem={setWarehouse} dropdownLabel={warehouse ? warehouse : "Select warehouse"} long={true} />
                            </>
                        }
                    />
                    <AppFormRow
                        leftHeader={"Section"}
                        rightSrc={
                            <>
                                <AppItemDropdown valueKey="code" labelKey="name" dropdownItems={sectionArray} labelChange={true} toggleButtonClass="solid" caret={true} setSelectedItem={setSection} setSelectedItemValue={setSectionCode} dropdownLabel={section ? section : "Select section"} long={true} />
                            </>
                        }
                    />
                </tbody>
            </table>
            <AppButton disabled={apiResStatus == "SUCCESS" ? false : true} onClick={() => setStockPositionDisplay(true)} label={apiResStatus == "SUCCESS" ? "Add New Position" : "Warehouse / Section Not Available"} buttonType="transparent" colorType="primary" leftSrc={<AiFillPlusCircle size={16} />} />
            <table style={{ borderTop: "1px dashed #C4C4C4", display: "flex", maxWidth: 700, marginTop: 20 }}>
                <tbody style={{ marginTop: 20 }}>
                    <AppFormRow leftHeader={"Total Stock On Hand"} rightSrc={<AppInput type="text" disabled={true} value={`${variantDetailsWithStock?.productItemStockSummary?.totalInStockQuantity} unit`} />} />
                    <AppFormRow leftHeader={"Total Allocated Stock"} rightSrc={<AppInput type="text" disabled={true} value={`${variantDetailsWithStock?.productItemStockSummary?.totalAllocatedQuantity} unit`} />} />
                    <AppFormRow leftHeader={"Total Available Stock"} rightSrc={<AppInput type="text" disabled={true} value={`${variantDetailsWithStock?.productItemStockSummary?.totalAvailableQuantity} unit`} />} />
                </tbody>
            </table>
            {renderBody()}

            <NotificationContainer />
            <ModalStockPosition
                addBtnDisabledCheck={addBtnDisabledCheck}
                getRackInfo={(value) => getRackInfo(value)}
                setStockAmount={(value) => setStockAmount(value)}
                stockAmount={stockAmount}
                selectedData={apiRes}
                rackInfo={shelfApiRes}
                setSelectRack={(value) => setSelectRack(value)}
                selectShelf={selectShelf}
                setSelectShelf={(value) => setSelectShelf(value)}
                warehouseName={warehouse}
                isOpen={stockPositionDisplay}
                toggle={stockPositionToggle}
                btnAction={btnAddStockPosition}
            />
        </AppEditForm>
    );
};

export default PageManageStock;

// libraries
import React, { Fragment } from "react";

// assets
import AppInput from "./app-input";
import AppFormRow from "./app-form-row";

const AppSelectRack = ({ data, setSelectRack, selectShelf, shelfLocation, displayOnly = false }) => {
    return (
        <Fragment>
            <div className="app-select-rack">
                <div className="app-select-rack__wrapper">
                    <table>
                        <tbody>
                            <AppFormRow leftHeader={"Shelf Selected"} rightSrc={<AppInput disabled={true} type="text" value={shelfLocation ? "Shelf " + shelfLocation : ""} />} />
                            <AppFormRow leftHeader={"Current Position"} rightSrc={<AppInput disabled={true} type="text" value={selectShelf ? selectShelf : ""} />} />
                        </tbody>
                    </table>

                    {data?.map((item, index) => (
                        <div className="app-select-rack__radioWrapper">
                            {!displayOnly && <input style={{ alignSelf: "center" }} type="radio" name="rack" value={item.warehouseSectionMapId} onChange={(e) => setSelectRack(e.target.value)} />}
                            <div className="app-select-rack__radio">
                                Rack {index + 1}
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ marginLeft: 15 }}>
                                        {item?.productItemsWithStockCount.map((i, ind) => (
                                            <li>
                                                {i.productItemVariant} - {i.onHandStockCount}
                                            </li>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Fragment>
    );
};

export default AppSelectRack;

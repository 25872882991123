const pathnames = {
    //general
    pageDashboard: "/dashboard",
    pagePurchaseOrder: "/purchase-order",
    pageReturns: "/returns",
    pageFurfillment: "/furfillment",
    pageCustomers: "/customers",
    pageDeliveryPartners: "/delivery-partners",
    pageReporting: "/reporting",
    pageUsers: "/users",
    page404: "/404",
    pageIntegrations: "/integrations",
    pageHelpFeedback: "/help-feedback",

    //authentication or user
    pageSplashScreen: "/",
    pageSignUp: "/sign-up",
    pageLogin: "/login",
    pageForgotPassword: "/forgot-password",
    pageForgotPasswordHash: "/forgot-password/:forgotPasswordHash",
    pageUserList: "/user/userList",
    pageAddUser: "/user/addUser",
    pageUserProfile: "/user/userProfile",
    pageChangePassword: "/user/changePassword",
    pageVerifyEmail: "/verifyEmail",
    pageCreatePassword: "/createPassword",
    pageSignUpSuccess: "/signUpSuccess",
    pageViewOtherProfile: "/user/viewOtherProfile/:userId",

    //inventory
    pageInventory: "/inventory",
    pageInventoryProductList: "/inventory/product-list",
    pageInventoryVariants: "/inventory/variants",
    pageInventoryPurchaseOrder: "/inventory/purchase-order",
    pageAddProduct: "/inventory/addProduct",
    pageProductCategory: "/inventory/productCategory",
    pageEditProduct: "/inventory/editProduct/:productId",
    pageEditVariant: "/inventory/variants/edit/:productItemId",
    pageAddNewVariant: "/inventory/variants/addNewVariant/:productId",
    pageCreateStockCount: "/inventory/variants/createStockCount",
    pageManageStock: "/inventory/variants/manageStock/:productItemId",
    pageStockMovementList: "/inventory/variants/stockMovement/:productItemId",

    //supplier
    pageRelationshipSuppliers: "/relationships/suppliers",
    pageEditSupplier: "/relationships/suppliers/edit/:id",
    pageAddSupplier: "/relationships/suppliers/add",

    //warehouse
    pageWarehouse: "/warehouse/warehouse",
    pageAddWarehouse: "/warehouse/addWarehouse",
    pageEditWarehouse: "/warehouse/editWarehouse/:warehouseId",
    pageViewStockPosition: "/warehouse/viewStockPosition/:warehouseCode",
};

export default pathnames;

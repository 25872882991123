import React, { Children, isValidElement, cloneElement } from "react";
import { useTable, useRowSelect, useFlexLayout, useSortBy } from "react-table";
import { useSticky } from "react-table-sticky";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { TiArrowUnsorted } from "react-icons/ti";

const AppReactTable = ({ columns, data, children, useHideColumn, onHeaderClick }) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, allColumns } = useTable(
        {
            columns,
            data,
            initialState: {
                hiddenColumns: columns.map((column) => {
                    if (column.initialHideColumn === true) {
                        return column.id;
                    } else {
                        return null;
                    }
                }),
            },
            autoResetHiddenColumns: false,
        },
        useSticky,
        useFlexLayout,
        useSortBy,
        useRowSelect
    );

    const childrenWithProps = Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child, { allColumns });
        }
        return child;
    });

    return (
        <div className="app-react-table">
            <div>{useHideColumn ? childrenWithProps : children}</div>
            <div className="app-react-table__content">
                <table {...getTableProps()} className="app-react-table__table-wrapper">
                    <thead className="header">
                        {headerGroups.map((headerGroup, i) => (
                            <tr key={i} className="header__row" {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, index) => (
                                    <th key={`header_${index}`} className="header__row-header" {...column.getHeaderProps(column.getSortByToggleProps())} onClick={column?.onClick}>
                                        {column.render("Header")}
                                        {/* {column.sorting ? column.isSorted ? column.isSortedDesc ? <IoMdArrowDropup size={16} /> : <IoMdArrowDropdown size={16} /> : <TiArrowUnsorted size={15} /> : ""} */}
                                        {/* {column.sorting ? column.sorted ? column.sortDecs ? <IoMdArrowDropup size={16} /> : <IoMdArrowDropdown size={16} /> : <TiArrowUnsorted size={15} /> : ""} */}
                                        {column.sorting ? column.sortDecs ? <IoMdArrowDropup size={16} /> : <IoMdArrowDropdown size={16} /> : ""}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="body">
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr key={`tr_${i}`} {...row.getRowProps()} className="body__row">
                                    {row.cells.map((cell, index) => {
                                        return (
                                            <td key={`td_${index}`} className="body__row-data" {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AppReactTable;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pageSize: null,
    pageNumber: null,
    numberOfElements: null,
    totalElements: null,
    totalPages: null,
    first: null,
    last: null,
    hasNext: null,
    hasPrevious: null,
    hasContent: null,
    content: null,
};

export const createStockCountSlice = createSlice({
    name: "createStockCount",
    initialState,
    reducers: {
        updateCreateStockCount: (state, action) => {
            return {
                ...state,
                ...action.payload.data["atfc-data"],
            };
        },
    },
});

export const { updateCreateStockCount } = createStockCountSlice.actions;

export default createStockCountSlice.reducer;

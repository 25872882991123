import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userId: null,
    username: null,
    email: null,
    userDisplayName: null,
    userRolesGroup: null,
    userStatus: null,
    userExtendedDetails: {
        profileInfo: {},
        appPreferences: {},
    },
    lastLogin: null,
    createDate: null,
    modifyDate: null,
    createBy: null,
    modifyBy: null,
};

export const otherUserDetailsSlice = createSlice({
    name: "otherUserDetails",
    initialState,
    reducers: {
        updateOtherUserDetails: (state, action) => {
            return {
                ...state,
                ...action.payload.data["atfc-data"],
            };
        },
    },
});

export const { updateOtherUserDetails } = otherUserDetailsSlice.actions;

export default otherUserDetailsSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pageSize: null,
    pageNumber: null,
    numberOfElements: null,
    totalElements: null,
    totalPages: null,
    first: null,
    last: null,
    hasNext: null,
    hasPrevious: null,
    hasContent: null,
    content: null,
};

export const productCategoryListSlice = createSlice({
    name: "productCategoryList",
    initialState,
    reducers: {
        updateProductCategoryList: (state, action) => {
            return {
                ...state,
                ...action.payload.data["atfc-data"],
            };
        },
    },
});

export const { updateProductCategoryList } = productCategoryListSlice.actions;

export default productCategoryListSlice.reducer;

// libraries
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import * as Yup from "yup";
import { Formik } from "formik";
import { NotificationContainer, NotificationManager } from "react-notifications";

// commons
import CONSTANSTS from "common/constansts";
// routes
import pathnames from "routes/pathnames";
// components
import AppAuthLayout from "components/pages/authentication/app-auth-layout";
import AppAuthInput from "components/pages/authentication/app-auth-input";
import AppButton from "components/app-button";
// assets
import { VscDebugStackframeDot } from "react-icons/vsc";
import IconLock from "assets/images/pages/authentication/icon-lock.svg";
import iconTick from "assets/images/icon-green-tick.svg";
import iconCross from "assets/images/icon-red-cross.svg";

//api
import api from "services/api";
import createNotification from "services/helper";

const defaultPasswordState = {
    passwordMinEightChar: 0,
    passwordMinOneNumber: 0,
    passwordMinOneUppercase: 0,
    passwordMinOneSpecialChar: 0,
};

const PageCreatePassword = (props) => {
    const history = useHistory();
    // 0 = initial state, -1 = error, 1 = success
    const [passwordRequirement, setPasswordRequirement] = useState(defaultPasswordState);

    const newPasswordConfirmPasswordMatch = (data) => {
        const passwordMinEightChar = CONSTANSTS.REGEX.minEightChar.test(data);
        const passwordMinOneUppercase = CONSTANSTS.REGEX.atLeaseOneUppercase.test(data);
        const passwordMinOneNumber = CONSTANSTS.REGEX.atLeastOneNumber.test(data);
        const passwordMinOneSpecialChar = CONSTANSTS.REGEX.atLeaseOneSpecialChar.test(data);
        setPasswordRequirement({
            passwordMinEightChar: passwordMinEightChar ? 1 : -1,
            passwordMinOneNumber: passwordMinOneNumber ? 1 : -1,
            passwordMinOneUppercase: passwordMinOneUppercase ? 1 : -1,
            passwordMinOneSpecialChar: passwordMinOneSpecialChar ? 1 : -1,
        });
    };

    const btnCreatePassword = async (data) => {
        try {
            let payload = {
                signUpId: props?.location?.state?.res?.preSignUpId,
                password: data?.confirmPassword,
                verificationCode: props?.location?.state?.res?.verificationCode,
            };
            const response = await api.post.completeSignUp(payload);
            if (response.data["atfc-message"] == "SUCCESS") {
                history.push(pathnames.pageSignUpSuccess);
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    const { passwordMinEightChar, passwordMinOneUppercase, passwordMinOneNumber, passwordMinOneSpecialChar } = passwordRequirement;

    const iconTickImage = () => <img alt="icon-tick" src={iconTick} className="page-create-password__tick-cross-img" />;
    const iconCrossImage = () => <img alt="icon-cross" src={iconCross} className="page-create-password__tick-cross-img" />;

    return (
        <AppAuthLayout>
            <Formik
                initialValues={{ newPassword: "", confirmPassword: "" }}
                validationSchema={Yup.object({
                    newPassword: Yup.string()
                        .required("New password is required")
                        .min(8, "Must be 8 characters or more")
                        .matches(/[a-z]+/, "Should contain at least 1 lowercase")
                        .matches(/[A-Z]+/, "Should contain at least 1 uppercase")
                        .matches(/\d+/, "Should contain at least 1 number")
                        .matches(/[@$!%*#?&]+/, "Should contain at least 1 special character"),
                    confirmPassword: Yup.string()
                        .required("Confirm password is required")
                        .oneOf([Yup.ref("newPassword"), null], "Passwords does not match"),
                })}
                onSubmit={(values, actions) => {
                    btnCreatePassword(values);
                }}
                validator={() => ({})}>
                {({ values, errors, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="page-create-password">
                            <div className="page-create-password__title">Create Password</div>
                            <div className="page-create-password__input-wrapper">
                                <AppAuthInput
                                    key="new password"
                                    name="newPassword"
                                    type="password"
                                    iconAlt="password-icon"
                                    iconSrc={IconLock}
                                    placeholder={"Enter new password"}
                                    value={values.newPassword}
                                    // error={errors.newPassword}
                                    onChange={(e) => {
                                        handleChange(e);
                                        newPasswordConfirmPasswordMatch(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="page-create-password__input-wrapper">
                                <AppAuthInput key="confirm password" name="confirmPassword" type="password" iconAlt="password-icon" iconSrc={IconLock} placeholder={"Confirm new password"} value={values.confirmPassword} error={errors.confirmPassword} onChange={handleChange} />
                            </div>
                            <div className="page-create-password__password-requirement-wrapper">
                                <div
                                    className={`page-create-password__password-requirement 
						${passwordMinEightChar === 1 ? `page-create-password--success` : passwordMinEightChar === -1 ? `page-create-password--error` : null}`}>
                                    {passwordMinEightChar === 1 ? iconTickImage() : passwordMinEightChar === -1 ? iconCrossImage() : <VscDebugStackframeDot />}
                                    Use at least 8 characters
                                </div>
                                <div
                                    className={`page-create-password__password-requirement 
						${passwordMinOneUppercase === 1 ? `page-create-password--success` : passwordMinOneUppercase === -1 ? `page-create-password--error` : null}`}>
                                    {passwordMinOneUppercase === 1 ? iconTickImage() : passwordMinOneUppercase === -1 ? iconCrossImage() : <VscDebugStackframeDot />}
                                    Should contain at least 1 uppercase
                                </div>
                                <div
                                    className={`page-create-password__password-requirement 
						${passwordMinOneNumber === 1 ? `page-create-password--success` : passwordMinOneNumber === -1 ? `page-create-password--error` : null}`}>
                                    {passwordMinOneNumber === 1 ? iconTickImage() : passwordMinOneNumber === -1 ? iconCrossImage() : <VscDebugStackframeDot />}
                                    Should contain at least 1 numeric
                                </div>
                                <div
                                    className={`page-create-password__password-requirement 
						${passwordMinOneSpecialChar === 1 ? `page-create-password--success` : passwordMinOneSpecialChar === -1 ? `page-create-password--error` : null}`}>
                                    {passwordMinOneSpecialChar === 1 ? iconTickImage() : passwordMinOneSpecialChar === -1 ? iconCrossImage() : <VscDebugStackframeDot />}
                                    Should contain at least 1 special character
                                </div>
                            </div>
                            <div className="page-create-password__primary-button">
                                <AppButton type="submit" colorType="primary" label="Next" />
                            </div>
                        </div>

                        <NotificationContainer />
                    </form>
                )}
            </Formik>
        </AppAuthLayout>
    );
};

export default PageCreatePassword;

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createLogger } from "redux-logger";
import authReducer from "redux/slices/auth-slice";
import supplierListReducer from "redux/slices/supplier-slice";
import inventoryListReducer from "redux/slices/inventory-slice";
import ProductCategoryReducer from "redux/slices/product-category.slice";
import VariantListReducer from "redux/slices/variant-slice";
import VariantListDetailsReducer from "redux/slices/variant-details-slices-with-stock";
import WarehouseListReducer from "redux/slices/warehouse-slice";
import CreateStockCountReducer from "redux/slices/create-stock-count";
import SelectedVariantDetailsReducer from "redux/slices/selected-variant-details";
import UserListReducer from "redux/slices/user-list-slice";
import UserProfileReducer from "redux/slices/user-profile-slice";
import DashboardReducer from "redux/slices/dashboard-slice";
import ApiErrorReducer from "redux/slices/api-error-slice";
import StockMovementReducer from "redux/slices/stock-movement-slice";
import OtherUserDetailsReducer from "redux/slices/selected-user-details";

import { isDevelopmentMode } from "common/utilities";

const persistConfig = { key: "root", storage };
const logger = createLogger({ collapsed: true });
const reducers = {
    auth: authReducer,
    supplierList: supplierListReducer,
    inventoryList: inventoryListReducer,
    productCategoryList: ProductCategoryReducer,
    variantList: VariantListReducer,
    warehouseList: WarehouseListReducer,
    createStockCount: CreateStockCountReducer,
    variantDetailsWithStock: VariantListDetailsReducer,
    selectedVariantDetails: SelectedVariantDetailsReducer,
    userList: UserListReducer,
    userProfile: UserProfileReducer,
    dashboard: DashboardReducer,
    apiError: ApiErrorReducer,
    stockMovement: StockMovementReducer,
    otherUserDetails: OtherUserDetailsReducer,
};
const combinedReducers = combineReducers(reducers);
const debug = isDevelopmentMode();
const middleware = [debug && logger].filter(Boolean);

const rootReducer = (state, action) => {
    if (action.type === "logout") {
        state = undefined;
    }
    return combinedReducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({ reducer: persistedReducer, middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middleware) });
export const persistor = persistStore(store);

export default store;

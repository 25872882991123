import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { AiOutlineClose } from "react-icons/ai";

//components
import AppSelectRack from "components/app-select-rack";

//asset
import iconBookshelf from "assets/images/icon-bookshelf.svg";

const ModalRackInfo = ({ isOpen, toggle, rackInfo, selectShelf, shelfLocation }) => {
    return (
        <Modal size="lg" isOpen={isOpen} className="page-view-stock-position" contentClassName="page-view-stock-position__content">
            <ModalHeader className="header">
                <AiOutlineClose className="header__close-button" onClick={toggle} />
            </ModalHeader>
            <ModalBody className="body">
                <div className="body__container">
                    <img src={iconBookshelf} alt="icon edit" width={30} height={30} />
                    <div className="body__title">Rack Info</div>
                </div>
                <AppSelectRack displayOnly={true} shelfLocation={shelfLocation} data={rackInfo} selectShelf={selectShelf} />
            </ModalBody>
        </Modal>
    );
};

export default ModalRackInfo;

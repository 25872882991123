// libraries
import React, { useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import AppLinkButton from "components/app-link-button";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

//api
import api from "services/api";

//routes
import pathnames from "routes/pathnames";

// components
import AppButton from "components/app-button";
import AppSearchBar from "components/app-search-bar";
import AppTableColumnHeader from "components/app-table-column-header";
import AppTableFooter from "components/app-table-footer";
import AppSuccessMessage from "components/app-success-message";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTablePage from "components/app-table-page.jsx";

// assets
import iconEdit from "assets/images/icon-edit.svg";
import iconBookshelf from "assets/images/icon-bookshelf.svg";

//redux
import { updateWarehouseList } from "redux/slices/warehouse-slice";
import { updateApiError } from "redux/slices/api-error-slice";

const PageWarehouse = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [searchQuery, setSearchQuery] = useState("");
    const [searchError, setSearchError] = useState();
    const [sortSelected, setSortSelected] = useState("");
    const warehouseList = useSelector((state) => state.warehouseList);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [sortHeader, setSortHeader] = useState();
    const [sortAsc, setSortAsc] = useState("");
    const [paginationInfo, setPaginationInfo] = useState({
        page: 1,
        size: 20,
        numberOfElements: 16,
        totalElements: 10,
        totalPages: 1,
    });
    const sortOptions = [
        { id: "visits", desc: true },
        { id: "age", desc: true },
    ];

    const columns = [
        {
            id: "no",
            Header: () => <AppTableColumnHeader title="No" />,
            Cell: (row) => {
                return <div>{row.row.index + 1}</div>;
            },
            width: 50,
            minWidth: 50,
            maxWidth: 50,
        },
        {
            id: "warehouseName",
            columnsListLabel: "Warehouse Name",
            Header: () => <AppTableColumnHeader title="Warehouse Name" />,
            accessor: "warehouseName",
            width: 180,
            minWidth: 180,
            sorting: true,
            sortType: "string",
            onClick: () => {
                setSortHeader("warehouseName");
                if (sortHeader == "warehouseName") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "warehouseName" && sortAsc == "",
            sortDecs: sortHeader == "warehouseName" && sortAsc == "-",
        },
        {
            id: "warehouseSectionCount",
            columnsListLabel: "Section",
            Header: (header) => <AppTableColumnHeader title="Section" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => <div className="page-product-list-table--line-clamp-2">{row.warehouseSectionCount}</div>,
            minWidth: 70,
            width: 70,
            disableSortBy: true,
        },
        {
            id: "warehouseStatus",
            columnsListLabel: "warehouseStatus",
            columnId: "warehouseStatus",
            Header: (header) => <AppTableColumnHeader title="Status" />,
            accessor: (row) => {
                if (row.warehouseStatus == "ACTIVE") {
                    return <span className="page-product-list-table__status--active">Active</span>;
                } else {
                    return <span className="page-product-list-table__status--disabled">Disabled</span>;
                }
            },
            width: 90,
            minWidth: 90,
            sorting: true,
            sortType: (a, b) => {
                if (a.values["warehouseStatus"].props.children < b.values["warehouseStatus"].props.children) {
                    return -1;
                } else if (a.values["warehouseStatus"].props.children > b.values["warehouseStatus"].props.children) {
                    return 1;
                }

                return 0;
            },
            onClick: () => {
                setSortHeader("warehouseStatus");
                if (sortHeader == "warehouseStatus") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "warehouseStatus" && sortAsc == "",
            sortDecs: sortHeader == "warehouseStatus" && sortAsc == "-",
        },
        {
            id: "createDate",
            columnsListLabel: "Date Created",
            Header: (header) => <AppTableColumnHeader title="Date Created" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: "createDate",
            sorting: true,
            onClick: () => {
                setSortHeader("createDate");
                if (sortHeader == "createDate") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "createDate" && sortAsc == "",
            sortDecs: sortHeader == "createDate" && sortAsc == "-",
        },
        {
            id: "modifyDate",
            columnsListLabel: "Last Updated",
            Header: (header) => <AppTableColumnHeader title="Last Updated" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: "modifyDate",
            sorting: true,
            onClick: () => {
                setSortHeader("modifyDate");
                if (sortHeader == "modifyDate") {
                    if (sortAsc == "") {
                        setSortAsc("-");
                    } else setSortAsc("");
                }
            },
            sorted: sortHeader == "modifyDate" && sortAsc == "",
            sortDecs: sortHeader == "modifyDate" && sortAsc == "-",
        },
        {
            id: "buttons",
            Header: () => <AppTableColumnHeader title="Action" style={{ justifyContent: "center" }} />,
            Cell: (row) => {
                return (
                    <div className="row-wrapper">
                        <AppButton buttonType="transparent" onClick={() => history.push(`/warehouse/viewStockPosition/${row.row.original.warehouseCode}`, { selectedData: row.row.original })} label={<img src={iconBookshelf} alt="icon edit" />} />
                        <AppButton buttonType="transparent" onClick={() => history.push(`/warehouse/editWarehouse/${row.row.original.warehouseId}`, { selectedData: row.row.original })} label={<img src={iconEdit} alt="icon edit" />} />
                    </div>
                );
            },
        },
    ];

    const breadcrumbArray = [
        { title: "Warehouse", onClick: null },
        { title: "Warehouse List", onClick: null },
    ];

    const loadData = async () => {
        try {
            let req = {
                page: paginationInfo.page,
                pageSize: paginationInfo.size,
            };
            if (!_.isEmpty(searchQuery)) {
                req.searchQuery = searchQuery;
            }
            if (!_.isEmpty(sortHeader)) {
                req.sort = `${sortAsc}${sortHeader}`;
            }
            let response = await api.get.getWarehouseList(req);
            setPaginationInfo({
                ...paginationInfo,
                numberOfElements: response.data["atfc-data"]?.numberOfElements,
                totalElements: response.data["atfc-data"]?.totalElements,
                totalPages: response.data["atfc-data"]?.totalPages,
            });
            dispatch(updateWarehouseList(response));
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    };

    const searchSuccess = (e) => {
        setSearchError();
        setPaginationInfo({ ...paginationInfo, page: 1 });
        setSearchQuery(e.target.value);
    };

    const searchEnterBtn = (e) => {
        if (e.target.value && e.target.value.length > 0 && e.target.value.length < 3) {
            setSearchError("Cannot be lesser than 3 characters");
        } else {
            searchSuccess(e);
        }
    };

    return (
        <AppTablePage
            sorted={sortOptions}
            popUpMessage={<AppSuccessMessage show={showSuccessMessage} message="Stock amount has been edited." />}
            breadcrumb={<AppBreadcrumb breadcrumbArray={breadcrumbArray} />}
            pageTitle="Warehouse List"
            searchBar={<AppSearchBar placeholder="Search by warehouse name" onKeyEnter={searchEnterBtn} searchError={searchError} />}
            showResult="Showing all 2500 results"
            tableFunctions={[<AppLinkButton label="Add New Warehouse" buttonType="linkBtn" linkTo={pathnames.pageAddWarehouse} buttonColor="primary" leftSrc={<AiFillPlusCircle size={16} />} />]}
            tableColumns={columns}
            totalElements={paginationInfo.totalElements}
            tableData={warehouseList.content ? warehouseList.content : []}
            tablePageFooter={<AppTableFooter sortAsc={sortAsc} sortHeader={sortHeader} searchQuery={searchQuery} loadData={loadData} paginationInfo={paginationInfo} setPaginationInfo={(paginationInfo) => setPaginationInfo(paginationInfo)} />}
        />
    );
};

export default PageWarehouse;

export const isDevelopmentMode = () => !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const sanitizeError = (originalError) => {
    let error = originalError;

    if (typeof originalError === "object") {
        if (originalError.response) {
            if (originalError.response.data) {
                error = originalError.response.data;
            } else {
                error = originalError.response;
            }
        } else if (originalError.data) {
            error = originalError.data;
        }

        return error.message;
    } else {
        error = originalError;
        return error;
    }
};

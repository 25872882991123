import * as axs from "axios";

const axios = {
    get: async (url, params) => {
        const endPointUrl = getEndPointUrl(url);
        const headers = getHeaders();
        return axs.get(endPointUrl, { params, ...headers });
    },
    post: async (url, body, head) => {
        const endPointUrl = getEndPointUrl(url);
        const headers = getHeaders(head);
        return axs.post(endPointUrl, body, headers);
    },
};

const getHeaders = (head) => {
    const headerType = () => {
        if (head == "media") {
            return "multipart/form-data";
        } else return "application/json";
    };
    let headers = { "Content-Type": headerType() };

    const token = localStorage.getItem("@storage_token");

    if (token) {
        headers = Object.assign({}, headers, {
            Authorization: token,
        });
    }

    return { headers };
};

export const getEndPointUrl = (path) => {
    const endpoint = process.env.REACT_APP_API_URL + path;
    return endpoint;
};

export default axios;

// libraries
import React from "react";
import { useHistory } from "react-router-dom";
// routes
import pathnames from "routes/pathnames";
// components
import AppButton from "components/app-button";
import AppAuthLayout from "components/pages/authentication/app-auth-layout";

const PageSignUpSuccess = () => {
    const history = useHistory();
    return (
        <AppAuthLayout>
            <div className="page-signup-success">
                <div className="page-signup-success__title">Successful</div>
                <div className="page-signup-success__description">Password created! You have been signed in successfully.</div>
                <div className="page-signup-success__primary-button">
                    <AppButton colorType="primary" onClick={() => history.push(pathnames.pageLogin)} label="Get Started" />
                </div>
            </div>
        </AppAuthLayout>
    );
};

export default PageSignUpSuccess;

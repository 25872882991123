import React, { Fragment, useState, useRef } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import iconCalendar from "assets/images/icon-calendar.svg";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

const AppInput = ({ type, value, placeholder, setValue, selectList, uppercase, label, error, valueKey, length, leftLabel, styleClass, showInput, setShowInput, ...rest }) => {
    const [selectToggle, setSelectToggle] = useState(false);
    const dateRef = useRef();

    const getNumberInputClassName = (type) => {
        const classNames = ["app-input-number"];

        if (type === "number-with-units") classNames.push("app-input-number--with-units");
        if (styleClass) classNames.push(styleClass);
        return classNames.join(" ");
    };

    const getTextInputClassName = () => {
        const classNames = ["app-input-text"];
        if (leftLabel) classNames.push("app-input-text__withLeftLabel");
        if (length === "short") classNames.push("app-input-text__short");
        if (length === "extra_short") classNames.push("app-input-text__extra_short");
        else classNames.push("app-input-text__long");
        return classNames.join(" ");
    };

    const getSelectInputClassName = (selectToggle, value) => {
        const classNames = ["app-input-select__input"];

        if (selectToggle) classNames.push("app-input-select__input--selected");

        if (!value) classNames.push("app-input-select__input--placeholder");

        return classNames.join(" ");
    };
    const getAppInputContainerClass = () => {
        const classNames = ["app-input"];

        if (styleClass) classNames.push(styleClass);

        return classNames.join(" ");
    };
    switch (type) {
        case "text":
            return (
                <div className={getAppInputContainerClass()}>
                    <div className="app-input-text-leftLabel-wrapper">{leftLabel}</div>
                    <input className={getTextInputClassName()} placeholder={placeholder} value={value} onChange={(e) => (uppercase ? setValue(e.target.value.toUpperCase()) : setValue(e.target.value))} {...rest} />
                    {error && <div className="error-msg-wrapper">{error}</div>}
                </div>
            );
        case "textarea":
            return (
                <div className="app-input">
                    <textarea className="app-input-textarea" placeholder={placeholder} value={value} onChange={(e) => setValue(e.target.value)} {...rest} />
                    {error && <div className="error-msg-wrapper">{error}</div>}
                </div>
            );
        case "select":
            return (
                <div className="app-input">
                    <Dropdown className="app-input-select" isOpen={selectToggle} toggle={() => setSelectToggle(!selectToggle)}>
                        <DropdownToggle tag="span" className={getSelectInputClassName(selectToggle, value)}>
                            <div>{value ? value : placeholder}</div>
                            {selectToggle ? <IoMdArrowDropup size={24} /> : <IoMdArrowDropdown size={24} />}
                        </DropdownToggle>
                        <DropdownMenu className="app-input-select__list">
                            {selectList.map((item, index) => (
                                <DropdownItem key={index} className="app-input-select__option" onClick={() => setValue(item.value)}>
                                    {item[valueKey]}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                </div>
            );
        case "number":
        case "number-with-units":
            return (
                <div className="app-input">
                    <div className={getNumberInputClassName(type)}>
                        <input type="number" min={0} className="app-input-number__input" placeholder={placeholder} value={value} onChange={(e) => setValue(e.target.value)} {...rest} />
                    </div>
                    {error && <div className="error-msg-wrapper">{error}</div>}
                </div>
            );
        case "date":
            return (
                <div className="app-input">
                    <div className="app-input-date">
                        <DatePicker ref={dateRef} placeholderText={placeholder} className="app-input-date__input" selected={value} onChange={(date) => setValue(date)} {...rest} />
                        <img className="app-input-date__icon" src={iconCalendar} alt="icon calendar" onClick={() => dateRef.current.setOpen(true)} />
                    </div>
                </div>
            );
        case "radio":
            return (
                <>
                    <input className="app-input-radio" type="radio" onChange={(e) => setValue(e.target.value)} {...rest} />
                    {label}
                    {error && <div className="error-msg-wrapper">{error}</div>}
                </>
            );
        case "password":
            return (
                <>
                    <div className="app-input">
                        <div className="app-input-password">
                            <input type={showInput ? "text" : "password"} className="app-input-password__input" {...rest} />
                            <div className="app-input-password__password-eye" onClick={() => setShowInput(!showInput)}>
                                {showInput ? <IoEyeOutline size={24} /> : <IoEyeOffOutline size={24} />}
                            </div>
                        </div>
                        {error && <div className="error-msg-wrapper">{error}</div>}
                    </div>
                </>
            );
        default:
            return <Fragment />;
    }
};

export default AppInput;

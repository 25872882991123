import React from "react";
import AppLinkButton from "components/app-link-button";
import Logo from "assets/images/components/app-auth-layout/logo-white.svg";

const AppAuthLayout = ({ children }) => {
    return (
        <div className="app-auth-layout">
            <div className="app-auth-layout__body">
                <div className="app-auth-layout__content">
                    <div className="header">
                        <img src={Logo} alt="" className="header__logo" />
                        <div className="header__title">OGISTIC</div>
                        <div className="header__description">Optimize Warehouse and Inventory Operation</div>
                    </div>

                    {children}
                    <div className="footer">
                        Powered by <AppLinkButton className="footer__link" buttonType="href" buttonColor="white" linkTo="https://www.atxsolutions.net/" label={"ATX"} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppAuthLayout;

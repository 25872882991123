import React from "react";
import { Link } from "react-router-dom";
import pathnames from "routes/pathnames";

const getButtonClassName = ({ buttonType, buttonColor, className, fontSize, leftSrc }) => {
    const linkClasses = {
        href: "app-link-button__href",
        link: "app-link-button__link",
        button: "app-link-button__button",
        linkBtn: "app-link-button__linkBtn",
    };
    const classNames = [linkClasses[buttonType]];

    if (buttonColor === "primary") classNames.push("app-link-button__primary");

    if (buttonColor === "white") classNames.push("app-link-button__white");

    if (fontSize === "small") classNames.push("app-link-button--small-font");

    if (className) classNames.push(className);

    return classNames.join(" ");
};

const getLeftSrcClassName = () => {
    const classNames = ["app-button__icon-wrapper"];
    return classNames.join(" ");
};

const AppLinkButton = ({ label, buttonType, buttonColor, linkTo, onClick, className, fontSize, leftSrc, ...rest }) => {
    const buttonClassNames = getButtonClassName({ buttonType, buttonColor, className, fontSize });

    switch (buttonType) {
        case "href":
            return (
                <a href={linkTo} className={buttonClassNames}>
                    {label}
                </a>
            );
        case "link":
            return (
                <Link to={{ pathname: linkTo, state: rest }} className={buttonClassNames}>
                    {label}
                </Link>
            );
        case "linkBtn":
            return (
                <Link to={{ pathname: linkTo, state: rest }} className={buttonClassNames}>
                    {leftSrc ? <div className={getLeftSrcClassName()}>{leftSrc}</div> : null}
                    {label}
                </Link>
            );
        default:
            // button
            return (
                <div onClick={onClick} className={buttonClassNames}>
                    {leftSrc ? <div className={getLeftSrcClassName()}>{leftSrc}</div> : null}
                    <span>{label}</span>
                </div>
            );
    }
};

export default AppLinkButton;

// libraries
import React, { useState, useEffect } from "react";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useHistory, useParams } from "react-router-dom";
import pathnames from "routes/pathnames";
import InputMask from "react-input-mask";
import _ from "lodash";
import { Formik } from "formik";
import * as Yup from "yup";
// components
import AppBreadcrumb from "components/app-breadcrumb";
import AppInput from "components/app-input";
import AppItemDropdown from "components/app-item-dropdown";
import AppEditForm from "components/app-edit-form";
import AppFormRow from "components/app-form-row";
import AppButton from "components/app-button";

//api
import api from "services/api";
import createNotification from "services/helper";

const PageAddSupplier = (props) => {
    const history = useHistory();
    const [supplierName, setSupplierName] = useState();
    const [supplierBusinessNo, setSupplierBusinessNo] = useState();
    const [personInCharge, setPersonInCharge] = useState();
    const [supplierEmail, setSupplierEmail] = useState();
    const [supplierContact, setSupplierContact] = useState();
    const [address1, setAddress1] = useState();
    const [address2, setAddress2] = useState();
    const [city, setCity] = useState();
    const [country, setCountry] = useState();
    const [postcode, setPostcode] = useState();
    const [addressState, setAddressState] = useState();
    const [supplierStatus, setSupplierStatus] = useState();

    const selectList = [
        { id: 1, value: "Johor", label: "Johor" },
        { id: 2, value: "Kedah", label: "Kedah" },
        { id: 3, value: "Kelantan", label: "Kelantan" },
        { id: 4, value: "Kuala Lumpur", label: "Kuala Lumpur" },
        { id: 5, value: "Malacca", label: "Malacca" },
        { id: 6, value: "Negeri Sembilan", label: "Negeri Sembilan" },
        { id: 7, value: "Pahang", label: "Pahang" },
        { id: 8, value: "Penang", label: "Penang" },
        { id: 9, value: "Perak", label: "Perak" },
        { id: 10, value: "Perlis", label: "Perlis" },
        { id: 11, value: "Sabah", label: "Sabah" },
        { id: 12, value: "Sarawak", label: "Sarawak" },
        { id: 13, value: "Selangor", label: "Selangor" },
        { id: 14, value: "Terengganu", label: "Terengganu" },
    ];

    const selectListCountry = [
        { id: 1, value: "Malaysia", label: "Malaysia" },
        { id: 2, value: "Singapore", label: "Singapore" },
    ];

    const breadcrumbArray = [
        {
            title: "Relationships",
            onClick: () => {
                history.push(pathnames.pageRelationshipSuppliers);
            },
        },
        {
            title: "Suppliers",
            onClick: () => {
                history.push(pathnames.pageRelationshipSuppliers);
            },
        },
        { title: "Add New Supplier", onClick: null },
    ];

    const phoneFormatting = (dasherizedStr) => {
        let countryCode = dasherizedStr.substr(0, 2);
        if (!_.isEmpty(dasherizedStr) && countryCode == "+6") {
            let newValue = dasherizedStr.slice(2);
            return newValue.replace(/[_-]/g, " ").replace(/ /g, "");
        } else return dasherizedStr;
    };

    const btnAddSupplier = async (values) => {
        try {
            let payload = {
                supplierName: values.supplierName,
                supplierBusinessNo: values.supplierBusinessNo,
                supplierContact: phoneFormatting(values.supplierContact),
                supplierEmail: values.supplierEmail,
                supplierStatus: values.supplierStatus,
                personInCharge: values.personInCharge,
                supplierAddress: {
                    address1: values.address1,
                    address2: values.address2,
                    postcode: values.postcode,
                    city: values.city,
                    state: values.addressState,
                    country: values.country,
                },
            };
            const response = await api.post.addSupplier(payload);
            if (response.data["atfc-message"] == "SUCCESS") {
                await createNotification("success", "New supplier created successfully.").then(() => {
                    setTimeout(() => {
                        history.goBack();
                    }, 800);
                });
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    return (
        <Formik
            initialValues={{ supplierName: "", supplierBusinessNo: "", supplierEmail: "", supplierStatus: "ACTIVE", personInCharge: "", address1: "", address2: "", postcode: "", addressState: "", city: "", country: "" }}
            validationSchema={Yup.object({
                supplierName: Yup.string().required("Required"),
                supplierBusinessNo: Yup.string().required("Required"),
                personInCharge: Yup.string().required("Required"),
                supplierEmail: Yup.string().required("Required"),
                supplierStatus: Yup.string().required("Required"),
            })}
            onSubmit={(values, actions) => {
                btnAddSupplier(values);
            }}
            validator={() => ({})}>
            {({ values, errors, setFieldValue, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <AppEditForm
                        breadcrumb={<AppBreadcrumb breadcrumbArray={breadcrumbArray} />}
                        pageTitle={"New Supplier"}
                        submitCancelButton={
                            <>
                                <AppButton colorType="white" type="button" onClick={() => history.goBack()} label={"Cancel"} regularBtn={true} />
                                <AppButton colorType="primary" type="submit" label={"Create"} regularBtn={true} />
                            </>
                        }>
                        <table>
                            <tbody>
                                <AppFormRow leftHeader={"Company Name"} rightSrc={<AppInput type="text" name="supplierName" error={errors.supplierName} onChange={handleChange} value={supplierName} placeholder="Company name" />} />
                                <AppFormRow leftHeader={"Registration No"} rightSrc={<AppInput type="text" name="supplierBusinessNo" error={errors.supplierBusinessNo} onChange={handleChange} value={supplierBusinessNo} placeholder="Business registration no" />} />
                                <AppFormRow leftHeader={"Person In Charge"} rightSrc={<AppInput type="text" name="personInCharge" error={errors.personInCharge} onChange={handleChange} value={personInCharge} placeholder="Person in charge name" />} />
                                <AppFormRow leftHeader={"Email Address"} rightSrc={<AppInput type="text" name="supplierEmail" error={errors.supplierEmail} onChange={handleChange} value={supplierEmail} placeholder="Business email address" />} />
                                {/* <AppFormRow leftHeader={"Contact No"} rightSrc={<AppInput type="text" value={supplierContact} setValue={setSupplierContact} placeholder="Contact number" />} /> */}
                                <AppFormRow
                                    leftHeader={"Contact No"}
                                    rightSrc={
                                        <div className="row-wrapper">
                                            <div className="app-input">
                                                <div className="app-input-text-leftLabel-wrapper">
                                                    <InputMask
                                                        className="app-input-text__long"
                                                        style={{ height: 40, border: "none", paddingLeft: 10 }}
                                                        mask="+6999-99999999"
                                                        name="supplierContact"
                                                        value={supplierContact}
                                                        onChange={(e) => {
                                                            const value = e.target.value || "";
                                                            setFieldValue("supplierContact", value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />
                                <AppFormRow leftHeader={"Address Line 1"} rightSrc={<AppInput type="text" name="address1" value={address1} onChange={handleChange} placeholder="Eg unit no, apartment, suite" />} />
                                <AppFormRow leftHeader={"Address Line 2"} rightSrc={<AppInput type="text" name="address2" value={address2} onChange={handleChange} placeholder="Eg street" />} />
                                <AppFormRow leftHeader={"Postcode"} rightSrc={<AppInput type="text" name="postcode" value={postcode} onChange={handleChange} placeholder="Postcode" />} />
                                <AppFormRow leftHeader={"City"} rightSrc={<AppInput type="text" name="city" value={city} onChange={handleChange} placeholder="City" />} />
                                <AppFormRow leftHeader={"State"} rightSrc={<AppItemDropdown valueKey="value" name="addressState" onChange={(value) => setFieldValue("addressState", value)} labelKey="label" dropdownItems={selectList} labelChange={false} toggleButtonClass="solid" caret={true} setSelectedItem={setAddressState} dropdownLabel={addressState ? addressState : "Select State"} long={true} />} />
                                <AppFormRow leftHeader={"Country"} rightSrc={<AppItemDropdown valueKey="value" name="country" onChange={(value) => setFieldValue("country", value)} labelKey="label" dropdownItems={selectListCountry} labelChange={false} toggleButtonClass="solid" caret={true} setSelectedItem={setCountry} dropdownLabel={country ? country : "Select country"} long={true} />} />
                                <AppFormRow
                                    leftHeader={"Status"}
                                    rightSrc={
                                        <>
                                            <AppInput label={"Active"} name="supplierStatus" value={"ACTIVE"} type="radio" checked={values.supplierStatus == "ACTIVE"} setValue={() => setFieldValue("supplierStatus", "ACTIVE")} />
                                            <AppInput label={"Disabled"} name="supplierStatus" value={"DISABLED"} error={errors.supplierStatus} type="radio" checked={values.supplierStatus == "DISABLED"} setValue={() => setFieldValue("supplierStatus", "DISABLED")} />
                                        </>
                                    }
                                />
                                <AppFormRow leftHeader={"Date Created"} rightSrc={<AppInput disabled={true} type="text" placeholder="Today" />} />
                                <AppFormRow leftHeader={"Last Updated"} rightSrc={<AppInput disabled={true} type="text" placeholder="Today" />} />
                            </tbody>
                        </table>
                        <NotificationContainer />
                    </AppEditForm>
                </form>
            )}
        </Formik>
    );
};

export default PageAddSupplier;

// libraries
import React from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import * as Yup from "yup";
import { Formik } from "formik";
import { NotificationContainer, NotificationManager } from "react-notifications";

// routes
import pathnames from "routes/pathnames";
// components
import AppButton from "components/app-button";
import AppLinkButton from "components/app-link-button";
import AppAuthLayout from "components/pages/authentication/app-auth-layout";
import AppAuthInput from "components/pages/authentication/app-auth-input";
// asset
import IconLock from "assets/images/pages/authentication/icon-lock.svg";
import IconMail from "assets/images/pages/authentication/icon-mail.svg";

//api
import api from "services/api";
import createNotification from "services/helper";

const PageVerifyEmail = () => {
    const history = useHistory();

    const buttonVerifyEmail = async (data) => {
        try {
            let payload = {
                preSignUpId: data?.preSignUpId,
                verificationCode: data?.verificationCode,
            };
            const response = await api.post.verifyPreSignUp(payload);
            if (response.data["atfc-message"] == "SUCCESS") {
                history.push(pathnames.pageCreatePassword, { res: response?.data["atfc-data"] });
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    const buttonResendCode = () => {
        console.log("Resend Code");
    };

    return (
        <AppAuthLayout>
            <Formik
                initialValues={{ preSignUpId: "", verificationCode: "" }}
                validationSchema={Yup.object({
                    preSignUpId: Yup.string().email("Invalid email").required("Password is required"),
                    verificationCode: Yup.string().required("Access code is required"),
                })}
                onSubmit={(values, actions) => {
                    buttonVerifyEmail(values);
                }}
                validator={() => ({})}>
                {({ values, errors, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="page-verify-email">
                            <div className="page-verify-email__title">Verification Email</div>
                            <div className="page-verify-email__description">Please enter the access code which send to your email for email address verification.</div>
                            <div className="page-verify-email__input-wrapper">
                                <AppAuthInput name="preSignUpId" iconAlt="mail-icon" iconSrc={IconMail} error={errors.preSignUpId} placeholder={"johnsmith@gmail.com"} onChange={handleChange} value={values.preSignUpId} />
                            </div>
                            <div className="page-verify-email__input-wrapper">
                                <AppAuthInput name="verificationCode" type="name" iconAlt="lock-icon" iconSrc={IconLock} error={errors.verificationCode} placeholder={"Access code"} onChange={handleChange} value={values.verificationCode} />
                            </div>

                            <AppLinkButton className="page-verify-email__resend-url" buttonColor="primary" buttonType="button" onClick={buttonResendCode} label={"Resend Code"} />

                            <div className="page-verify-email__primary-button">
                                <AppButton type="submit" colorType="primary" label="Verify" />
                            </div>

                            <div className="page-verify-email__login-option">
                                Already have an account? <AppLinkButton buttonType="link" buttonColor="primary" linkTo="/login" label={"Log In"} />
                            </div>
                        </div>

                        <NotificationContainer />
                    </form>
                )}
            </Formik>
        </AppAuthLayout>
    );
};

export default PageVerifyEmail;

import React from "react";
import { compose } from "redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";

import routes from "routes/routes";
import history from "routes/history";
import pathnames from "routes/pathnames";
import withAuthenticate from "contexts/with-authenticate";
import AppLayout from "components/app-layout";

const App = () => {
    return (
        <Router history={history}>
            <AppLayout>
                <Switch>
                    {routes.map((route, index) => {
                        const EnhanceRoute = compose(withAuthenticate)(route.component, route.authenticate);

                        return <Route key={`${route.path}_index`} path={route.path} exact={true} render={(props) => <EnhanceRoute {...props} />} />;
                    })}
                    <Redirect to={pathnames.page404} />
                </Switch>
            </AppLayout>
        </Router>
    );
};

export default App;

// libraries
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiFillPlusCircle } from "react-icons/ai";
import "react-notifications/lib/notifications.css";
import { NotificationContainer, NotificationManager } from "react-notifications";
import _ from "lodash";
import pathnames from "routes/pathnames";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";

// components
import AppBreadcrumb from "components/app-breadcrumb";
import AppInput from "components/app-input";
import AppEditForm from "components/app-edit-form";
import AppFormRow from "components/app-form-row";
import AppButton from "components/app-button";
import AppLinkButton from "components/app-link-button";

//api
import api from "services/api";
import PageAddProductCategory from "./addProductCategory";

//redux
import { updateProductCategoryList } from "redux/slices/product-category.slice";
import AppItemDropdown from "components/app-item-dropdown";
import { updateApiError } from "redux/slices/api-error-slice";
import createNotification from "services/helper";

const PageEditProduct = (props) => {
    // const { categoryCode, categoryId, categoryName, createBy, createDate, inStockQuantity, modifyBy, modifyDate, productCode, productDescription, productId, allocatedQuantity, productItemCount, productName, productStatus } = props.location.state.selectedData;
    const dispatch = useDispatch();
    const history = useHistory();
    const { productId } = useParams();
    const [addNewCategoryDisplay, setAddNewCategoryDisplay] = useState(false);
    const productCategoryList = useSelector((state) => state.productCategoryList);
    const [productItemsSummary, setProductItemsSummary] = useState([]);
    const [localProductName, setLocalProductName] = useState();
    const [localCategoryName, setLocalCategoryName] = useState();
    const [localProductDescription, setLocalProductDescription] = useState();
    const [localProductStatus, setLocalProductStatus] = useState();
    const [localStatusRemark, setLocalStatusRemark] = useState();
    const [localProductCategoryId, setLocalProductCategoryId] = useState();
    const [localCreateDate, setLocalCreateDate] = useState();
    const [localModifyDate, setLocalModifyDate] = useState();
    const [paginationInfo, setPaginationInfo] = useState({
        page: 1,
        size: 100,
        numberOfElements: 16,
        totalElements: 160,
        totalPages: 100,
    });
    const breadcrumbArray = [
        {
            title: "Inventory",
            onClick: () => {
                history.push(pathnames.pageInventoryProductList);
            },
        },
        {
            title: "Products",
            onClick: () => {
                history.push(pathnames.pageInventoryProductList);
            },
        },
        { title: "Edit Product Info", onClick: null },
    ];

    const addBtnToggle = () => {
        setAddNewCategoryDisplay(!addNewCategoryDisplay);
    };

    const btnAddNewCategory = async (payload) => {
        try {
            const response = await api.post.addNewProductCategory(payload);
            if (response.data["atfc-message"] == "SUCCESS") {
                createNotification("success", "New category created successfully.");
                loadData();
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    const loadData = async () => {
        try {
            let response = await api.get.getProductCategoryList({ pageSize: paginationInfo.size, page: paginationInfo.page });
            dispatch(updateProductCategoryList(response));
            let responseDetails = await api.get.getProductDetails(productId);
            setLocalCreateDate(responseDetails.data["atfc-data"]?.createDate);
            setLocalModifyDate(responseDetails.data["atfc-data"]?.modifyDate);
            setLocalStatusRemark(responseDetails.data["atfc-data"]?.productStatusRemark);
            setLocalProductStatus(responseDetails.data["atfc-data"]?.productStatus);
            setLocalProductDescription(responseDetails.data["atfc-data"]?.productDescription);
            setLocalProductName(responseDetails.data["atfc-data"]?.productName);
            setLocalProductCategoryId(responseDetails.data["atfc-data"]?.categoryId);
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    };

    useEffect(() => {
        loadData();
    }, [productId]);

    useEffect(() => {
        if (!_.isEmpty(productCategoryList?.content)) {
            let selectedData = productCategoryList?.content.filter((item) => {
                return item.categoryId == localProductCategoryId;
            });
            if (!_.isEmpty(selectedData)) {
                setLocalCategoryName(selectedData[0]?.categoryName);
            }
        } else return;
    }, [localProductCategoryId]);

    const formatDate = (value) => {
        return moment(value).format("DD/MM/YYYY");
    };

    const btnEditProduct = async () => {
        try {
            let payload = {
                productId: productId,
                productName: localProductName,
                productDescription: localProductDescription,
                productStatus: localProductStatus,
                categoryId: localProductCategoryId,
                productStatusRemark: localStatusRemark,
            };
            const response = await api.post.editProduct(payload);
            if (response.data["atfc-message"] == "SUCCESS") {
                await createNotification("success", "Product info update successfully.").then(() =>
                    setTimeout(() => {
                        history.goBack();
                    }, 800)
                );
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    return (
        <AppEditForm
            breadcrumb={<AppBreadcrumb breadcrumbArray={breadcrumbArray} />}
            pageTitle={localProductName}
            pageTitleRightSrc={
                <div className="row-wrapper">
                    <AppLinkButton label="Add Variant" buttonType="linkBtn" linkTo={`/inventory/variants/addNewVariant/${productId}`} buttonColor="primary" leftSrc={<AiFillPlusCircle size={16} />} />
                </div>
            }
            submitCancelButton={
                <>
                    <AppButton colorType="white" onClick={() => history.goBack()} label={"Cancel"} regularBtn={true} />
                    <AppButton colorType="primary" onClick={() => btnEditProduct()} label={"Update"} regularBtn={true} />
                </>
            }>
            <table>
                <tbody>
                    <AppFormRow leftHeader={"Product ID"} rightSrc={<AppInput disabled={true} type="text" value={productId} />} />
                    <AppFormRow leftHeader={"Product Name"} rightSrc={<AppInput type="text" value={localProductName} setValue={setLocalProductName} placeholder="Enter product name" />} />
                    <AppFormRow
                        leftHeader={"Product Category"}
                        rightSrc={
                            <>
                                <AppItemDropdown valueKey="categoryId" labelKey="categoryName" dropdownItems={!_.isEmpty(productCategoryList?.content) ? productCategoryList?.content : []} labelChange={true} toggleButtonClass="solid" caret={true} setSelectedItemValue={setLocalProductCategoryId} setSelectedItem={setLocalCategoryName} dropdownLabel={localCategoryName ? localCategoryName : "Select product category"} long={true} />
                            </>
                        }
                    />
                    <AppFormRow type="textarea" leftHeader={"Product Description"} rightSrc={<AppInput type="textarea" value={localProductDescription} setValue={setLocalProductDescription} placeholder="Describe a little bit about your product..." />} />
                    <AppFormRow
                        leftHeader={"Status"}
                        rightSrc={
                            <>
                                <AppInput label={"Active"} value={"ACTIVE"} type="radio" name="status" checked={localProductStatus == "ACTIVE"} setValue={() => setLocalProductStatus("ACTIVE")} />
                                <AppInput label={"Disabled"} value={"DISABLED"} type="radio" name="status" checked={localProductStatus == "DISABLED"} setValue={() => setLocalProductStatus("DISABLED")} />
                            </>
                        }
                    />
                    {productItemsSummary.length > 0 && productItemsSummary.map((item, index) => <AppFormRow leftHeader={`Product Variant ${index + 1}`} rightSrc={<AppLinkButton buttonColor="primary" buttonType="link" linkTo={`/inventory/variants/edit/${item.productItemId}`} label={item.productItemVariant} />} />)}
                    <AppFormRow type="textarea" leftHeader={"Status Remarks"} rightSrc={<AppInput type="textarea" value={localStatusRemark} setValue={setLocalStatusRemark} placeholder="Please enter remarks if disable the product" />} />
                    <AppFormRow leftHeader={"Date Created"} rightSrc={<AppInput disabled={true} type="text" value={formatDate(localCreateDate)} placeholder="Today" />} />
                    <AppFormRow leftHeader={"Last Updated"} rightSrc={<AppInput disabled={true} type="text" value={formatDate(localModifyDate)} placeholder="Today" />} />
                </tbody>
            </table>
            <NotificationContainer />
            <PageAddProductCategory isOpen={addNewCategoryDisplay} toggle={addBtnToggle} btnAction={btnAddNewCategory} />
        </AppEditForm>
    );
};

export default PageEditProduct;

import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AiOutlineClose } from "react-icons/ai";
import { FiGrid } from "react-icons/fi";
import * as Yup from "yup";
import { Formik } from "formik";
import AppButton from "components/app-button";

//components
import AppFormRow from "components/app-form-row";
import AppInput from "components/app-input";

const PageAddProductCategory = ({ isOpen, toggle, btnAction }) => {
    const [categoryStatus, setCategoryStatus] = useState();

    return (
        <Modal isOpen={isOpen} className="page-add-new-category" contentClassName="page-add-new-category__content">
            <Formik
                initialValues={{ categoryName: "", categoryCode: "" }}
                validationSchema={Yup.object({
                    categoryCode: Yup.string().required("Category code is required").min(4, "Must be 4 characters"),
                    categoryName: Yup.string().required("Category name is required"),
                })}
                onSubmit={(values, actions) => {
                    btnAction({ categoryName: values.categoryName, categoryCode: values.categoryCode, categoryStatus: categoryStatus });
                }}
                validator={() => ({})}>
                {({ values, errors, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <ModalHeader className="header">
                            <AiOutlineClose className="header__close-button" onClick={toggle} />
                        </ModalHeader>
                        <ModalBody className="body">
                            <FiGrid size={50} className="page-add-new-category__header-logo" />
                            <div className="body__title">Add New Category</div>
                            <table>
                                <tbody>
                                    <AppFormRow leftHeader={"Category Code"} rightSrc={<AppInput type="text" name={"categoryCode"} error={errors.categoryCode} uppercase={true} maxLength={4} value={values.categoryCode} onChange={handleChange} placeholder="Enter category code" />} />
                                    <AppFormRow leftHeader={"Category Name"} rightSrc={<AppInput type="text" name={"categoryName"} error={errors.categoryName} value={values.categoryName} onChange={handleChange} placeholder="Category name" />} />
                                    <AppFormRow
                                        leftHeader={"Status"}
                                        rightSrc={
                                            <>
                                                <AppInput label={"Active"} value={"ACTIVE"} type="radio" name="status" setValue={() => setCategoryStatus("ACTIVE")} />
                                                <AppInput label={"Disabled"} value={"DISABLED"} type="radio" name="status" setValue={() => setCategoryStatus("DISABLED")} />
                                            </>
                                        }
                                    />
                                </tbody>
                            </table>
                        </ModalBody>
                        <ModalFooter className="footer">
                            <AppButton type="button" colorType="white" onClick={toggle} label={"Cancel"} regularBtn={true} />
                            <AppButton type="submit" colorType="primary" label={"Add"} regularBtn={true} />
                        </ModalFooter>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};

export default PageAddProductCategory;

// libraries
import React, { useState, useEffect } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { BiExport } from "react-icons/bi";
import { FiGrid } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
//api
import api from "services/api";

//routes
import pathnames from "routes/pathnames";

// components
import AppSearchBar from "components/app-search-bar";
import AppTableColumnHeader from "components/app-table-column-header";
import AppRowSelect from "components/app-row-select";
import AppSuccessMessage from "components/app-success-message";
import AppBreadcrumb from "components/app-breadcrumb";
import AppButton from "components/app-button";
import AppFormRow from "components/app-form-row";
import AppItemDropdown from "components/app-item-dropdown";
import ModalRackInfo from "pages/warehouse/modalViewRackInfo";

// assets
import iconEdit from "assets/images/icon-edit.svg";

//redux
import AppLinkButton from "components/app-link-button";
import AppViewStockPosition from "components/app-view-stock-position";
import { updateApiError } from "redux/slices/api-error-slice";
const tableTypeList = [{ label: "Section 1", value: "S1" }];

const PageViewStockPosition = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { warehouseName, warehouseCode } = props.location.state.selectedData;
    const [selectedSection, setSelectedSection] = useState(tableTypeList[0].value);
    const [sortSelected, setSortSelected] = useState("");
    const [apiRes, setApiRes] = useState({});
    const [localCountry, setLocalCountry] = useState();
    const [searchInput, setSearchInput] = useState("");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [selectShelf, setSelectShelf] = useState();
    const [shelfLocation, setShelfLocation] = useState();
    const [shelfApiRes, setShelfApiRes] = useState();
    const [rackInfoDisplay, setRackInfoDisplay] = useState(false);
    const selectListCountry = [
        { id: 1, value: "Malaysia", label: "Malaysia" },
        { id: 2, value: "Singapore", label: "Singapore" },
    ];
    const tableTypeSelected = (item) => {
        setSelectedSection(item.value);
    };

    const columns = [
        {
            id: "no",
            Header: () => <AppTableColumnHeader title="No" />,
            Cell: (row) => {
                return <div>{row.row.index + 1}</div>;
            },
            width: 50,
            minWidth: 50,
            maxWidth: 50,
        },
        {
            id: "productId",
            columnsListLabel: "Product ID",
            Header: () => <AppTableColumnHeader title="Product ID" />,
            accessor: "productId",
            width: 100,
            minWidth: 100,
            maxWidth: 100,
        },
        {
            id: "productName",
            columnsListLabel: "Product Name",
            Header: (header) => <AppTableColumnHeader title="Product Name" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => <div className="page-product-list-table--line-clamp-2">{row.productName}</div>,
            minWidth: 105,
            width: 150,
            maxWidth: 250,
            sorting: true,
        },
        {
            id: "categoryName",
            columnsListLabel: "Category",
            Header: (header) => <AppTableColumnHeader title="Category" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => <div className="page-product-list-table--line-clamp-2">{row.categoryName}</div>,

            width: 90,
            minWidth: 90,
            maxWidth: 90,
            sorting: true,
        },
        {
            id: "productStatus",
            columnsListLabel: "Status",
            Header: (header) => <AppTableColumnHeader title="Status" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => {
                if (row.productStatus) {
                    return <span className="page-product-list-table__status--active">Active</span>;
                } else {
                    return <span className="page-product-list-table__status--disabled">Disabled</span>;
                }
            },
            width: 70,
            minWidth: 70,
            maxWidth: 70,
            sorting: true,
        },

        {
            id: "createDate",
            columnsListLabel: "Date Created",
            Header: (header) => <AppTableColumnHeader title="Date Created" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: "createDate",
            width: 135,
            minWidth: 135,
            maxWidth: 135,
            sorting: true,
        },
        {
            id: "modifyDate",
            columnsListLabel: "Last Updated",
            Header: (header) => <AppTableColumnHeader title="Last Updated" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: "modifyDate",
            width: 135,
            minWidth: 135,
            maxWidth: 135,
            sorting: true,
        },
        {
            id: "buttons",
            Header: () => <AppTableColumnHeader title="Action" style={{ justifyContent: "center" }} />,
            Cell: (row) => {
                return <AppLinkButton buttonColor="primary" buttonType="link" linkTo={pathnames.pageEditProduct} label={<img src={iconEdit} alt="icon edit" />} selectedData={row.row.original} />;
            },
            width: 50,
            minWidth: 50,
            maxWidth: 50,
        },
    ];

    const breadcrumbArray = [
        {
            title: "Warehouse",
            onClick: () => {
                history.push(pathnames.pageWarehouse);
            },
        },
        {
            title: "Warehouse List",
            onClick: () => {
                history.push(pathnames.pageWarehouse);
            },
        },
        { title: "View Stock Position", onClick: null },
    ];

    const loadData = async () => {
        try {
            const warehouseRes = await api.get.getSingleWarehouseSection({ warehouseCode: warehouseCode, section: selectedSection });
            await setApiRes(warehouseRes.data["atfc-data"]);
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const getRackInfo = async (value) => {
        try {
            const selectShelfRes = await api.get.getRackInfo({ shelf: value });
            await setShelfApiRes(selectShelfRes.data["atfc-data"]);
            setRackInfoDisplay(true);
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    };

    useEffect(() => {
        try {
            if (!_.isEmpty(selectShelf)) {
                getRackInfo(selectShelf);
            }
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    }, [selectShelf]);

    return (
        apiRes && (
            <>
                <AppViewStockPosition
                    searchInput={searchInput}
                    selectedData={apiRes}
                    selectShelf={selectShelf}
                    setSelectShelf={(value) => setSelectShelf(value)}
                    setShelfLocation={(value) => setShelfLocation(value)}
                    getRackInfo={(value) => getRackInfo(value)}
                    popUpMessage={<AppSuccessMessage show={showSuccessMessage} message="Stock amount has been edited." />}
                    breadcrumb={<AppBreadcrumb breadcrumbArray={breadcrumbArray} />}
                    mainTitle={"Stock Position"}
                    pageTitle={warehouseName}
                    leftSrcImg={true}
                    children={
                        <>
                            <AppFormRow leftHeader={"Row"} leftHeaderAddPadding={true} rightSrc={<AppItemDropdown valueKey="value" labelKey="value" dropdownItems={selectListCountry} labelChange={false} dropdownLabel={localCountry} toggleButtonClass="solid" caret={true} setSelectedItem={setLocalCountry} long={true} />} />
                            <AppFormRow leftHeader={"Column"} leftHeaderAddPadding={true} rightSrc={<AppItemDropdown valueKey="value" labelKey="value" dropdownItems={selectListCountry} labelChange={false} dropdownLabel={localCountry} toggleButtonClass="solid" caret={true} setSelectedItem={setLocalCountry} long={true} />} />
                        </>
                    }
                    tableTypeSelect={<AppRowSelect list={tableTypeList} selected={tableTypeSelected} />}
                    searchBar={
                        <AppSearchBar
                            placeholder="Search by position"
                            onKeyEnter={(e) => {
                                setSearchInput(e.target.value);
                            }}
                            onBlur={(e) => {
                                e.target.value !== searchInput && setSearchInput(e.target.value);
                            }}
                        />
                    }
                    showResult="Showing all 2000 results"
                    tableFunctions={[
                        <AppLinkButton label="Product Category" buttonType="linkBtn" linkTo={pathnames.pageProductCategory} buttonColor="primary" leftSrc={<FiGrid size={20} />} />,
                        <AppLinkButton label="Add New Product" buttonType="linkBtn" linkTo={pathnames.pageAddProduct} buttonColor="primary" leftSrc={<AiFillPlusCircle size={16} />} />,
                        <AppButton label="Export All" buttonType="transparent" colorType="primary" leftSrc={<BiExport size={20} />} />,
                    ]}
                    tableColumns={columns}
                />
                <ModalRackInfo setShelfLocation={(value) => setShelfLocation(value)} setSelectShelf={(value) => setSelectShelf(value)} shelfLocation={shelfLocation} selectShelf={selectShelf} isOpen={rackInfoDisplay} toggle={() => setRackInfoDisplay(!rackInfoDisplay)} rackInfo={shelfApiRes} />
            </>
        )
    );
};

export default PageViewStockPosition;

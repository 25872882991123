import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    content: null,
    pageable: null,
    totalPages: null,
    totalElements: null,
    last: true,
    size: null,
    number: null,
    sort: null,
    numberOfElements: null,
    first: true,
    last: false,
};

export const inventoryListSlice = createSlice({
    name: "inventoryList",
    initialState,
    reducers: {
        updateInventoryList: (state, action) => {
            return {
                ...state,
                ...action.payload.data["atfc-data"],
            };
        },
    },
});

export const { updateInventoryList } = inventoryListSlice.actions;

export default inventoryListSlice.reducer;

// libraries
import React, { useState, useEffect } from "react";
import { BiExport } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import pathnames from "routes/pathnames";
import { CSVLink } from "react-csv";

//api
import api from "services/api";

// components
import AppTableColumnHeader from "components/app-table-column-header";
import AppTableFooter from "components/app-table-footer";
import AppSuccessMessage from "components/app-success-message";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTablePage from "components/app-table-page.jsx";
import AppButton from "components/app-button";

//redux
import { updateApiError } from "redux/slices/api-error-slice";
import { updateStockMovement } from "redux/slices/stock-movement-slice";

const PageStockMovementList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [sortSelected, setSortSelected] = useState("");
    const selectedVariantDetails = useSelector((state) => state.selectedVariantDetails);
    const stockMovement = useSelector((state) => state.stockMovement);
    const [paginationInfo, setPaginationInfo] = useState({
        page: 1,
        size: 20,
        numberOfElements: 16,
        totalElements: 10,
        totalPages: 1,
    });

    const columns = [
        {
            id: "no",
            Header: () => <AppTableColumnHeader title="No" />,
            Cell: (row) => {
                return <div>{row.row.index + 1}</div>;
            },
            width: 20,
        },
        {
            id: "date",
            columnsListLabel: "Date",
            Header: () => <AppTableColumnHeader title="Date" />,
            accessor: "auditDate",
            width: 80,
            disableSortBy: true,
        },
        {
            id: "description",
            columnsListLabel: "Description",
            Header: (header) => <AppTableColumnHeader title="Description" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => <div className="page-product-list-table--line-clamp-2">{row?.auditActionType}</div>,
            width: 85,
            maxWidth: 250,
            sorting: true,
            sortType: (a, b) => {
                if (a.values["description"].props.children < b.values["description"].props.children) {
                    return -1;
                } else if (a.values["description"].props.children > b.values["description"].props.children) {
                    return 1;
                }

                return 0;
            },
        },
        {
            id: "quantity",
            columnsListLabel: "Quantity",
            Header: (header) => <AppTableColumnHeader title="Quantity" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => <div className="page-product-list-table--line-clamp-2">{row?.auditQuantity}</div>,
            width: 35,
            disableSortBy: true,
        },
        {
            id: "remarks",
            columnsListLabel: "Remarks",
            Header: (header) => <AppTableColumnHeader title="Remarks" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => <div className="page-product-list-table--line-clamp-2">{row?.auditEntitySummary?.auditRemark}</div>,
            width: 80,
            maxWidth: 90,
            disableSortBy: true,
        },
        {
            id: "createBy",
            columnsListLabel: "createBy",
            Header: (header) => <AppTableColumnHeader title="Created by" selecting={sortSelected === header.column.id} sortButtonClick={() => setSortSelected(header.column.id)} />,
            accessor: (row) => <div className="page-product-list-table--line-clamp-2">{row?.auditOnUser}</div>,
            width: 90,
            disableSortBy: true,
        },
    ];

    const breadcrumbArray = [
        { title: "Inventory", onClick: () => history.push(pathnames.pageInventoryProductList) },
        { title: "Variants", onClick: () => history.push(pathnames.pageInventoryVariants) },
        { title: selectedVariantDetails.productItemVariant, onClick: () => history.push(pathnames.pageInventoryVariants) },
        { title: "Edit Variant Info", onClick: () => history.push(`/inventory/variants/edit/${selectedVariantDetails.productItemId}`) },
        { title: "Stock Movement", onClick: null },
    ];

    const headers = [
        { label: "Date", key: "auditDate" },
        { label: "Description", key: "auditActionType" },
        { label: "Initial Quantity", key: "auditEntitySummary.initialStockCount" },
        { label: "Adjustment Quantity", key: "auditQuantity" },
        { label: "Remarks", key: "auditEntitySummary.auditRemark" },
        { label: "Created By", key: "auditOnUser" },
    ];

    const loadData = async () => {
        try {
            let response = await api.get.getStockMovement({ size: paginationInfo.size, productItemId: selectedVariantDetails.productItemId, page: paginationInfo.page });
            setPaginationInfo({
                ...paginationInfo,
                numberOfElements: response.data["atfc-data"]?.numberOfElements,
                totalElements: response.data["atfc-data"]?.totalElements,
                totalPages: response.data["atfc-data"]?.totalPages,
            });
            dispatch(updateStockMovement(response));
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    };

    return (
        <AppTablePage
            breadcrumb={<AppBreadcrumb breadcrumbArray={breadcrumbArray} />}
            pageTitle="Stock Movement"
            tableFunctions={[
                <AppButton label="" buttonType="transparent" colorType="primary" leftSrc={<BiExport size={20} />}>
                    <CSVLink style={{ color: "#3e868e" }} filename={`wms_stock_movement_${selectedVariantDetails.productItemVariant}_.csv`} data={stockMovement.content ? stockMovement.content : []} headers={headers}>
                        Export All
                    </CSVLink>
                </AppButton>,
            ]}
            totalElements={paginationInfo.totalElements}
            tableColumns={columns}
            tableData={stockMovement.content ? stockMovement.content : []}
            tablePageFooter={<AppTableFooter paginationInfo={paginationInfo} loadData={loadData} setPaginationInfo={(paginationInfo) => setPaginationInfo(paginationInfo)} />}
        />
    );
};

export default PageStockMovementList;

import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AiOutlineClose } from "react-icons/ai";
import { MdAccountCircle } from "react-icons/md";
import InputMask from "react-input-mask";
import _ from "lodash";
//api
import api from "services/api";

//components
import AppFormRow from "components/app-form-row";
import AppInput from "components/app-input";
import AppItemDropdown from "components/app-item-dropdown";

const PageQuickAddNewSupplier = ({ isOpen, toggle, btnAction }) => {
    const [companyName, setCompanyName] = useState();
    const [regisNo, setRegisNo] = useState();
    const [personInCharge, setPersonInCharge] = useState();
    const [email, setEmail] = useState();
    const [contactNo, setContactNo] = useState();
    const [address1, setAddress1] = useState();
    const [address2, setAddress2] = useState();
    const [postcode, setPostcode] = useState();
    const [city, setCity] = useState();
    const [addressState, setAddressState] = useState();
    const [country, setCountry] = useState();
    const [status, setStatus] = useState("ACTIVE");

    const selectList = [
        { id: 1, value: "Johor", label: "Johor" },
        { id: 2, value: "Kedah", label: "Kedah" },
        { id: 3, value: "Kelantan", label: "Kelantan" },
        { id: 4, value: "Kuala Lumpur", label: "Kuala Lumpur" },
        { id: 5, value: "Malacca", label: "Malacca" },
        { id: 6, value: "Negeri Sembilan", label: "Negeri Sembilan" },
        { id: 7, value: "Pahang", label: "Pahang" },
        { id: 8, value: "Penang", label: "Penang" },
        { id: 9, value: "Perak", label: "Perak" },
        { id: 10, value: "Perlis", label: "Perlis" },
        { id: 11, value: "Sabah", label: "Sabah" },
        { id: 12, value: "Sarawak", label: "Sarawak" },
        { id: 13, value: "Selangor", label: "Selangor" },
        { id: 14, value: "Terengganu", label: "Terengganu" },
    ];

    const selectListCountry = [
        { id: 1, value: "Malaysia", label: "Malaysia" },
        { id: 2, value: "Singapore", label: "Singapore" },
    ];

    const phoneFormatting = (dasherizedStr) => {
        if (!_.isEmpty(dasherizedStr)) {
            let countryCode = dasherizedStr.substr(0, 2);
            if (countryCode == "+6") {
                let newValue = dasherizedStr.slice(2);
                return newValue.replace(/[_-]/g, " ").replace(/ /g, "");
            } else return "";
        }
    };

    return (
        <Modal isOpen={isOpen} className="page-quick-add-new-supplier" contentClassName="page-quick-add-new-supplier__content">
            <ModalHeader className="header">
                <AiOutlineClose className="header__close-button" onClick={toggle} />
            </ModalHeader>
            <ModalBody className="body">
                <div className="body__container">
                    <MdAccountCircle size={50} className="page-add-new-category__header-logo" />
                    <div className="body__title">Add New Supplier</div>
                </div>
                <table>
                    <tbody>
                        <AppFormRow leftHeader={"Company Name"} rightSrc={<AppInput type="text" value={companyName} setValue={setCompanyName} placeholder="Company name" />} />
                        <AppFormRow leftHeader={"Registration No"} rightSrc={<AppInput type="text" value={regisNo} setValue={setRegisNo} placeholder="Company registration no" />} />
                        <AppFormRow leftHeader={"Person In Charge"} rightSrc={<AppInput type="text" value={personInCharge} setValue={setPersonInCharge} placeholder="Person in charge name" />} />
                        <AppFormRow leftHeader={"Email Address"} rightSrc={<AppInput type="text" value={email} setValue={setEmail} placeholder="Business email address" />} />
                        <AppFormRow
                            leftHeader={"Contact No"}
                            rightSrc={
                                <div>
                                    <InputMask
                                        style={{ minWidth: 420, height: 40, border: "1px solid #c4c4c4", borderRadius: 4, outline: "none", paddingLeft: 10 }}
                                        mask="+6999-99999999"
                                        name="contact"
                                        value={contactNo}
                                        onChange={(e) => {
                                            const value = e.target.value || "";
                                            setContactNo(value);
                                        }}
                                    />
                                </div>
                            }
                        />
                        <AppFormRow leftHeader={"Address Line 1"} rightSrc={<AppInput type="text" value={address1} setValue={setAddress1} placeholder="Eg unit no, apartment, suite" />} />
                        <AppFormRow leftHeader={"Address Line 2"} rightSrc={<AppInput type="text" value={address2} setValue={setAddress2} placeholder="Eg street" />} />
                        <AppFormRow leftHeader={"Postcode"} rightSrc={<AppInput type="text" value={postcode} setValue={setPostcode} placeholder="Postcode" />} />
                        <AppFormRow leftHeader={"City"} rightSrc={<AppInput type="text" value={city} setValue={setCity} placeholder="City" />} />
                        <AppFormRow leftHeader={"State"} rightSrc={<AppItemDropdown valueKey="value" labelKey="label" dropdownItems={selectList} labelChange={setAddressState} dropdownLabel={addressState} toggleButtonClass="solid" caret={true} setSelectedItem={setAddressState} long={true} />} />
                        <AppFormRow leftHeader={"Country"} rightSrc={<AppItemDropdown valueKey="value" labelKey="label" dropdownItems={selectListCountry} labelChange={setCountry} dropdownLabel={country} toggleButtonClass="solid" caret={true} setSelectedItem={setCountry} long={true} />} />

                        <AppFormRow
                            leftHeader={"Status"}
                            rightSrc={
                                <>
                                    <AppInput checked={status == "ACTIVE"} label={"Active"} value={"ACTIVE"} type="radio" name="status" setValue={() => setStatus("ACTIVE")} />
                                    <AppInput checked={status == "DISABLED"} label={"Disabled"} value={"DISABLED"} type="radio" name="status" setValue={() => setStatus("DISABLED")} />
                                </>
                            }
                        />
                        <AppFormRow leftHeader={"Date Created"} rightSrc={<AppInput disabled={true} type="text" placeholder="Today" />} />
                        <AppFormRow leftHeader={"Last Updated"} rightSrc={<AppInput disabled={true} type="text" placeholder="Today" />} />
                    </tbody>
                </table>
            </ModalBody>
            <ModalFooter className="footer">
                <div className="footer__left" onClick={toggle}>
                    Cancel
                </div>
                <div
                    className="footer__right"
                    onClick={() =>
                        btnAction({
                            supplierName: companyName,
                            supplierBusinessNo: regisNo,
                            supplierContact: phoneFormatting(contactNo),
                            supplierEmail: email,
                            supplierStatus: status,
                            personInCharge: personInCharge,
                            supplierAddress: {
                                address1: address1,
                                address2: address2,
                                postcode: postcode,
                                city: city,
                                state: addressState,
                                country: country,
                            },
                        })
                    }>
                    Add
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default PageQuickAddNewSupplier;

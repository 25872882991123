// libraries
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiFillPlusCircle, AiOutlineShoppingCart } from "react-icons/ai";
import "react-notifications/lib/notifications.css";
import { NotificationContainer, NotificationManager } from "react-notifications";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import pathnames from "routes/pathnames";
import moment from "moment";

// components
import AppBreadcrumb from "components/app-breadcrumb";
import AppInput from "components/app-input";
import AppEditForm from "components/app-edit-form";
import AppFormRow from "components/app-form-row";
import AppButton from "components/app-button";
import AppItemDropdown from "components/app-item-dropdown";

//api
import api from "services/api";

//redux
import { updateSupplierList } from "redux/slices/supplier-slice";
import UploadAndDisplayImage from "components/app-upload-image";
import PageQuickAddNewSupplier from "pages/relationships/quickAddNewSuppliers";

//asset
import iconBookshelf from "assets/images/icon-bookshelf.svg";
import { updateSelectedVariantDetails } from "redux/slices/selected-variant-details";
import { updateApiError } from "redux/slices/api-error-slice";
import AppLinkButton from "components/app-link-button";
import createNotification from "services/helper";

const PageEditVariant = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { productItemId } = useParams();
    // const { productName } = props.location.state.selectedData;
    const selectedVariantDetails = useSelector((state) => state.selectedVariantDetails);
    const supplierList = useSelector((state) => state.supplierList);
    // const { selectedProductItemId } = props.location.state;
    const [status, setStatus] = useState();
    const [localProductVariant, setLocalProductVariant] = useState();
    const [localSupplierId, setLocalSupplierId] = useState();
    const [localSupplierName, setLocalSupplierName] = useState();
    const [country, setCountry] = useState();
    const [addSupplierDisplay, setAddSupplierDisplay] = useState(false);
    const [itemColor, setItemColor] = useState();
    const [mediaArray, setMediaArray] = useState([]);
    const [localWeight, setWeight] = useState();
    const [localWeightUnit, setWeightUnit] = useState();
    const [localDimensionWidth, setDimensionWidth] = useState();
    const [localDimensionLength, setDimensionLength] = useState();
    const [localDimensionHeight, setDimensionHeight] = useState();
    const [localDimensionUnit, setDimensionUnit] = useState();
    const [paginationInfo, setPaginationInfo] = useState({
        page: 1,
        size: 100,
        numberOfElements: 16,
        totalElements: 160,
        totalPages: 100,
    });

    const breadcrumbArray = [
        {
            title: "Inventory",
            onClick: () => {
                history.push(pathnames.pageInventoryProductList);
            },
        },
        {
            title: "Products",
            onClick: () => {
                history.push(pathnames.pageInventoryProductList);
            },
        },
        {
            title: localProductVariant,
            onClick: () => {
                history.push(pathnames.pageInventoryVariants);
            },
        },
        { title: "Edit Variant Info", onClick: null },
    ];

    const weightArray = [
        {
            label: "g",
        },
        {
            label: "kg",
        },
    ];
    const dimensionArray = [
        {
            label: "cm",
        },
        {
            label: "mm",
        },
        {
            label: "m",
        },
    ];

    const selectListCountry = [
        { id: 1, value: "Malaysia", label: "Malaysia" },
        { id: 2, value: "Singapore", label: "Singapore" },
    ];

    const formatDate = (value) => {
        return moment(value).format("DD/MM/YYYY");
    };

    const addSupplierToggle = () => {
        setAddSupplierDisplay(!addSupplierDisplay);
    };

    const goAddNewSupplier = () => {
        addSupplierToggle();
    };

    const goManageStock = () => {
        history.push(pathnames.pageManageStock, { selectedData: props.location.state.selectedData, selectedProductItemId: productItemId });
    };

    const btnAddSupplier = async (payload) => {
        try {
            const response = await api.post.addSupplier(payload);
            if (response.data["atfc-message"] == "SUCCESS") {
                await createNotification("success", "New supplier created successfully.").then(() => {
                    setTimeout(() => {
                        addSupplierToggle();
                    }, 800);
                });
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    const loadData = async () => {
        try {
            let response = await api.get.getSuppliersList({ page: paginationInfo.page, pageSize: paginationInfo.size, statusFilter: "ACTIVE" });
            let details = await api.get.getVariantDetails(productItemId);
            dispatch(updateSelectedVariantDetails(details));
            setLocalProductVariant(details.data["atfc-data"]?.productItemVariant);
            setStatus(details.data["atfc-data"]?.productItemStatus);
            setLocalSupplierId(details.data["atfc-data"]?.supplierId);
            setLocalSupplierName(details.data["atfc-data"]?.supplierName);
            setCountry(details.data["atfc-data"]?.productItemAttrs?.basicAttrs?.originCountry);
            setItemColor(details.data["atfc-data"]?.productItemAttrs?.extendedAttrs?.color);
            setMediaArray(details.data["atfc-data"]?.productItemMediaAssets);
            setWeight(details.data["atfc-data"]?.productItemAttrs?.extendedAttrs?.weight);
            setWeightUnit(details.data["atfc-data"]?.productItemAttrs?.extendedAttrs?.weightUnit);
            setDimensionWidth(details.data["atfc-data"]?.productItemAttrs?.extendedAttrs?.dimensionWidth);
            setDimensionLength(details.data["atfc-data"]?.productItemAttrs?.extendedAttrs?.dimensionLength);
            setDimensionHeight(details.data["atfc-data"]?.productItemAttrs?.extendedAttrs?.dimensionHeight);
            setDimensionUnit(details.data["atfc-data"]?.productItemAttrs?.extendedAttrs?.dimensionUnit);
            dispatch(updateSupplierList(response));
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    };

    useEffect(() => {
        loadData();
    }, [productItemId, addSupplierDisplay]);

    useEffect(() => {
        if (!_.isEmpty(supplierList?.content)) {
            let selectedData = supplierList?.content.filter((item) => {
                return item.supplierId == localSupplierId;
            });
            if (!_.isEmpty(selectedData)) {
                setLocalSupplierName(selectedData[0]?.supplierName);
            }
        } else return;
    }, [localSupplierId]);

    const btnEditVariant = async () => {
        try {
            let payload = {
                productItemId: productItemId,
                productItemVariant: selectedVariantDetails?.productItemVariant,
                productItemStatus: status,
                productItemAttrs: {
                    basicAttrs: {
                        originCountry: country,
                    },
                    extendedAttrs: {
                        color: itemColor,
                        weight: localWeight,
                        weightUnit: localWeightUnit,
                        dimensionLength: localDimensionLength,
                        dimensionHeight: localDimensionHeight,
                        dimensionWidth: localDimensionWidth,
                        dimensionUnit: localDimensionUnit,
                    },
                },
                supplierId: localSupplierId,
                productId: selectedVariantDetails?.productId,
            };
            const response = await api.post.editVariant(payload);
            if (response.data["atfc-message"] == "SUCCESS") {
                await createNotification("success", "Product item info update successfully.").then(() => {
                    setTimeout(() => {
                        history.goBack();
                    }, 800);
                });
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    return (
        <AppEditForm
            breadcrumb={<AppBreadcrumb breadcrumbArray={breadcrumbArray} />}
            pageTitle={localProductVariant ? localProductVariant : ""}
            pageTitleRightSrc={
                <div className="row-wrapper">
                    <AppLinkButton label="Stock Movement" buttonType="linkBtn" linkTo={`/inventory/variants/stockMovement/${productItemId}`} buttonColor="primary" leftSrc={<AiOutlineShoppingCart size={16} />} selectedData={selectedVariantDetails} />
                    <AppLinkButton label="Manage Stock" buttonType="linkBtn" linkTo={`/inventory/variants/manageStock/${productItemId}`} colorType="primary" buttonColor="primary" leftSrc={<img src={iconBookshelf} alt="icon edit" />} selectedData={selectedVariantDetails} selectedProductItemId={productItemId} />
                </div>
            }
            submitCancelButton={
                <>
                    <AppButton colorType="white" onClick={() => history.goBack()} label={"Cancel"} regularBtn={true} />
                    <AppButton colorType="primary" onClick={() => btnEditVariant()} label={"Update"} regularBtn={true} />
                </>
            }>
            <table>
                <tbody>
                    <AppFormRow leftHeader={"Product ID"} rightSrc={<AppInput disabled={true} type="text" value={selectedVariantDetails?.productItemCode} />} />
                    <AppFormRow leftHeader={"Product Name"} rightSrc={<AppInput disabled={true} type="text" value={localProductVariant} />} />
                </tbody>
            </table>
            <div className="app-edit-page__subTitle-wrapper">
                <p className="app-edit-page__subTitle-wrapper__text">Basic Info</p>
            </div>
            <table>
                <tbody>
                    <AppFormRow leftHeader={"SKU"} rightSrc={<AppInput type="text" disabled={true} value={selectedVariantDetails?.productItemCode} />} />
                    <AppFormRow leftHeader={"Barcode"} rightSrc={<AppInput type="text" value={selectedVariantDetails?.productItemBarcode} />} />

                    <AppFormRow
                        leftHeader={"Supplier"}
                        rightSrc={
                            <>
                                <AppItemDropdown valueKey="supplierId" labelKey="supplierName" dropdownItems={supplierList?.content ? supplierList?.content : []} labelChange={true} toggleButtonClass="solid" caret={true} setSelectedItem={setLocalSupplierName} setSelectedItemValue={setLocalSupplierId} dropdownLabel={localSupplierName ? localSupplierName : "Select supplier"} long={true} />
                                <AppButton label="Add New Supplier" onClick={goAddNewSupplier} buttonType="transparent" colorType="primary" leftSrc={<AiFillPlusCircle size={16} />} />
                            </>
                        }
                    />
                    <AppFormRow
                        leftHeader={"Country of Origin"}
                        rightSrc={
                            <>
                                <AppItemDropdown valueKey="value" labelKey="label" dropdownItems={!_.isEmpty(selectListCountry) ? selectListCountry : []} labelChange={true} toggleButtonClass="solid" caret={true} setSelectedItem={setCountry} setSelectedItemValue={setCountry} dropdownLabel={country ? country : "Select country"} long={true} />

                                {/* <AppInput valueKey="value" labelKey="label" type="select" selectList={selectListCountry} value={country} setValue={setCountry} placeholder="Select country" /> */}
                            </>
                        }
                    />
                    <AppFormRow
                        leftHeader={"Status"}
                        rightSrc={
                            <>
                                <AppInput label={"Active"} value={"ACTIVE"} type="radio" name="status" checked={status == "ACTIVE"} setValue={() => setStatus("ACTIVE")} />
                                <AppInput label={"Disabled"} value={"DISABLED"} type="radio" name="status" checked={status == "DISABLED"} setValue={() => setStatus("DISABLED")} />
                            </>
                        }
                    />
                    <AppFormRow leftHeader={"Date Created"} rightSrc={<AppInput disabled={true} type="text" value={formatDate(selectedVariantDetails?.createDate)} />} />
                    <AppFormRow leftHeader={"Last Updated"} rightSrc={<AppInput disabled={true} type="text" value={formatDate(selectedVariantDetails?.modifyDate)} />} />
                </tbody>
            </table>
            <div className="app-edit-page__subTitle-wrapper">
                <p className="app-edit-page__subTitle-wrapper__text">Attributes</p>
            </div>
            <table>
                <tbody>
                    <AppFormRow leftHeader={"Color"} rightSrc={<AppInput type="text" value={itemColor} setValue={setItemColor} placeholder="Enter color, eg Red" />} />
                    <AppFormRow
                        leftHeader={"Weight"}
                        rightSrc={
                            <>
                                <AppInput styleClass="max-width-420" length="short" type="text" value={localWeight} setValue={setWeight} placeholder="Enter weight, eg 100g" />
                                <AppItemDropdown long={false} valueKey="label" labelKey="label" dropdownItems={weightArray} labelChange={false} toggleButtonClass="solid" caret={true} setSelectedItem={setWeightUnit} dropdownLabel={localWeightUnit} />
                            </>
                        }
                    />
                    <AppFormRow
                        leftHeader={"Dimension"}
                        leftHeaderStickUp={true}
                        rightSrcVertical={true}
                        rightSrc={
                            <>
                                <span style={{ flexDirection: "row", display: "flex", paddingBottom: "20px" }}>
                                    <AppInput leftLabel="Width" length="extra_short" type="text" value={localDimensionWidth} setValue={setDimensionWidth} placeholder="Enter weight, eg 100g" />
                                    <AppItemDropdown long={false} valueKey="label" labelKey="label" dropdownItems={dimensionArray} labelChange={false} toggleButtonClass="solid" caret={true} setSelectedItem={setDimensionUnit} dropdownLabel={localDimensionUnit} />
                                </span>
                                <span style={{ flexDirection: "row", display: "flex", paddingBottom: "20px" }}>
                                    <AppInput leftLabel="Length" length="extra_short" type="text" value={localDimensionLength} setValue={setDimensionLength} placeholder="Enter weight, eg 100g" />
                                    <AppItemDropdown disabled={true} long={false} valueKey="label" labelKey="label" dropdownItems={dimensionArray} labelChange={false} toggleButtonClass="solid" setSelectedItem={setDimensionUnit} dropdownLabel={localDimensionUnit} />
                                </span>
                                <span style={{ flexDirection: "row", display: "flex", paddingBottom: "20px" }}>
                                    <AppInput leftLabel="Height" length="extra_short" type="text" value={localDimensionHeight} setValue={setDimensionHeight} placeholder="Enter weight, eg 100g" />
                                    <AppItemDropdown disabled={true} long={false} valueKey="label" labelKey="label" dropdownItems={dimensionArray} labelChange={false} toggleButtonClass="solid" setSelectedItem={setDimensionUnit} dropdownLabel={localDimensionUnit} />
                                </span>
                            </>
                        }
                    />
                    <AppFormRow type="textarea" leftHeader={"Variants"} rightSrc={<AppInput type="textarea" value={localProductVariant} setValue={setLocalProductVariant} placeholder="Enter product variants" />} />
                </tbody>
            </table>
            <div>Images</div>
            <div>* File format can be jpeg or png, maximum size 1MB.</div>
            <UploadAndDisplayImage productItemId={productItemId} productItemMediaAsset={selectedVariantDetails.productItemMediaAssets} />
            <NotificationContainer />
            <PageQuickAddNewSupplier isOpen={addSupplierDisplay} toggle={addSupplierToggle} btnAction={btnAddSupplier} />
        </AppEditForm>
    );
};

export default PageEditVariant;

import React, { useEffect } from "react";
import AppButton from "components/app-button";
import { MdRefresh } from "react-icons/md";
import DoughnutChart from "components/app-doughnut-chart";
import DashboardBox from "components/app-dashboard-box";
import { useSelector, useDispatch } from "react-redux";
import logo from "../assets/images/inventory.png";
import { TiDocumentAdd } from "react-icons/ti";

//api
import api from "services/api";
import { updateDashboard } from "redux/slices/dashboard-slice";
import { updateApiError } from "redux/slices/api-error-slice";

const PageDashboard = () => {
    const userProfile = useSelector((state) => state.userProfile);
    const dispatch = useDispatch();
    const dashboard = useSelector((state) => state.dashboard);
    const loadData = async () => {
        try {
            let response = await api.get.getDashboard();
            dispatch(updateDashboard(response));
        } catch (error) {
            dispatch(updateApiError({ error }));
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const refreshPage = () => {
        window.location.reload();
    };

    return (
        <div className="page-dashboard">
            <div className="dashboard">
                <div className="dashboard__container">
                    <div className="dashboard__header_container">
                        <div className="dashboard__title_container">
                            <div className="dashboard__title">Hello {userProfile?.userDisplayName},</div>
                            <div>Here is what happening with your current warehouse today.</div>
                        </div>
                        <div className="dashboard__refresh_container">
                            <AppButton label="Refresh" onClick={refreshPage} buttonType="transparent" colorType="primary" leftSrc={<MdRefresh color="#3E868E" size={16} />} />
                        </div>
                    </div>
                    <div className="dashboard__content_container">
                        <label className="dashboard__content_title">Warehouse Capacity</label>
                        <div className="dashboard__content_chart_container" style={{ flexWrap: "wrap" }}>
                            {dashboard?.warehousesShelfSummary.map((item) => {
                                return (
                                    <div className="dashboard__content_chart" style={{ margin: 10, width: "45%", padding: 20, height: 400 }}>
                                        <DoughnutChart item={item} />
                                    </div>
                                );
                            })}
                        </div>

                        <div className="row-wrapper">
                            <label className="dashboard__content_title">Inventory</label>
                            <label className="dashboard__content_title">Top 5 least product</label>
                        </div>

                        <div className="dashboard__content_fulfilment_container">
                            <div className="dashboard__content_fulfilment_sub_container">
                                <DashboardBox leftSrc="New Order" rightSrc="280,000" leftLogo={<TiDocumentAdd size={25} color="#FFFFFF" />} />
                                <DashboardBox leftSrc="Processing" rightSrc="10,000" leftLogo={<TiDocumentAdd size={25} color="#FFFFFF" />} />
                                <DashboardBox leftSrc="Ready To Ship" rightSrc="8,000" leftLogo={<TiDocumentAdd size={25} color="#FFFFFF" />} />
                                <DashboardBox leftSrc="Shipped" rightSrc="5,000" leftLogo={<TiDocumentAdd size={25} color="#FFFFFF" />} />
                                <DashboardBox leftSrc="Completed" rightSrc="3,000" leftLogo={<TiDocumentAdd size={25} color="#FFFFFF" />} />
                                <DashboardBox leftSrc="Return" rightSrc="5" leftLogo={<TiDocumentAdd size={25} color="#FFFFFF" />} />
                            </div>
                            <div className="dashboard__content_fulfilment_sub_container" style={{ flexWrap: "wrap" }}>
                                {dashboard?.inventoryProductSummary.map((item) => {
                                    return (
                                        <>
                                            <DashboardBox leftSrc={item?.productName} rightSrc={item?.sumOfOnHandStockCount} leftLogo={<img src={logo} alt="" height={28} width={28} />} />
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageDashboard;

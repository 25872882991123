// libraries
import React, { Fragment } from "react";

const AppFormRow = ({ leftHeader, rightSrc, type, rightSrcVertical, leftHeaderStickUp, leftHeaderAddPadding }) => {
    const getClassName = () => {
        const classNames = ["app-form-row__left-header"];
        if (type === "textarea") classNames.push("app-form-row__left-header-textarea");
        if (leftHeaderStickUp) classNames.push("app-form-row__leftHeaderStickUp");
        if (leftHeaderAddPadding) classNames.push("app-form-row__leftHeaderAddPadding");
        else classNames.push();
        return classNames.join(" ");
    };

    const getColonClassName = () => {
        const classNames = ["app-form-row__colon"];
        if (type === "textarea") classNames.push("app-form-row__left-header-textarea");
        if (leftHeaderStickUp) classNames.push("app-form-row__leftHeaderStickUp");
        else classNames.push();
        return classNames.join(" ");
    };
    const getRightSrcClassName = () => {
        const classNames = [""];
        if (rightSrcVertical) classNames.push("app-form-row__rightSrcVertical");
        else classNames.push("app-form-row__rightSrc");
        return classNames.join(" ");
    };
    return (
        <Fragment>
            <tr className="app-form-row">
                <td className={getClassName()}>{leftHeader}</td>
                <td className={getColonClassName()}>:</td>
                <td className={getRightSrcClassName()}>{rightSrc}</td>
            </tr>
        </Fragment>
    );
};

export default AppFormRow;

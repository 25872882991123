// libraries
import React, { useState, useEffect } from "react";
import { NotificationContainer, NotificationManager } from "react-notifications";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import pathnames from "routes/pathnames";
import { useHistory } from "react-router-dom";
import _ from "lodash";
// components
import AppBreadcrumb from "components/app-breadcrumb";
import AppInput from "components/app-input";
import AppItemDropdown from "components/app-item-dropdown";
import AppEditForm from "components/app-edit-form";
import AppFormRow from "components/app-form-row";
import AppButton from "components/app-button";
import api from "services/api";

//asset
import logo from "../../assets/images/pages/warehouse/editWarehouseLogo.png";
import createNotification from "services/helper";

const PageAddWarehouse = (props) => {
    const [addressState, setAddressState] = useState();
    const [country, setCountry] = useState();
    const history = useHistory();
    const selectList = [
        { id: 1, value: "Johor", label: "Johor" },
        { id: 2, value: "Kedah", label: "Kedah" },
        { id: 3, value: "Kelantan", label: "Kelantan" },
        { id: 4, value: "Kuala Lumpur", label: "Kuala Lumpur" },
        { id: 5, value: "Malacca", label: "Malacca" },
        { id: 6, value: "Negeri Sembilan", label: "Negeri Sembilan" },
        { id: 7, value: "Pahang", label: "Pahang" },
        { id: 8, value: "Penang", label: "Penang" },
        { id: 9, value: "Perak", label: "Perak" },
        { id: 10, value: "Perlis", label: "Perlis" },
        { id: 11, value: "Sabah", label: "Sabah" },
        { id: 12, value: "Sarawak", label: "Sarawak" },
        { id: 13, value: "Selangor", label: "Selangor" },
        { id: 14, value: "Terengganu", label: "Terengganu" },
    ];

    const selectListCountry = [
        { id: 1, value: "Malaysia", label: "Malaysia" },
        { id: 2, value: "Singapore", label: "Singapore" },
    ];

    const breadcrumbArray = [
        {
            title: "Warehouse",
            onClick: () => {
                history.push(pathnames.pageWarehouse);
            },
        },
        {
            title: "Warehouse List",
            onClick: () => {
                history.push(pathnames.pageWarehouse);
            },
        },
        { title: "Add New Warehouse", onClick: null },
    ];

    const btnAddWarehouse = async (values) => {
        try {
            let payload = {
                warehouseCode: values.warehouseCode,
                warehouseName: values.warehouseName,
                warehouseDescription: values.warehouseDescription,
                warehouseStatus: values.warehouseStatus,
                warehouseAddress: {
                    address1: values.address1,
                    address2: values.address2,
                    postcode: values.postcode,
                    city: values.city,
                    state: values.state,
                    country: values.country,
                },

                rowCount: values.rows,
                colCount: values.columns,
                shelfCountPerCol: values.shelves,
                rackCountPerShelf: values.racks,
            };
            const response = await api.post.newWarehouse(payload);
            if (response.data["atfc-message"] == "SUCCESS") {
                await createNotification("success", "New warehouse created successfully.").then(() => {
                    setTimeout(() => {
                        history.goBack();
                    }, 800);
                });
            }
        } catch (error) {
            console.log("error", { error });
            let err = { error };
            let msg = err?.error?.response?.data["atfc-data"]["data-detail"];
            createNotification("error", msg);
        }
    };

    return (
        <Formik
            initialValues={{ warehouseCode: "", warehouseName: "", warehouseDescription: "", warehouseStatus: "", address1: "", address2: "", postcode: "", city: "", state: "", country: "", sections: "1", rows: "", columns: "", shelves: "", racks: "", warehouseStatus: "ACTIVE" }}
            validationSchema={Yup.object({
                warehouseCode: Yup.string().required("Required").min(4, "Must be exactly 4 digits").max(4, "Must be exactly 4 digits"),
                // postcode: Yup.string()
                //     .required("Required")
                //     .matches(/^[0-9]+$/, "Must be only digits")
                //     .min(5, "Minimum 5 digits")
                //     .max(6, "Maximum 6 digits"),
                warehouseName: Yup.string().required("Required"),
                sections: Yup.string().required("Required"),
                rows: Yup.string().required("Required"),
                columns: Yup.string().required("Required"),
                shelves: Yup.string().required("Required"),
                racks: Yup.string().required("Required"),
            })}
            onSubmit={(values, actions) => {
                btnAddWarehouse(values);
            }}
            validator={() => ({})}>
            {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                    <AppEditForm
                        breadcrumb={<AppBreadcrumb breadcrumbArray={breadcrumbArray} />}
                        pageTitle={"New Warehouse"}
                        leftSrcImg={
                            <div className="margin-right-10">
                                <img width={70} height={70} src={logo} alt="" />
                            </div>
                        }
                        submitCancelButton={
                            <>
                                <AppButton colorType="white" type="button" onClick={() => history.goBack()} label={"Cancel"} regularBtn={true} />
                                <AppButton type="submit" colorType="primary" label={"Create"} regularBtn={true} />
                            </>
                        }>
                        <table>
                            <tbody>
                                <AppFormRow leftHeader={"Warehouse Code"} rightSrc={<AppInput maxLength="4" error={errors.warehouseCode} name="warehouseCode" type="text" value={values.warehouseCode.toUpperCase()} onChange={handleChange} placeholder="Enter warehouse code" />} />
                                <AppFormRow leftHeader={"Warehouse Name"} rightSrc={<AppInput name="warehouseName" type="text" error={errors.warehouseName} value={values.warehouseName} onChange={handleChange} placeholder="Warehouse name" />} />
                                <AppFormRow type="textarea" leftHeader={"Warehouse Description"} rightSrc={<AppInput name="warehouseDescription" type="textarea" value={values.warehouseDescription} onChange={handleChange} placeholder="Describe a little bit about your warehouse" />} />
                                <AppFormRow leftHeader={"Address Line 1"} rightSrc={<AppInput name="address1" type="text" value={values.address1} error={errors.address1} onChange={handleChange} placeholder="Eg unit no, apartment, suite" />} />
                                <AppFormRow leftHeader={"Address Line 2"} rightSrc={<AppInput name="address2" type="text" value={values.address2} onChange={handleChange} placeholder="Eg street" />} />
                                <AppFormRow leftHeader={"Postcode"} rightSrc={<AppInput error={errors.postcode} name="postcode" maxLength="6" type="text" value={values.postcode} onChange={handleChange} placeholder="Postcode" />} />
                                <AppFormRow leftHeader={"City"} rightSrc={<AppInput name="city" type="text" value={values.city} error={errors.city} onChange={handleChange} placeholder="City" />} />
                                <AppFormRow leftHeader={"State"} rightSrc={<AppItemDropdown type="button" name="state" onChange={(value) => setFieldValue("state", value)} error={errors.state} valueKey="value" labelKey="value" dropdownItems={selectList} labelChange={false} dropdownLabel={addressState ? addressState : "Select state"} toggleButtonClass="solid" caret={true} setSelectedItem={setAddressState} long={true} />} />
                                <AppFormRow leftHeader={"Country"} rightSrc={<AppItemDropdown type="button" name="country" error={errors.country} onChange={(value) => setFieldValue("country", value)} valueKey="value" labelKey="value" dropdownItems={selectListCountry} labelChange={false} dropdownLabel={country ? country : "Select country"} toggleButtonClass="solid" caret={true} setSelectedItem={setCountry} long={true} />} />
                                <AppFormRow leftHeader={"No. of Section"} rightSrc={<AppInput name="sections" error={errors.sections} type="number-with-units" value={values.sections} onChange={handleChange} setValue={handleChange} placeholder="No. of section" />} />
                                <AppFormRow leftHeader={"No. of Rows"} rightSrc={<AppInput name="rows" error={errors.rows} type="number-with-units" value={values.rows} onChange={handleChange} setValue={handleChange} placeholder="No. of rows" />} />
                                <AppFormRow leftHeader={"No. of Columns"} rightSrc={<AppInput name="columns" error={errors.columns} type="number-with-units" value={values.columns} onChange={handleChange} setValue={handleChange} placeholder="No. of columns" />} />
                                <AppFormRow leftHeader={"No. of Shelves Per Column"} rightSrc={<AppInput name="shelves" error={errors.shelves} type="number-with-units" value={values.shelves} onChange={handleChange} setValue={handleChange} placeholder="No. of shelves per column" />} />
                                <AppFormRow leftHeader={"No. of Racks Per Shelf"} rightSrc={<AppInput name="racks" error={errors.racks} type="number-with-units" value={values.racks} onChange={handleChange} setValue={handleChange} placeholder="No. of racks per shelf" />} />
                                {/* <AppFormRow
                                    leftHeader={"Status"}
                                    rightSrc={
                                        <div className="justify-content-center">
                                            <label className="radio-wrapper">
                                                <Field label={"Active"} type="radio" name="warehouseStatus" value={"ACTIVE"} onChange />
                                                <div className="margin-left-5">Active</div>
                                            </label>
                                            <label className="margin-left-10 radio-wrapper">
                                                <Field label={"Disabled"} type="radio" name="warehouseStatus" value={"DISABLED"} />
                                                <div className="margin-left-5">Disabled</div>
                                            </label>
                                        </div>
                                    }
                                /> */}
                                <AppFormRow
                                    leftHeader={"Status"}
                                    rightSrc={
                                        <>
                                            <AppInput label={"Active"} name="warehouseStatus" value={"ACTIVE"} type="radio" checked={values.warehouseStatus == "ACTIVE"} setValue={() => setFieldValue("warehouseStatus", "ACTIVE")} />
                                            <AppInput label={"Disabled"} name="warehouseStatus" value={"DISABLED"} error={errors.warehouseStatus} type="radio" checked={values.warehouseStatus == "DISABLED"} setValue={() => setFieldValue("warehouseStatus", "DISABLED")} />
                                        </>
                                    }
                                />
                                <AppFormRow leftHeader={"Date Created"} rightSrc={<AppInput disabled={true} type="text" placeholder="Today" />} />
                                <AppFormRow leftHeader={"Last Updated"} rightSrc={<AppInput disabled={true} type="text" placeholder="Today" />} />
                            </tbody>
                        </table>
                        <NotificationContainer />
                    </AppEditForm>
                </form>
            )}
        </Formik>
    );
};

export default PageAddWarehouse;

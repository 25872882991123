import React from "react";
import { IoIosSearch } from "react-icons/io";

const AppSearchBar = ({ placeholder, onKeyEnter, onBlur, searchError }) => {
    return (
        <>
            <div className="app-search-bar">
                <input
                    type="input"
                    className="app-search-bar__input"
                    placeholder={placeholder}
                    onKeyUp={(e) => {
                        e.key === "Enter" && onKeyEnter(e);
                    }}
                    onBlur={onBlur}
                />
                <IoIosSearch size={22} className="app-search-bar__icon" />
            </div>
            <div style={{ color: "red" }}>{searchError}</div>
        </>
    );
};

export default AppSearchBar;

import axios from "services/axios";
import CONSTANSTS from "common/constansts";

const api = {
    get: {
        verifyForgotPasswordByHash: (payload) => axios.get(`${CONSTANSTS.ENDPOINT_PATH.VERIFY_FORGOT_PASSWORD}/${payload}`, null),
        getSuppliersList: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.SUPPLIER_LIST, payload),
        getInventoryList: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.INVENTORY_LIST, payload),
        getProductCategoryList: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.PRODUCT_CATEGORY_LIST, payload),
        getProductDetails: (payload) => axios.get(`${CONSTANSTS.ENDPOINT_PATH.GET_PRODUCT_DETAIL}/${payload}`, null),
        getVariantList: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.VARIANT_LIST, payload),
        getWarehouseList: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.WAREHOUSE_LIST, payload),
        getCreateStockCount: (payload) => axios.get(`${CONSTANSTS.ENDPOINT_PATH.CREATE_STOCK_COUNT}?page=${payload.page}&pageSize=${payload.size}`, null),
        getSingleWarehouseSection: (payload) => axios.get(`${CONSTANSTS.ENDPOINT_PATH.SINGLE_WAREHOUSE_SECTION}/${payload.warehouseCode}/${payload.section}`, null),
        getRackInfo: (payload) => axios.get(`${CONSTANSTS.ENDPOINT_PATH.GET_RACK_INFO}/${payload.shelf}`, null),
        getProductMedia: (payload) => axios.get(`${CONSTANSTS.ENDPOINT_PATH.RETRIEVE_MEDIA}/${payload}`, null),
        getVariantDetails: (payload) => axios.get(`${CONSTANSTS.ENDPOINT_PATH.VARIANT_DETAILS}/${payload}`, null),
        getVariantDetailsWithStock: (payload) => axios.get(`${CONSTANSTS.ENDPOINT_PATH.VARIANT_DETAILS_WITH_STOCK(payload)}`, null),
        getUserList: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.USER_LIST, payload),
        getUserProfile: () => axios.get(`${CONSTANSTS.ENDPOINT_PATH.USER_PROFILE}`, null),
        getDashboard: () => axios.get(`${CONSTANSTS.ENDPOINT_PATH.DASHBOARD}`, null),
        getStockMovement: (payload) => axios.get(`${CONSTANSTS.ENDPOINT_PATH.STOCK_MOVEMENT}/${payload.productItemId}?pageSize=${payload.size}&page=${payload.page}&sort=-createDate`, null),
        getOtherProfileDetails: (payload) => axios.get(`${CONSTANSTS.ENDPOINT_PATH.VIEW_OTHER_PROFILE}/${payload}`, null),
        getWarehouseDetails: (payload) => axios.get(`${CONSTANSTS.ENDPOINT_PATH.GET_WAREHOUSE_DETAILS}/${payload}`, null),
    },

    post: {
        preSignUp: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.PRE_SIGN_UP, payload),
        verifyPreSignUp: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.VERIFY_PRE_SIGN_UP, payload),
        completeSignUp: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.COMPLETE_SIGN_UP, payload),
        signIn: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.SIGN_IN, payload),
        forgotPassword: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.FORGOT_PASSWORD, payload),
        verifyForgotPassword: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.VERIFY_FORGOT_PASSWORD, payload),
        resetPassword: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.RESET_PASSWORD, payload),
        addNewProductCategory: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.ADD_PRODUCT_CATEGORY, payload),
        editProductCategory: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.EDIT_PRODUCT_CATEGORY, payload),
        editSupplier: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.EDIT_SUPPLIER, payload),
        addSupplier: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.ADD_SUPPLIER, payload),
        addProduct: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.ADD_PRODUCT, payload),
        editWarehouse: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.EDIT_WAREHOUSE, payload),
        newWarehouse: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.NEW_WAREHOUSE, payload),
        editProduct: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.EDIT_PRODUCT, payload),
        assignNewStockPosition: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.ASSIGN_NEW_STOCK_POSITION, payload),
        addMedia: (id, payload, headers) => axios.post(`${CONSTANSTS.ENDPOINT_PATH.ADD_MEDIA}/${id}`, payload, headers),
        removeMedia: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.REMOVE_MEDIA, payload),
        editVariant: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.EDIT_VARIANT, payload),
        newVariant: (payload, headers) => axios.post(CONSTANSTS.ENDPOINT_PATH.NEW_VARIANT, payload, headers),
        updateVariantStock: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.UPDATE_VARIANT_STOCK, payload),
        addUser: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.ADD_USER, payload),
        editProfile: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.EDIT_PROFILE, payload),
        editPassword: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.EDIT_PASSWORD, payload),
        verifyPreSignUp: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.VERIFY_PRE_SIGN_UP, payload),
        completeSignUp: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.COMPLETE_SIGN_UP, payload),
        editOtherProfile: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.EDIT_OTHER_PROFILE, payload),
    },
};

export default api;

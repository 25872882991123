import { useRef, useEffect } from "react";
import axios from "axios";

import useIsMount from "hooks/use-is-mount";
import { sanitizeError, isDevelopmentMode } from "common/utilities";

const sharedStyles = "margin: 5px 0; padding: 8px; border-radius: 20px; color: #FFFFFF;";
const initStyles = `${sharedStyles} background: #673AB7;`;
const reqSuccessStyles = `${sharedStyles} background: #F8EA8C;`;
const respSuccessStyles = `${sharedStyles} background: #8BC34A;`;
const errorStyles = `${sharedStyles} background: #F79489;`;
const debug = isDevelopmentMode();

const interceptorDebug = (title, styles, data = "") => {
    if (!debug) return;

    console.log(`%c ${title}`, styles);
    console.log(data);
    console.log("");
};

const InterceptorProvider = ({ children }) => {
    const isMount = useIsMount();
    const requestInterceptor = useRef();
    const responseInterceptor = useRef();

    useEffect(() => {
        if (isMount) {
            interceptorDebug("Init Axios Interceptor! 🎉", initStyles);

            requestInterceptor.current = axios.interceptors.request.use(
                (config) => {
                    interceptorDebug("REQUESTING 🚀", reqSuccessStyles, config.url);
                    return config;
                },
                (error) => {
                    interceptorDebug("REQUESTING ERROR 👎", errorStyles, sanitizeError(error));
                    return Promise.reject(error);
                }
            );

            responseInterceptor.current = axios.interceptors.response.use(
                (response) => {
                    interceptorDebug("RESPONSE SUCCESS: 🌟", respSuccessStyles, response.data);
                    return response;
                },
                async (error) => {
                    interceptorDebug("RESPONSE ERROR 🥲", errorStyles, sanitizeError(error));
                    const statusCode = error?.response?.status;

                    switch (statusCode) {
                        case 401:
                            return Promise.reject(error);
                        default:
                            return Promise.reject(error);
                    }
                }
            );
        }
    }, [isMount]);

    useEffect(() => {
        return () => {
            interceptorDebug("Ejected Axios Interceptor! 👋", initStyles);
            axios.interceptors.request.eject(requestInterceptor.current);
            axios.interceptors.response.eject(responseInterceptor.current);
        };
    }, []);

    return children;
};

export default InterceptorProvider;
